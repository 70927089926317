import {Injectable} from '@angular/core';
import {ITableColumn} from '../common/interfaces/table-column.interface';
import {StorageService} from './storage.service';
import {RegisterDataTableService} from './register-data-table.service';


@Injectable({
  providedIn: 'root'
})
export class RegisterRateCardDataTableService extends RegisterDataTableService {


  constructor(
    storageService: StorageService
  ) {
    super(storageService);
  }


  override getColumns(): ITableColumn[] {
    return [
      {
        name: 'priority',
        title: 'Приоритет',
        settingTitle: 'Приоритет',
        useCustomTemplate: true,
        default: true,
        hideTitle: true
      },
      {
        name: 'id',
        title: 'Id',
        useCustomTemplate: false,
        default: true
      },
      {
        name: 'createAt',
        title: 'Дата оценки',
        useCustomTemplate: true,
        default: true
      },
      {
        name: 'firm',
        title: 'Учреждение',
        useCustomTemplate: true,
        default: true
      },
      {
        name: 'facility',
        title: 'Объект',
        useCustomTemplate: true,
        default: true
      },
      {
        name: 'facilityAddress',
        title: 'Адрес объекта',
        useCustomTemplate: false,
        default: true
      },
      {
        name: 'remarkType',
        title: 'Замечание',
        useCustomTemplate: true,
        default: true
      },
      {
        name: 'status',
        title: 'Статус',
        useCustomTemplate: true,
        default: true
      },
      {
        name: 'facilityType',
        title: 'Тип объекта',
        useCustomTemplate: true,
        default: false
      },
      {
        name: 'firmAddress',
        title: 'Адрес учреждения',
        useCustomTemplate: true,
        default: false
      },
      {
        name: 'district',
        title: 'Округ',
        useCustomTemplate: false,
        default: false
      },
      {
        name: 'rateGroup',
        title: 'Группа',
        useCustomTemplate: false,
        default: false
      },
      {
        name: 'rateSubGroup',
        title: 'Подгруппа',
        useCustomTemplate: false,
        default: false
      },
      {
        name: 'rateKindGroup',
        title: 'Вид замечания',
        useCustomTemplate: false,
        default: false
      },
      {
        name: 'daysInWork',
        title: 'Дней в работе',
        useCustomTemplate: false,
        default: false
      },
      {
        name: 'finishedDate',
        title: 'Дата устранения',
        useCustomTemplate: true,
        default: false
      },
      {
        name: 'inspector',
        title: 'Инспектор',
        useCustomTemplate: true,
        default: false
      },
      {
        name: 'operator',
        title: 'Оператор',
        useCustomTemplate: true,
        default: false
      },
      // {
      //   name: 'statusComment',
      //   title: 'Комментарий к статусу',
      //   useCustomTemplate: false,
      //   default: false
      // },
      {
        name: 'note',
        title: 'Примечание',
        useCustomTemplate: false,
        default: false
      },
      {
        name: 'comment',
        title: 'Комментарий',
        useCustomTemplate: false,
        default: false
      },
      {
        name: 'archive',
        title: 'Архив',
        useCustomTemplate: true,
        default: false,
      },
    ];
  }


}
