import {ElementRef, inject, Injectable, Renderer2} from '@angular/core';
import {DataTableService} from '../../../core/services/data-table.service';
import {BehaviorSubject} from 'rxjs';
import {FacilityRateService} from '../../apiModule/api/facilityRate.service';
import {FirmRateService} from '../../apiModule/api/firmRate.service';

@Injectable({
  providedIn: 'any'
})
export class RenderLastRowService<T extends FirmStat | FacilityStat> {
  facilityRateService = inject(FacilityRateService);
  firmRateService = inject(FirmRateService);
  dataTableService = inject(DataTableService,{host:true});
  renderer = inject(Renderer2);

  element?: ElementRef<HTMLTableElement>;
  count?: number;
  totalRating?: number;
  colspan?: number;
  type: 'firm' | 'facility' = 'firm';
  stat$ = new BehaviorSubject<T | null>(null);

  done$ = new BehaviorSubject<boolean>(true);


  public createRow(): void {
    if (!this.element) return console.error('element is null');

    //--- есть ли этот элемент в DOM? удаляем если есть ---
    const isSame = document.querySelector('.overall-row');
    if (isSame) isSame.remove();

    const columns = this.dataTableService.getCustomColumns();
    const tbody = this.element.nativeElement.querySelector('tbody');
    const overallRow = this.renderer.createElement('tr');
    overallRow.classList.add('overall-row');


    overallRow.innerHTML = `<td colspan='${this.colspan}'>ИТОГО: ${this.count ?? ''}</td>`;
    overallRow.innerHTML += `<td>${this.totalRating?.toFixed(2) ?? ''}%</td>`;
    for (let i = this.colspan! + 1; i < columns.length; i++) {
      const html = columns.length > 4 ? `<td>${
          this._getRateByColumnIndex(i, columns, this.stat$.value?.groupRatings)
            ? this._getRateByColumnIndex(i, columns, this.stat$.value?.groupRatings) + '%'
            : ''
        }
        </td>`
        : '';
      overallRow.innerHTML += html;
    }
    this.renderer.appendChild(tbody, overallRow);
    this.renderer.setStyle(overallRow, 'position', 'sticky');
    this.done$.next(true);
  }

  private _getRateByColumnIndex(columnIndex: number, columns: string[], rating?: Rate[]): number | undefined {
    const currentId: number | undefined = columns[columnIndex].startsWith('group') ? parseInt(columns[columnIndex].replace('group', '')) : undefined;
    if (!currentId) return;
    const ratingObj = rating?.find(el => el.id === currentId);
    return ratingObj?.rating;
  }

  init(type: 'firm' | 'facility', element: ElementRef<HTMLTableElement>, filter: FilterFormData|null): BehaviorSubject<boolean> {
    this.type = type;
    this.element = element;
    this.colspan = this.type === 'firm' ? 2 : 3;

    this.done$.next(false);

    const statMethod = this.type === 'firm'
      ? this.firmRateService.apiFirmRateStatsGet(filter ? {...removeEmptyProperties(filter)}:{})
      : this.facilityRateService.apiFacilityRateStatsGet(filter ? {...removeEmptyProperties(filter)}:{});


    statMethod.subscribe((stat: any) => {
      this.stat$.next(stat);
      this.count = this.type === 'firm' ? stat?.firmCount : stat?.facilityCount;
      this.totalRating = this.type === 'firm' ? stat?.totalRatingFirm : stat?.totalRatingFacilities;
      this.createRow();
    });

    return this.done$;
  }

}

function removeEmptyProperties<T extends object>(obj: T): Partial<T> {

  const res = {} as Partial<T>;
  Object.entries(obj).forEach(([key, value] :  [string, any]) => {
    if (value !== undefined && value !== null && (value as unknown as string) !== '') {
      res[key as keyof T] = value;
    }
  });
  return res;

}

