import {
  ChangeDetectorRef,
  Component,
  computed,
  DestroyRef,
  effect,
  inject,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import {DateAdapter, MAT_DATE_LOCALE} from '@angular/material/core';
import {CommonModule, DOCUMENT} from '@angular/common';
import {MatSelectModule} from '@angular/material/select';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BreakpointObserver} from '@angular/cdk/layout';
import {catchError, first, forkJoin, Observable, switchMap, tap, throwError} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIcon} from '@angular/material/icon';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialog,} from '@angular/material/dialog';
import {SideBarDoubleComponent} from '../../../shared/components/side-bar-double/side-bar-double.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {RemarktypeService} from '../../apiModule/api/remarktype.service';
import {RemarkType} from '../../apiModule/model/remarktype';
import {FacilityService} from '../../apiModule/api/facility.service';
import {AutocompleteComponent} from '../../../shared/components/autocomplete/autocomplete.component';
import {MatCheckbox} from '@angular/material/checkbox';
import {
  ApiRatecardIdPutRequestParams,
  ApiRatecardPostRequestParams,
  RateCardService
} from '../../apiModule/api/rate-card.service';
// import {CommunicationService} from '../../../core/services/communication.service';
import {ActivatedRoute, NavigationError, Router, RouterLink} from '@angular/router';
import {AccessableDirective} from '../../../core/directives/accessable.directive';
// import {ToAgreementComponent} from './to-agreement/to-agreement.component';
import {messages} from './messages';
import {StatusesService} from '../../../core/services/statuses.service';
import {changeState} from './chnageState';
import {CustomDateAdapter} from '../../../custom-date-adapter';
import {GradeRightSideComponent} from './grade-right-side/grade-right-side.component';
import {RegisterDialogDeleteComponent} from '../register/dialogs/dialogForDelete/register-dialog-delete.component';
import {RateCardFilesService} from '../../apiModule/api/rate-card-files.service';
import {takeUntilDestroyed, toObservable} from '@angular/core/rxjs-interop';
import {DoAgreeComponent} from './do-agree/do-agree.component';
import {ToastsComponent} from '../../../shared/components/toasts/toasts.component';
import {ToastsService} from '../../../core/services/toasts.service';
import {ModeService} from './mode.service';
import {StatusService} from './status.service';
import {Institution} from '../../apiModule/model/institution';
import {InstitutionsService} from '../../apiModule/api/institutions.service';
import {SendMailComponent} from './send-mail/send-mail.component';
import {UserService} from '../../../core/services/user.service';
import {SelectInspectorComponent} from './select-inspector/select-inspector.component';
import {AccountControllerService} from '../../apiModule/api/account-controller.service';


@Component({
  selector: 'ostso-grade',
  standalone: true,
  imports: [
    MatSelectModule,
    MatSidenavModule,
    MatIcon,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    CommonModule,
    MatDatepickerModule,
    SideBarDoubleComponent,
    ScrollingModule,
    AutocompleteComponent,
    MatCheckbox,
    RouterLink,
    AccessableDirective,
    GradeRightSideComponent,
    DoAgreeComponent,
    ToastsComponent,
    SendMailComponent,
    SelectInspectorComponent
  ],
  templateUrl: './grade.component.html',
  styleUrl: './grade.component.scss',
  providers: [
    {provide: DateAdapter, useClass: CustomDateAdapter}, // Используйте ваш пользовательский адаптер
    {provide: MAT_DATE_LOCALE, useValue: 'ru'}, // Установите нужную локаль
    ModeService,
    StatusService,
  ]
})
export class GradeComponent implements OnInit {

  @ViewChild('filesComponent', {read: GradeRightSideComponent}) gradeRightSideComponent!: GradeRightSideComponent;
  @ViewChild(ToastsComponent) toastsComponent!: ToastsComponent;


  showDoAgree = signal(false); // флаг появления окна согласия
  titleDoAgree = '';
  answerDoAgree = signal({value: false}); // ответ полученный из окна согласия
  answerDoAgree$ = toObservable(this.answerDoAgree);
  willCreateNew = signal(false); //создание нового замечания, переход на /grade, инициализация нужных значений

  emailSent = signal(false);
  enableEmailSend = signal(false); //ограничитель


  files: RateFile[] = [];
  preFilesData: Partial<RateFileDate>[] = []; // Предварительные данные

  public opened = true;
  mode = this.modeService.mode;

  allowEdit = signal(false);
  rightOnEdit = signal({} as Right);
  rightOnWatch = signal({} as Right);
  rightOnCreate = signal({
    remark: true,
    save: true,
    rate_object: true,
    comment: true,
    rate_date: true,
    sent_to_agreement_and_create: true,
    sent_to_agreement: true,
    edit: true,
    load_remark_docs: true,
  } as Right);
  currentRights = computed(() => ({
    edit: this.rightOnEdit(),
    watch: this.rightOnWatch(),
    create: this.rightOnCreate(),
  }[this.mode()]));

  titleCreate = 'Создать заявку';


  currentId?: number;
  status = inject(StatusService).status;
  archive = signal<StatusRateCard | undefined>(undefined);
  typeGrade = computed(() => {
    if (this.archive() !== undefined && this.archive()?.code === 'Archive') return 'В архиве';
    return this.status()?.name || '';
  });

  mapTitles: Record<string, { warning: string, success: string, error: string }> = messages;

  prevRemarktypeControlValue = signal<RemarkType | null>(null);

  remarktypeControl = new FormControl<RemarkType | null>(null);
  withoutRemarks = new FormControl<boolean>({value: false, disabled: true});
  facilityControl: FormControl = new FormControl<Facility | null>(null);
  groupControl = new FormControl({value: '', disabled: true});
  subGroupControl = new FormControl({value: '', disabled: true});
  kindControl = new FormControl({value: '', disabled: true});
  comment = new FormControl<string>('');
  dateControl = new FormControl<string>('');
  dateFixedControl = new FormControl<string>('');
  note = new FormControl<string>('');
  inspector = new FormControl<User | undefined>(undefined);

  private readonly titles = {
    create: 'Создание оценки',
    edit: 'Редактирование оценки',
    watch: 'Просмотр оценки',
  };
  public mobileOpen: boolean = false;
  public inspectorsArray: User[] = [];

  get title(): string {
    return this.titles[this.mode()];
  }

  get canShowDateFixed(): boolean {
    return this.status()?.code === 'firm_fixed'
      || this.status()?.code === 'inspector_agreement'
      || this.status()?.code === 'operator_approval'
      || this.status()?.code === 'fixed'
      || this.status()?.code === 'firm_revision'
      || this.status()?.code === 'inspector_agreement_revision';
  }

  remarktypesData: RemarkType[] = [];
  firmsData: Institution[] = [];
  facilitiesData: Facility[] = [];
  filteredFacilitiesData: Facility[] = [];


  filteredRemarktypes!: Observable<RemarkType[]>;

  currentChangingStatus = {
    id: -1,
    code: '',
    currentStatusCode: ''
  };

  constructor(
    private cdr: ChangeDetectorRef,
    private destroyRef: DestroyRef,
    private router: Router,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
    private institutionsService: InstitutionsService,
    private facilityService: FacilityService,
    private remarktypeService: RemarktypeService,
    private remarkCardService: RateCardService,
    public statusesService: StatusesService,
    private filesService: RateCardFilesService,
    private toasts: ToastsService,
    public modeService: ModeService,
    public user: UserService,
    private accountService: AccountControllerService,
  ) {

    this.answerDoAgree$.subscribe((answer: { value: boolean }) => {
      console.log('answerDoAgree$', answer);
      if (!answer.value) return;
      this.sendData()
        .pipe(switchMap((v) => this.setStatus(v.id, this.currentChangingStatus.id)))
        .subscribe({
          next: v => {
            console.log('sendData', v);
            this.updateRights(v);
            this.status.set(v?.status);
            this.archive.set(v?.archive);
            this.mode.set('watch');
            this.currentChangingStatus.code === 'without_remark'
              ? this.toasts.addToast({
                text: this.mapTitles[this.currentChangingStatus.code].success,
                type: 'check',
                duration: 2000
              })
              : this.toasts.addToast({
                text: this.mapTitles[this.currentChangingStatus.currentStatusCode + '->' + this.currentChangingStatus.code].success,
                type: 'check'
              });
            this.willCreateNew() && this.createNew();
            this.willCreateNew.set(false);
          },
          error: _ => {
            this.currentChangingStatus.code === 'without_remark'
              ? this.toasts.addToast({text: this.mapTitles[this.currentChangingStatus.code].error, type: 'check'})
              : this.toasts.addToast({
                text: this.mapTitles[this.currentChangingStatus.currentStatusCode + '->' + this.currentChangingStatus.code].error,
                type: 'check'
              });
          }
        });
    });

    this.breakpointObserver.observe([
      '(max-width: 1000px)'
    ]).subscribe(result => {
      this.opened = !result.matches;
    });

    effect(() => {
      if (this.mode() === 'create' || this.mode() === 'edit') {
        this.withoutRemarks.enable();
        this.inspector.enable();
      } else {
        this.withoutRemarks.disable();
        this.inspector.disable();
      }
    },{allowSignalWrites:true});

  }

  createNew(): void {
    this.router.navigate(['/grade']);
    this.mode.set('create');
    this.facilityControl.setValue(this.facilityControl.value);
    this.dateControl.setValue(this.dateControl.value);
    this.remarktypeControl.setValue(null);
    this.status() && (this.status.set(undefined));
    this.files = [];
  }

  updateRights(data: { rights: { mode: string, rights: Right }[], allowEdit: boolean }): void {
    this.allowEdit.set(data.allowEdit);
    if (!('rights' in data) || !Array.isArray(data.rights)) return;
    data.rights.forEach((e: { mode: string, rights: Right }) => {
      if (e.mode === 'edit') this.rightOnEdit.set(e.rights);
      if (e.mode === 'watch') this.rightOnWatch.set(e.rights);
    });
  }

  ngOnInit(): void {

    this.facilityControl.valueChanges.subscribe(v=> console.log('facilityControl change',v));

    this.inspector.disable();
    this.filteredRemarktypes = this.remarktypeControl.valueChanges.pipe(
      tap(console.log),
      startWith(''),
      map(value => this._filter(value || '', this.remarktypesData)),
    );
    this.facilityControl.valueChanges
      .subscribe(result => {
        console.log('facilityControl', result);
      });
    this.remarktypeControl.valueChanges
      .subscribe((result: RemarkType | null) => {
        console.log('remarktypeControl', result);
        this.groupControl.setValue(result?.rateGroup?.name || '');
        this.subGroupControl.setValue(result?.rateGroup?.name || '');
        this.kindControl.setValue(result?.rateKind?.name || '');
        this.withoutRemarks.setValue(result?.notHasRemarks || false,);
      });

    this.remarktypeService.apiRemarktypeGet({pageSize: 1e6, statusId: 1})
      .subscribe(data => {
        console.log(data);
        this.remarktypesData = data?.values || [];
        if (this.mode() !== 'create') return;
        // this.remarktypeControl.setValue(this.remarktypesData.find((e: RemarkType) => e.notHasRemarks) || null);
        // this.withoutRemarks.setValue(true);
      });

    // this.withoutRemarks.valueChanges.subscribe((result: boolean | null) => {
    //   if (this.mode() === 'watch') return;
    //   const foundNotHasRemarks = this.remarktypesData && this.remarktypesData.find((e: RemarkType) => e.notHasRemarks);
    //   if(result && foundNotHasRemarks){
    //     this.prevRemarktypeControlValue.set(this.remarktypeControl.value);
    //     this.remarktypeControl.setValue(foundNotHasRemarks, {emitEvent: false});
    //   }
    //   if(!result && this.remarktypesData){
    //     this.remarktypeControl.setValue(this.prevRemarktypeControlValue() || this.remarktypesData[0], {emitEvent: false});
    //   }
    // });
    this.facilityService.apiRemarktypeGet({pageSize: 1e6})
      .subscribe(data => {
        console.log(data);
        this.facilitiesData = data?.values || [];
      });
    this.institutionsService.apiRemarktypeGet({pageSize: 1e6})
      .subscribe(data => {
        console.log(data);
        this.firmsData = data?.values || [];
      });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationError) {
        console.error('Navigation Error:', event.error);
        this.router.navigate(['/404']);
      }
    });

    !this.route.snapshot.params['id'] && this.mode.set('create');
    this.route.snapshot.params['id'] && this.route.data.subscribe((v: any) => {
      const res = v['data'] ? v['data'] : v;
      this.files = res.rateFiles;
      this.preFilesData = res.rateFiles;
      this.updateRights(res);
      this.emailSent.set(res.emailSent);
      this.enableEmailSend.set(res.enableEmailSend);

      this.remarktypeService.apiRemarktypeIdGet({id: res.remarkType.id})
        .pipe(catchError(err => {
          return throwError(err);
        }))
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
            next: (v) => {
              this.remarktypeControl.setValue(v as RemarkType | null);
              this.prevRemarktypeControlValue.set(this.remarktypeControl.value);
              this.withoutRemarks.setValue(v.notHasRemarks);
            },
            error: () => {
              this.router.navigate(['/404']);
            }
          }
        );

      this.facilityService.apiRemarktypeIdGet({id: res.facility.id})
        .subscribe(
          v => {
            this.facilityControl.setValue(v as Facility | null);
          }
        );

      this.dateControl.setValue(res.createAt);
      this.dateFixedControl.setValue(res.finishedDate);
      this.comment.setValue(res.comment);
      this.note.setValue(res.note);
      this.currentId = res.id;
      this.status.set(res.status);
      this.archive.set(res.archive);
      !res.status && this.mode.set('create');

      // this.accountService.apiAccountGet({pageSize: 1e6})
      //   .pipe(map(v => v.values))
      //   .subscribe((result: User[] | null) => {
      //     if (!result) return;
      //     const foundInspector = result.find((e: User) => e.id === res?.inspector);
      //     this.inspector.setValue(foundInspector);
      //     this.inspectorsArray = result.filter((e: User) => e.userRoles[0].code === 'inspector');
      //     this.cdr.markForCheck();
      //   });
      this._setCurrentInspectorAndInspectorsArray((e: User) => e.id === res?.inspector);
    });

    // !this.route.snapshot.params['id'] && this.accountService.apiAccountGet({pageSize: 1e6})
    //   .pipe(map(v => v.values))
    //   .subscribe((result: User[] | null) => {
    //     if (!result) return;
    //     const foundInspector = result.find((e: User) => e.id === this.user.value.id);
    //     this.inspector.setValue(foundInspector);
    //     this.inspectorsArray = result.filter((e: User) => e.userRoles[0].code === 'inspector');
    //     this.cdr.markForCheck();
    //   });

    !this.route.snapshot.params['id'] && this._setCurrentInspectorAndInspectorsArray((e: User) => e.id === this.user.value.id);
  }

private _setCurrentInspectorAndInspectorsArray(compareFn: (e: User)=>boolean):void{
  this.accountService.apiAccountGet({pageSize: 1e6})
    .pipe(map(v => v.values))
    .subscribe((result: User[] | null) => {
      if (!result) return;
      const foundInspector = result.find(compareFn);
      this.inspector.setValue(foundInspector);
      this.inspectorsArray = result.filter((e: User) => e.userRoles[0].code === 'inspector');
      this.cdr.markForCheck();
    });
}

  private _filter(value: Named, data: RemarkType[]): RemarkType[] {
    return data.filter((option: RemarkType) => option.id === value.id);
  }

  onWithoutRemarksClick(): void {
    if (this.mode() === 'watch') return;
    const result = this.withoutRemarks.value;
    const foundNotHasRemarks = this.remarktypesData && this.remarktypesData.find((e: RemarkType) => e.notHasRemarks);
    if (result && foundNotHasRemarks) {
      this.prevRemarktypeControlValue.set(this.remarktypeControl.value);
      this.remarktypeControl.setValue(foundNotHasRemarks, {emitEvent: false});
    }
    if (!result && this.remarktypesData) {
      this.remarktypeControl.setValue(this.prevRemarktypeControlValue() || this.remarktypesData[0], {emitEvent: false});
    }
  }

  sendData(): Observable<any> {
    if (!this.remarktypeControl.value || !this.remarktypeControl.value.id) return throwError(() => new Error('Не выбрано замечание'));
    if (!this.dateControl.value) return throwError(() => new Error('Не задана дата'));
    if (!this.facilityControl.value) return throwError(() => new Error('Не выбрана организация'));

    const method = this.mode() !== 'create' ? 'apiRatecardIdPut' : 'apiRatecardPost';
    const postData: ApiRatecardPostRequestParams = {
      apiRatecardPostRequest: {
        facilityId: this.facilityControl.value.id,
        remarkTypeId: this.remarktypeControl.value.id,
        comment: this.comment.value || '',
        note: this.note.value || '',
        createAt: this.dateControl.value ? new Date(this.dateControl.value).toISOString() : new Date(Date.now()).toISOString(),
        finishedDate: (this.dateFixedControl.value && new Date(this.dateFixedControl.value).toISOString()) || null,
        inspectorId: this.inspector.value?.id || null,
      }
    };

    const putData: ApiRatecardIdPutRequestParams = {
      id: this.currentId!,
      ...postData,
    };
    return this.remarkCardService[method](putData)
      .pipe(
        tap(v => {
          this.status.set(v.status);
          this.archive.set(v.archive);
          this.toasts.addToast({text: 'Оценка сохранена', type: 'check'});
          // this.router.navigate([`/grade/${v.id}`]);
          this.mode.set('watch');
        })
      );
  }

  saveAddedFiles(): void {
    if (this.gradeRightSideComponent
      .addedFiles.length) {
      this.toasts.addToast({text: 'Сохраняем добавленные файлы', type: 'info'});
      forkJoin(this.gradeRightSideComponent
        .addedFiles
        .map(e => this.filesService.apiRatecardFileCardIdTypeTypeIdPost({
          file: e.data || new Blob(),
          cardId: this.currentId!,
          typeId: e.type.id
        })))
        .pipe(first())
        .subscribe({
          next: () => {
            this.toasts.addToast({text: 'Файлы замечаний добавлены', type: 'check'});
          },
          error: v => this.toasts.addToast({text: 'Ошибка сохранения!' + v.message, type: 'check'})
        });
    }
  }

  removeFiles(): void {
    if (this.gradeRightSideComponent.filesToRemove.length) {
      this.toasts.addToast({text: 'Отправляем файлы на удаление', type: 'info'});
      forkJoin(this.gradeRightSideComponent
        .filesToRemove
        .filter(e => e.id !== 0)
        .map(e => this.filesService.apiRatecardFileFileIdDelete({
          fileId: e.id
        }))).subscribe({
        next: () => this.toasts.addToast({text: 'Файлы удалены', type: 'check'}),
        error: v => this.toasts.addToast({text: 'Ошибка удаления!' + v.message, type: 'error'})
      });
    }
  }

  save(): void {
    if (this.status()?.code === 'firm_fixed' && this.dateFixedControl.invalid) return this.toasts.addToast({
      text: 'Вы забыли указать дату',
      type: 'error'
    });
    this.sendData()
      .pipe(first())
      .subscribe(
        {
          next: v => {
            this.currentId = v.id;
            this.mode.set('watch');
            this.updateRights(v);
            this.saveAddedFiles();
            this.removeFiles();
          },
          error: v => this.toasts.addToast({text: 'Ошибка сохранения! ' + v.message, type: 'error'})
        });

  }


  onCopyUrl(): void {
    const fullUrl = window.location.href;
    navigator.clipboard.writeText(fullUrl);
    this.toasts.addToast({text: 'Ссылка скопирована в буфер обмена', type: 'check'});
  }

  setStatus(id: number, statusId: number): Observable<any> {
    return this.remarkCardService.apiRatecardSetstatusIdPut({
      id,
      apiRatecardSetstatusIdPutRequest: {statusId}
    }).pipe(tap(v => console.log('setStatus', v)));
  }

  changeStatus(code: StatusRemarkTypeCodes | undefined): void {
    if (!code) return;
    this.currentChangingStatus.code = code;
    this.currentChangingStatus.id = this.statusesService.getStatusByCode(code)?.id || -1;
    this.currentChangingStatus.currentStatusCode = this.status()?.code || '';

    this.titleDoAgree = code === 'without_remark' ? this.mapTitles[code].warning : this.mapTitles[this.currentChangingStatus.currentStatusCode + '->' + code].warning;
    this.showDoAgree.set(true);
  }

  toAgreementAndNew(): void {
    // const dialogRef = this.dialog.open(ToAgreementComponent, {
    //   maxWidth: '300px',
    //   data: {
    //     title: 'Вы уверены, что хотите отправить оценку на согласование оператору?'
    //   }
    // });


    // dialogRef.afterClosed().subscribe(result => {
    //   if (!result) return;
    //   // this.sendData().pipe(switchMap((v) => this.setStatus(v.id, 2))).subscribe(
    //   //   () => this.createNew()
    //   // );
    //   this.sendToAgreement();
    //   this.createNew();
    // });
    this.willCreateNew.set(true);
    this.sendToAgreement();

  }

  finishRate(): void {
    console.log('finish');
  }

  async sendToAgreement(): Promise<boolean> {
    if (this.gradeRightSideComponent.filesArray.length === 0) {
      this.toasts.addToast({
        text: 'Вы забыли добавить файлы',
        type: 'error'
      });
      return false;
    }

    if (this.status()?.code === 'firm_fixed'
      && (this.gradeRightSideComponent.filesArray.length === 0
        || this.gradeRightSideComponent.filesForElimination.length === 0)) {
      this.toasts.addToast({
        text: 'Вы забыли добавить файлы об устранении замечаний',
        type: 'error'
      });
      return false;
    }


    if (this.status()?.code === 'firm_fixed' && (this.dateFixedControl.invalid || !this.dateFixedControl.value)) {
      this.toasts.addToast({
        text: 'Вы забыли указать дату',
        type: 'error'
      });
      return false;
    }
    this.status()?.code && this.changeStatus(changeState('sendToAgreement', this.status()?.code));
    return true;
  }

  accept(): void {
    if (this.gradeRightSideComponent.filesArray.length === 0 && this.gradeRightSideComponent.addedFiles.length === 0) return this.toasts.addToast({
      text: 'Отсутствуют документы',
      type: 'error'
    });
    this.status()?.code && this.changeStatus(changeState('accept', this.status()?.code));
  }

  agree(): void {
    this.status()?.code && this.changeStatus(changeState('agree', this.status()?.code));
  }

  disagree(): void {
    this.status()?.code && this.changeStatus(changeState('disagree', this.status()?.code));
  }

  reject(): void {
    this.status()?.code && this.changeStatus(changeState('reject', this.status()?.code));
  }

  saveWithoutRemarks(): void {
    this.status()?.code && this.changeStatus(changeState('without_remark', this.status()?.code));
  }

  onEdit(): void {
    this.mode() === 'edit' ? this.mode.set('watch') : this.mode.set('edit');
    // this.mode() === 'edit' ? this.inspector.disable() : this.inspector.enable();
  }


  deleteElement($event: MouseEvent, element: any): void {
    console.log(element);
    const dialogRef = this.dialog.open(RegisterDialogDeleteComponent, {
      width: 'max(320px,70vw)',
      data: {id: this.currentId},
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      const id = result.id;
      delete result.id;
      this.remarkCardService.apiRatecardSetarchiveIdPut({id, apiRatecardSetstatusIdPutRequest: result})
        .subscribe({
          next: (v) => {
            this.toasts.addToast({text: 'Оценка отправлена в архив', type: 'check'});
            this.archive.set(v.archive);
            this.status.set(v.status);
          },
          error: err => this.toasts.addToast({text: 'Ошибка сохранения!' + err.message, type: 'error'})
        });
    });
  }

  onRestore(): void {
    if (!this.currentId) return;
    this.remarkCardService.apiRatecardSetarchiveIdPut({
      id: this.currentId,
      apiRatecardSetstatusIdPutRequest: {statusId: 0}
    })
      .subscribe({
        next: (v) => {
          this.toasts.addToast({text: 'Оценка восстановлена', type: 'check'});
          this.archive.set(v.archive);
          this.status.set(v.status);
        },
        error: err => this.toasts.addToast({text: 'Ошибка восстановления!' + err.message, type: 'error'})
      });
  }


  toggeleAgree(): void {
    this.showDoAgree.update(e => !e);
  }


  protected readonly window = window;

  onClickOutside(): void {
    if (!this.mobileOpen) return;
    this.mobileOpen = false;
  }
}
