import {Inject, Injectable} from '@angular/core';
import {AccountControllerService} from '../../modules/apiModule/api/account-controller.service';
import {StorageService} from './storage.service';
import {USERKEY} from '../../app.config';
import {BehaviorSubject} from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly emptyUser = {
    id: -1,
    emailAddress: '',
    firstName: '',
    lastName: '',
    middleName: '',
    userName: '',
    rights: {
      edit: {},
      watch: {},
      list:{}
    } as UserRights,
    userRoles: [],
    phone: '',
    position: '',
    rateStatusIntervalList:[]
  };

  get isEmpty(): boolean{
    const {emailAddress, firstName, lastName, middleName, userName, rights} = this.user$.value;
    return !emailAddress && !firstName && !lastName && !middleName && !userName && !rights;
  }

  get rights(): UserRights {
    return this.user$.value.rights;
  }

  // private userData: User = this.emptyUser;

  user$ = new BehaviorSubject<User>(this.emptyUser);

  user = this.user$.asObservable();

  get value(): User {
    return this.user$.value;
  }

  get isAdmin(): boolean{
    return !!this.value.userRoles.find(role => role.name === 'Администратор системы');
  }
  get isInspector(): boolean{
    return !!this.value.userRoles.find(role => role.name === 'Инспектор');
  }
  get isOperator(): boolean{
    return !!this.value.userRoles.find(role => role.name === 'Оператор');
  }
  get isFacility(): boolean{
    return !!this.value.userRoles.find(role => role.name === 'Учреждение');
  }

  constructor(
    private accountController: AccountControllerService,
    private storage: StorageService,
    @Inject(USERKEY) private userkey: string,
  ) {
  }


  clearUser(): void {
    this.user$.next(this.emptyUser);
  }

  fetchUser(): void {
    console.log('Fetching user');
    const key = this.storage.getItem(this.userkey);
    if (!key) return;
    this.accountController.apiAccountCurrentGet('body').subscribe({
      next: (data) => {
        this.user$.next(data);
        console.info('user', data);
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

}


