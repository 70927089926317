<main>

  <mat-sidenav-container [class.opened]="opened">
    <mat-sidenav #sidenav mode="side" [(opened)]="opened" class="main-container" [class.mobile-open]="mobileOpen">
      <ostso-side-bar-double
        [titleCreate]="titleCreate"
        [type]="type"
        (toggle)="sidenav.toggle()"
        [opened]="sidenav.opened"
        (clickOutside)="onClickOutside()"
      />
    </mat-sidenav>

    <mat-sidenav-content>
      <section>
        <div class="register-header">
          <ostso-register-header (open)="mobileOpen=true"/>
          <div class="header-filter">
            <div class="header-btn" (click)="excel()">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_0_424" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="19"
                      height="19">
                  <rect width="18.3415" height="18.3415" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask0_0_424)">
                  <path
                    d="M14.5204 14.5202H6.11393C5.69361 14.5202 5.33378 14.3706 5.03446 14.0712C4.73514 13.7719 4.58548 13.4121 4.58548 12.9918V2.29259C4.58548 1.87226 4.73514 1.51244 5.03446 1.21311C5.33378 0.913791 5.69361 0.76413 6.11393 0.76413H11.4635L16.0489 5.3495V12.9918C16.0489 13.4121 15.8992 13.7719 15.5999 14.0712C15.3006 14.3706 14.9408 14.5202 14.5204 14.5202ZM10.6993 6.11372V2.29259H6.11393V12.9918H14.5204V6.11372H10.6993ZM3.05702 17.5771C2.63669 17.5771 2.27687 17.4275 1.97755 17.1282C1.67823 16.8288 1.52856 16.469 1.52856 16.0487V5.3495H3.05702V16.0487H11.4635V17.5771H3.05702Z"
                    fill="#272B37"/>
                </g>
              </svg>

              Excel
            </div>

            <div class="header-btn blue refresh" (click)="refresh()">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_0_158" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="19"
                      height="19">
                  <rect x="0.341431" width="18.3415" height="18.3415" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask0_0_158)">
                  <path
                    d="M4.23909 12.2658C3.95887 11.7817 3.74871 11.285 3.6086 10.7755C3.46849 10.266 3.39844 9.7438 3.39844 9.20885C3.39844 7.50207 3.99071 6.05004 5.17527 4.85275C6.35982 3.65546 7.80548 3.05681 9.51226 3.05681H9.646L8.42323 1.83405L9.49315 0.76413L12.5501 3.82104L9.49315 6.87795L8.42323 5.80803L9.646 4.58527H9.51226C8.23855 4.58527 7.15589 5.03425 6.26429 5.93222C5.37269 6.83019 4.92689 7.9224 4.92689 9.20885C4.92689 9.54001 4.9651 9.86481 5.04153 10.1832C5.11795 10.5017 5.23258 10.8137 5.38543 11.1194L4.23909 12.2658ZM9.53137 17.5771L6.47445 14.5202L9.53137 11.4633L10.6013 12.5332L9.37852 13.756H9.51226C10.786 13.756 11.8686 13.307 12.7602 12.409C13.6518 11.5111 14.0976 10.4189 14.0976 9.13242C14.0976 8.80126 14.0594 8.47646 13.983 8.15803C13.9066 7.8396 13.7919 7.52754 13.6391 7.22185L14.7854 6.07551C15.0656 6.55952 15.2758 7.05627 15.4159 7.56576C15.556 8.07524 15.6261 8.59746 15.6261 9.13242C15.6261 10.8392 15.0338 12.2912 13.8493 13.4885C12.6647 14.6858 11.219 15.2845 9.51226 15.2845H9.37852L10.6013 16.5072L9.53137 17.5771Z"
                    fill="#4674FF"/>
                </g>
              </svg>
              Обновить
            </div>

            <div class="header-input">
              <mat-icon matPrefix>search</mat-icon>
              <input matInput [(ngModel)]="searchPhrase" (ngModelChange)="onSearch()">
            </div>


            <a class="filters-btn" (click)="openFilterDialog()">
              <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_0_435" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="25"
                      height="25">
                  <rect x="0.682861" y="0.5" width="24" height="24" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask0_0_435)">
                  <path
                    d="M11.6828 20.5C11.3995 20.5 11.162 20.4042 10.9703 20.2125C10.7787 20.0208 10.6828 19.7833 10.6828 19.5V13.5L4.88282 6.1C4.63282 5.76667 4.59532 5.41667 4.77032 5.05C4.94532 4.68333 5.24949 4.5 5.68282 4.5H19.6828C20.1162 4.5 20.4203 4.68333 20.5953 5.05C20.7703 5.41667 20.7328 5.76667 20.4828 6.1L14.6828 13.5V19.5C14.6828 19.7833 14.587 20.0208 14.3953 20.2125C14.2037 20.4042 13.9662 20.5 13.6828 20.5H11.6828ZM12.6828 12.8L17.6328 6.5H7.73282L12.6828 12.8Z"
                    fill="#E22B36"/>
                </g>
              </svg>
            </a>

          </div>

          <div class="header-indicators">
            <ostso-filter-indicators-bar [filter]="filters$.value"></ostso-filter-indicators-bar>
            <button class="reset-filters" (click)="resetFilters()">Сбросить все</button>
          </div>

        </div>

        <ostso-table [updateList]="updateList.asObservable()"
                     (pageChange)="onPageChange($event)"
                     (sortedColumn)="onSortedColumn($event)">
          <ng-template ostsoCellTemplate="isInspectionRequired" let-element>
            <div class="check-box__container" (click)="changeInspectionRequired($event,element)">
              <input type="checkbox" class="check-box" [checked]="element['isInspectionRequired']"/>
            </div>
          </ng-template>
          <ng-template ostsoCellTemplate="isLongspanStructureObject" let-element>
            {{ element['isLongspanStructureObject'] ? 'Да' : 'Нет' }}
          </ng-template>
          <ng-template ostsoCellTemplate="startDate" let-element>
            {{ element['startDate'] | date:'dd.MM.yy' }}
          </ng-template>
          <ng-template ostsoCellTemplate="district" let-element>
            {{ element['district']['name'] }}
          </ng-template>
          <ng-template ostsoCellTemplate="region" let-element>
            {{ element['region']['name'] }}
          </ng-template>
          <ng-template ostsoCellTemplate="isInvestmentObject" let-element>
            {{ element['isInvestmentObject'] ? 'Да' : 'Нет' }}
          </ng-template>
          <!--          <ng-template ostsoCellTemplate="isInspectionRequired" let-element>-->
          <!--            {{ element['isInspectionRequired'] ? 'Да' : 'Нет' }}-->
          <!--          </ng-template>-->
          <ng-template ostsoCellTemplate="status" let-element>
            <div [attr.archived]="element['status']['code'] === 'Archive'">
              {{ element['status']['name'] }}
            </div>
          </ng-template>
          <ng-template ostsoCellTemplate="other" let-element>
            {{ element.title }} {{ element.isSelected }}
          </ng-template>
          <ng-template ostsoCellTemplate="contact" let-element>
            @if (Array.isArray(element.contact)) {
              <div *ngFor="let i of element.contact" class="tel">
                <a href="tel:{{ i }}">{{ i }}</a>
              </div>
            } @else {
              <a href="tel:{{ element.contact }}">{{ element.contact }}</a>
            }
          </ng-template>
          <ng-template ostsoCellTemplate="isSelected" let-element>
            <div style="width: 20px;height: 20px"
                 [ngStyle]="element.isSelected ? {'background': 'green'} : {'background': 'red'}"></div>
          </ng-template>
          <ng-template ostsoCellTemplate="balanceHolder" let-element>
            <div class="name-facility">
              {{ element['balanceHolder']['shortName'] || element['balanceHolder']['name'] }}
            </div>
          </ng-template>
          <ng-template ostsoCellTemplate="nameFacility" let-element>
            <div class="name-facility">
              {{ element['nameFacility'] }}
            </div>
          </ng-template>
          <ng-template ostsoCellTemplate="name" let-element>
            <div class="name" [attr.archived]="element['status']['code'] === 'Archive'">
              <div>{{ element['name'] || element['shortName'] }}</div>
              @if (element['status']['code'] === 'Archive') {
                <div class="width-limited">
                  <strong>В архиве с: </strong>
                  <span>{{ element['status']['date'] | date:'dd.MM.yyyy' }}</span>
                </div>
                <div class="width-limited">
                  <strong> Причина: </strong>
                  <span>{{ element['status']['comment'] }}</span>
                </div>
              }
            </div>
          </ng-template>
          <ng-template ostsoCellTemplate="rateGroup" let-element>
            <span class="remarktype-span"
                  [attr.data-tooltip]="element['rateGroup']">{{ element['rateGroup'] | first3 }}</span>
          </ng-template>
          <ng-template ostsoCellTemplate="rateSubGroup" let-element>
            <span class="remarktype-span"
                  [attr.data-tooltip]="element['rateSubGroup']">{{ element['rateSubGroup'] | first3 }}</span>
          </ng-template>
          <ng-template ostsoCellTemplate="rateKind" let-element>
            <span class="remarktype-span"
                  [attr.data-tooltip]="element['rateKind']">{{ element['rateKind'] | first3 }}</span>
          </ng-template>
          <ng-template ostsoCellTemplate="significance" let-element>
            {{ element['significance'] + '%' }}
          </ng-template>
          <ng-template ostsoCellTemplate="iasId" let-element>
            <div class="iasId">{{ element['iasId'] }}</div>
          </ng-template>

          <ng-template ostsoActionTemplate let-element>
            @if (currentUser.rights.watch[type] || currentUser.rights.list.registry_archive) {
              <button mat-icon-button [matMenuTriggerFor]="actionMenu" #menuTrigger="matMenuTrigger">
                <mat-icon>menu</mat-icon>
              </button>
              <mat-menu #actionMenu="matMenu" class="custom-menu">
                <!--              <a mat-menu-item class="close-button" (click)="menuTrigger.closeMenu()">-->
                <!--                <mat-icon>close</mat-icon>-->
                <!--              </a>-->
                @if (contentMenuItems.edit && currentUser.rights.watch[type]) {
                  <a mat-menu-item (click)="onRowSelected($event, element)">{{ contentMenuItems.edit }}</a>
                }
                @if (contentMenuItems.delete && element.status.code !== 'Archive' && currentUser.rights.list.registry_archive) {
                  <a mat-menu-item (click)="deleteElement($event, element)">{{ contentMenuItems.delete }}</a>
                }
                @if (contentMenuItems.restore && element.status.code === 'Archive') {
                  <a mat-menu-item (click)="restoreElement(element)">{{ contentMenuItems.restore }}</a>
                }
              </mat-menu>
            }
          </ng-template>
          <ng-template ostsoCellTemplate="notHasRemarks" let-element>
            <div class="notHasRemarks">
              @if (element['notHasRemarks']) {
                <mat-icon>check</mat-icon>
              } @else {
                <mat-icon>check_box_outline_blank</mat-icon>
              }
            </div>
          </ng-template>

        </ostso-table>

        @if (legendEnabled) {
          <div class="legend">
            <span>Приоритет:</span>
            <div>
              <span style="background-color: #EAFA30;"></span>Низкий
            </div>
            <div>
              <span style="background-color: #61C33E;"></span>Средний
            </div>
            <div>
              <span style="background-color: #E22B36;"></span>Высокий
            </div>
            <div>
              <span style="background-color: #3D6DCE;"></span>Критический
            </div>
          </div>
        }
      </section>
    </mat-sidenav-content>
  </mat-sidenav-container>


</main>

