<button mat-icon-button class="close-button" (click)="onNoClick()">
  <mat-icon>close</mat-icon>
</button>
<div class="ostso-dialog" [class.blocked]="!loaded()">
  @if (!loaded()) {
    <ostso-fallback/>
  }
  <h1>Фильтр</h1>
  <form [formGroup]="form" class="filter-form">

    <ostso-multi-select [formControlName]="'firmIds'"
                        [data]="firms()??[]"
                        [placeholder]="'Учреждение'"
    >
      <ng-template let-item="item">
        <span><strong>{{ item?.name }}</strong> <div>({{ item?.shortName }}) (ID: <strong>{{ item?.id }}</strong>)</div><br/></span>
      </ng-template>
    </ostso-multi-select>

    <ostso-multi-select [formControlName]="'facilityIds'"
                        [data]="facilities()??[]"
                        [placeholder]="'Объекты'"
    >
      <ng-template let-item="item">
        <span><strong>{{ item?.name }}</strong> <div>({{ item?.shortName }}) (ID: <strong>{{ item?.id }}</strong>)</div><br/></span>
      </ng-template>
    </ostso-multi-select>

    <ostso-quick-autocomplete [label]="'Адрес объекта'"
                              [data]="facilityAddresses()??[]"
                              [formControlName]="'facilityAddress'"
    >
      <ng-template let-item="option">
        {{ item }}
      </ng-template>
    </ostso-quick-autocomplete>

    <ostso-multi-select [formControlName]="'remarkGroupIds'"
                        [data]="groups()??[]"
                        [placeholder]="'Группа замечаний'"
    >
      <ng-template let-item="item">
        <span><strong>{{ item?.name }}</strong> <div>({{ item?.shortName }}) (ID: <strong>{{ item?.id }}</strong>)</div><br/></span>
      </ng-template>
    </ostso-multi-select>

    <ostso-multi-select [formControlName]="'remarkSubGroupIds'"
                        [data]="subgroups()??[]"
                        [placeholder]="'Подгруппа замечаний'"
    >
      <ng-template let-item="item">
        <span><strong>{{ item?.name }}</strong> <div>({{ item?.shortName }}) (ID: <strong>{{ item?.id }}</strong>)</div><br/></span>
      </ng-template>
    </ostso-multi-select>

    <ostso-multi-select [formControlName]="'facilityTypeIds'"
                        [data]="facilityTypes()??[]"
                        [placeholder]="'Тип объекта'"
    >
      <ng-template let-item="item">
        <span><strong>{{ item?.name }}</strong></span>
      </ng-template>
    </ostso-multi-select>

    <ostso-multi-select [formControlName]="'remarkPriorityIds'"
                        [data]="priorities()??[]"
                        [placeholder]="'Приоритет'"
    >
      <ng-template let-item="item">
        <span><strong>{{ item?.name }}</strong></span>
      </ng-template>
    </ostso-multi-select>

    <mat-form-field appearance="outline">
      <mat-label>Объект БПК</mat-label>
      <mat-select formControlName="isLongspanStructureObject">
        <mat-option value="null">Не выбрано</mat-option>
        <mat-option value="true">Да</mat-option>
        <mat-option value="false">Нет</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Объект инвестстроительства</mat-label>
      <mat-select formControlName="isInvestmentObject">
        <mat-option value="null">Не выбрано</mat-option>
        <mat-option value="true">Да</mat-option>
        <mat-option value="false">Нет</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Просроченные</mat-label>
      <mat-select formControlName="IsOverdueRemark">
        <mat-option value="null">Не выбрано</mat-option>
        <mat-option value="true">Да</mat-option>
        <mat-option value="false">Нет</mat-option>
      </mat-select>
    </mat-form-field>

    <h5 style="margin: 0 0 -0.5rem;">Период оценки</h5>
    <div class="date-estimate">
      <mat-form-field
        appearance="outline"
      >
        <mat-label>Дата проведения оценки от:</mat-label>
        <mat-datepicker-toggle matIconPrefix [for]="pickerFrom"></mat-datepicker-toggle>
        <mat-datepicker #pickerFrom></mat-datepicker>
        <input matInput [matDatepicker]="pickerFrom" [formControlName]="'createAt'">
      </mat-form-field>
      <mat-form-field
        appearance="outline"
      >
        <mat-label>Дата проведения оценки до:</mat-label>
        <mat-datepicker-toggle matIconPrefix [for]="pickerTo"></mat-datepicker-toggle>
        <mat-datepicker #pickerTo></mat-datepicker>
        <input matInput [matDatepicker]="pickerTo" [formControlName]="'finishedDate'">
      </mat-form-field>
    </div>

    <h5 style="margin: 0 0 -0.5rem;">Группировка</h5>
    <form [formGroup]="formGroups" class="group-form">
      @for (checkbox of checkboxes; track checkbox) {
        <div>
          <label class="group-form__label">
            <input
              style="scale: 2 2; accent-color: red;"
              type="radio"
              [formControlName]="'groupingControl'"
              [value]="checkbox.key"
            />
            {{ checkbox.label }}
          </label>
        </div>
      }
    </form>


  </form>
  <div mat-dialog-actions class="filter-form__actions">
    <button [ngClass]="['btn', form.status==='VALID' ? 'btn-blue': 'btn-gray']"
            [ngStyle]="{color:form.status==='VALID' ? '#fff' : '#777'}"
            mat-button
            [disabled]="form.status!=='VALID'"
            (click)="onApply()"
    >
      Применить
    </button>
    <button class="btn btn-secondary" mat-button (click)="onReset()">Сбросить</button>
  </div>

</div>

