import {Component, Inject, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms'; // Import ReactiveFormsModule

import {MAT_DIALOG_DATA, MatDialogContent, MatDialogRef,} from '@angular/material/dialog';
import {MatButtonModule, MatIconButton} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {DashboardService} from '../../apiModule/api/dashboard.service';
import {GroupEstimate} from '../../apiModule/model/groupEstimate';

interface Facility {
  id: number;
  rank: number;
  name: string;
  shortName: string;
  address: string;
  balanceHolder: {
    id: number;
    name: string;
    shortName: string;
  };
  count: number;
}

@Component({
  selector: 'ostso-dashboard-modal',
  templateUrl: 'dashboard-modal.component.html',
  styleUrls: ['./dashboard-modal.component.scss', '../dashboard/dashboard.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogContent,
    MatIconButton,
    MatIcon,
    CommonModule,
    ReactiveFormsModule
  ],
})
export class DashboardModal implements OnInit {

  public topFacility: Facility[] = [];
  public maxTopFacilityCount: any;
  public maxTopGroupsCount: number = 1;
  public maxTopSubgroupsCount: number = 1;
  public maxTopFacilityTypesCount: number = 1;
  public topFirms: any[] = [];
  public maxTopFirmsCount: any;
  public facilityFilter = new FormControl('');
  public firmFilter = new FormControl('');
  public groupFilter = new FormControl('');
  public subgroupFilter = new FormControl('');
  public facilityTypesFilter = new FormControl('');
  public filteredFacilities: any[] = [];
  public filteredFirms: any[] = [];
  public topGroups: any[] = [];
  public topSubgroups: any[] = [];
  public topFacilityTypes: any[] = [];
  public filteredGroups: any[]=[];
  public filteredSubgroups: any[]=[];
  public filteredFacilityTypes: any[]=[];

  constructor(
    public dialogRef: MatDialogRef<DashboardModal>,
    @Inject(MAT_DIALOG_DATA) public data: { type: string },
    private dashboardService: DashboardService,
  ) {
  }

  ngOnInit(): void {
    if (this.data.type === 'objects') {
      this.dashboardService.apiDashboardActiveremarkTopfacilityGet({}).subscribe((value: any) => {
        this.topFacility = this.filteredFacilities = value.values;
        this.maxTopFacilityCount = Math.max(...this.topFacility.map((facility: Facility) => facility.count));
      });
    }

    if (this.data.type === 'institutions') {
      this.dashboardService.apiDashboardActiveremarkTopFirmAllGet({}).subscribe((value: any) => {
        this.topFirms = this.filteredFirms = value.values;
        this.maxTopFirmsCount = Math.max(...this.topFirms.map((facility: Facility) => facility.count));
      });
    }

    if (this.data.type === 'groups') {
      this.dashboardService.apiDashboardActiveremarkAllGroupGet().subscribe((value: any) => {
        this.topGroups = this.filteredGroups = value.values;
        this.maxTopGroupsCount = Math.max(...this.topGroups.map((v: any) => v.activeRemarksCount));
      });
    }

    if (this.data.type === 'subgroups') {
      this.dashboardService.apiDashboardActiveremarkAllBySubgroupGet().subscribe((value: any) => {
        this.topSubgroups = this.filteredSubgroups = value.values;
        this.maxTopSubgroupsCount = Math.max(...this.topSubgroups.map((v: any) => v.activeRemarksCount));
      });
    }

    if (this.data.type === 'facilityTypes') {
      this.dashboardService.apiDashboardActiveremarkByAllFacilitytypeGet().subscribe((value: any) => {
        this.topFacilityTypes = this.filteredFacilityTypes = value.values;
        this.maxTopFacilityTypesCount = Math.max(...this.topFacilityTypes.map((v: any) => v.remarkCount));
      });
    }

    this.facilityFilter.valueChanges.subscribe(() => this.filterFacilities());
    this.firmFilter.valueChanges.subscribe(() => this.filterFirms());
    this.groupFilter.valueChanges.subscribe(() => this.filterGroups());
    this.subgroupFilter.valueChanges.subscribe(() => this.filterSubgroups());
    this.facilityTypesFilter.valueChanges.subscribe(() => this.filterFacilityTypes());


  }

  // Filter functions
  filterFacilities(): void {
    const filterValue = this.facilityFilter.value ? this.facilityFilter.value.toLowerCase() : '';
    if (filterValue === '') {
      this.filteredFacilities = this.topFacility;
      return;
    }
    this.filteredFacilities = this.topFacility.filter((facility: Facility) => facility.shortName.toLowerCase().includes(filterValue));
  }

  filterFirms(): void {
    const filterValue = this.firmFilter.value ? this.firmFilter.value.toLowerCase() : '';
    this.filteredFirms = this.topFirms.filter((firm: Facility) => firm.shortName.toLowerCase().includes(filterValue));
  }

  filterGroups(): void {
    const filterValue = this.groupFilter.value ? this.groupFilter.value.toLowerCase() : '';
    this.filteredGroups = this.topGroups.filter((v: GroupEstimate) => v.name.toLowerCase().includes(filterValue));
  }

  filterSubgroups(): void {
    const filterValue = this.subgroupFilter.value ? this.subgroupFilter.value.toLowerCase() : '';
    this.filteredSubgroups = this.topSubgroups.filter((v: any) => v.name.toLowerCase().includes(filterValue));
  }

  filterFacilityTypes(): void {
    const filterValue = this.facilityTypesFilter.value ? this.facilityTypesFilter.value.toLowerCase() : '';
    this.filteredSubgroups = this.topFacilityTypes.filter((v: any) => v.name.toLowerCase().includes(filterValue));
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
