import {ChangeDetectionStrategy, Component, computed, inject, Input, signal} from '@angular/core';
import {DecimalPipe} from '@angular/common';
import {toSignal} from '@angular/core/rxjs-interop';
import {
  ApiDashboardActiveremarkByFacilitytypeGetRequestParams,
  DashboardService
} from '../../../../apiModule/api/dashboard.service';

@Component({
  selector: 'ostso-statistics-dashboard-widget',
  standalone: true,
  imports:[
    DecimalPipe
  ],
  templateUrl: './statistics-dashboard-widget.component.html',
  styleUrls: ['./statistics-dashboard-widget.component.scss','../../dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatisticsDashboardWidgetComponent {
  dashboardService = inject(DashboardService);
  private _filter?: ApiDashboardActiveremarkByFacilitytypeGetRequestParams;
  statistics = signal<any>({});
  @Input() set filter(v: ApiDashboardActiveremarkByFacilitytypeGetRequestParams) {
    this._filter = v;
    this.dashboardService.apiDashboardRemarkStatisticsGet({...this.filter || {}}).subscribe(x => {
      this.statistics.set(x);
    });
  }
  // statistics = toSignal(this.dashboardService.apiDashboardRemarkStatisticsGet({...this.filter || {}}));

  activePercentage = computed(() =>
    (this.statistics()?.activeRemarks / this.statistics()?.totalRemarks) * 100
  );

  resolvedPercentage = computed(() => (this.statistics()?.resolvedRemarks / this.statistics()?.totalRemarks) * 100);

}
