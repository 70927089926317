<div class="linechart-total">
  <div class="white">
    <div>Активных замечаний</div>
    <div>{{ statistics()?.activeRemarks}} ({{ activePercentage() | number:'1.0-2' }}%)</div>
  </div>
  <div class="white">
    <div>Устраненных замечаний</div>
    <div>{{ statistics()?.resolvedRemarks}} ({{ resolvedPercentage() | number:'1.0-2' }}%)</div>
  </div>
  <div class="red">
    <div>Всего замечаний</div>
    <div>{{ statistics()?.totalRemarks}}</div>
  </div>
</div>
