<div class="by-groups-dashboard-widget">
  <h4>Активные замечания по группам замечаний
    <span (click)="showMoreInfo('groups')">Подробнее</span>
  </h4>

  <div *ngFor="let item of byGroup()" class="horizontal-bar-chart">
    <div>
      <div class="title">{{item.name}}</div>
      <div class="bar"><div [style]="{width: (item.activeRemarksCount/ maxGroupValue() * 100)  + '%'}">{{item.activeRemarksCount}}</div></div>
    </div>
  </div>

</div>
