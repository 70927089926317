import {CommonModule, DatePipe} from '@angular/common';
import {Component, DestroyRef, inject, OnInit, ViewChild} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MatIconButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';
import {PageEvent} from '@angular/material/paginator';
import {BehaviorSubject, catchError, combineLatest, first, Observable, of, switchMap, takeWhile, tap} from 'rxjs';
import {DataTableService} from '../../../core/services/data-table.service';
import {RegisterDataTableService} from '../../../core/services/register-data-table.service';
import {TableActionDirective} from '../../../shared/components/table/table-action.directive';
import {TableCellDirective} from '../../../shared/components/table/table-cell.directive';
import {TableComponent} from '../../../shared/components/table/table.component';
import {RemarkTypeService} from '../../apiModule';
import {RegisterHeaderComponent} from './register-header/register-header.component';
import {BreakpointObserver} from '@angular/cdk/layout';
import {MatSidenavModule} from '@angular/material/sidenav';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {SideBarDoubleComponent} from '../../../shared/components/side-bar-double/side-bar-double.component';
import {RegisterDialogGroupEditComponent} from './dialogs/dialogForGroup/register-dialog.component';
import {ComponentType} from '@angular/cdk/overlay';
import {RegisterDialogSubGroupEditComponent} from './dialogs/dialogForSubgroup/register-dialog-subgroup.component';
import {RegisterDialogKindEditComponent} from './dialogs/dialogForKind/register-dialog-kind-edit.component';
import {
  RegisterDialogRemarktypeEditComponent
} from './dialogs/dialogForRemarktype/register-dialog-remarktype-edit.component';
import {
  isFacilityTableType,
  isGroupType,
  isInstitutionType,
  isKindType,
  isRemarkType,
  isSubGroupType
} from '../../apiModule/TypeGuards/entity.guards';
import {
  RegisterDialogInstitutionEditComponent
} from './dialogs/dialogForInstitution/register-dialog-institution.component';
import {RegisterDialogFacilityEditComponent} from './dialogs/dialogForFacility/register-dialog-facility.component';
import {ratePriorityDaysForFixItem,} from './dialogs/dialogForPriority/register-dialog-priority.component';
import {RemarkType, RemarkTypeTable} from '../../apiModule/model/remarktype';
import {RegisterDialogDeleteComponent} from './dialogs/dialogForDelete/register-dialog-delete.component';
import {First3Pipe} from '../../../core/pipes/first3.pipe';
import {Sort} from '@angular/material/sort';
import {FilterRemarktypeComponent} from './filters/remarktype/filter-remarktype.component';
import {FilterIndicatorsBarComponent} from './filter-indicators-bar/filter-indicators-bar.component';
import {CommunicationService} from '../../../core/services/communication.service';
import * as XLSX from 'xlsx';
import {FiltersStoreService} from '../../../core/services/filters-store.service';
import {FilterType} from '../../../core/services/filter-type';
import {AccessService} from '../../../core/services/access.service';
import {_autoFitColumns} from '../../../core/services/xls.service';
import {UserService} from '../../../core/services/user.service';

export type TableData = {
  id?: number,
  name: string,
  rateGroup?: string,
  rateSubGroup?: string,
  rateKind?: string,
}

export type ContentMenuItems = { open?: string, edit?: string, restore?: string, delete?: string }

@Component({
  selector: 'ostso-register',
  standalone: true,
  imports: [
    CommonModule,
    TableComponent,
    TableCellDirective,
    TableActionDirective,
    MatIcon,
    MatIconButton,
    MatMenu,
    MatMenuTrigger,
    RegisterHeaderComponent,
    MatSidenavModule,
    MatDatepickerModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    SideBarDoubleComponent,
    DatePipe,
    First3Pipe,
    FilterIndicatorsBarComponent,
  ],
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss',
  providers: [
    {provide: DataTableService, useClass: RegisterDataTableService}
  ]
})
export class RegisterComponent implements OnInit {
  @ViewChild(TableComponent) table: TableComponent<any> | null = null;

  titleCreate = 'Создать замечание';
  type: RegistrType = 'remarktype';
  filterComponent = FilterRemarktypeComponent;

  contentMenuItems: ContentMenuItems = {
    edit: 'Редактировать',
    delete: 'Архивировать',
    restore: 'Востановить',
  };

  public legendEnabled = false;
  public opened = true;
  mobileOpen = false;
  public searchPhrase: string = '';
  private router: Router = inject(Router);
  private readonly emptyPager: Pager = {
    length: 0,
    pageIndex: 1,
    pageSize: 10,
    previousPageIndex: 0
  };


  transformDataFn = (data: { values: any[], pager: Pager }): { values: any[], pager: Pager } => {
    return data;
  };

  search$ = new BehaviorSubject<string>('');
  sort$ = new BehaviorSubject<Sort>({direction: '', active: ''});
  filters$ = new BehaviorSubject<FilterFormData | null>(null);
  filtersFromDialog$ = new BehaviorSubject<FilterFormData | null>(null);
  pager$ = new BehaviorSubject<PageEvent>(this.emptyPager);

  searchObservable$ = this.search$.asObservable();
  sortObservable$ = this.sort$.asObservable();
  filtersObservable$ = this.filters$.asObservable();
  pagerObservable$ = this.pager$.asObservable();


  communicationService = inject(CommunicationService);
  filterStore = inject(FiltersStoreService);

  accessService = inject(AccessService);
  toastService = inject(CommunicationService);
  currentUser = inject(UserService);

  constructor(
    public readonly actRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private readonly dataTableService: DataTableService,
    private readonly destroyRef: DestroyRef,
    public dialog: MatDialog,
    private readonly remarkTypeService: RemarkTypeService,
  ) {
    this.initResolversObserver();

    this.searchObservable$.pipe(tap(v => console.log('searchObservable$', v))).subscribe(() => {
      this.pager$.next(this.emptyPager);
      this.table && this.table.setPage({
        page: 1,
        pageSize: 10,
        length: this.pager$.value.length
      });
    });
    this.pagerObservable$.pipe(tap(v => console.log('pagerObservable$', v))).subscribe();

    this.refreshData()
      .subscribe(data => {
        this.onChangeData(this.transformDataFn(data));
      });

    this.filtersFromDialog$.subscribe(v=>{
      if(!v) return;
      this.filterStore.set(this._convertTypeToFilterType(this.type), v);
    });

    this.breakpointObserver.observe([
      '(max-width: 1000px)'
    ]).subscribe(result => {
      this.opened = !result.matches;
    });
  }

  refreshData(): Observable<any> {
    return combineLatest([this.searchObservable$, this.sortObservable$, this.filtersObservable$, this.pagerObservable$])
      .pipe(tap(v => console.log('combineLatest', v)))
      .pipe(switchMap(() => {
          return this.remarkTypeService.apiRemarktypeGet(
            {
              searchString: this.search$.value,
              sortColumnName: this.sort$.value.active ? this.sort$.value.active : undefined,
              sortAscending: this.sort$.value.direction === 'asc' ? true : undefined,
              ...this.filterToQuery(this.filters$.value),
              pageIndex: this.pager$.value.pageIndex,
              pageSize: this.pager$.value.pageSize
            }
          );
        }
      ))
      .pipe(catchError(err => {
        console.error(err);
        return of(this.communicationService.publish('ошибка запроса данных', {type: 'error'}));
      }));
  }

  updateList = new BehaviorSubject<void>(undefined);

  private consvertRawDataToTable(data: RawData): TableData {
    const tableData: TableData = {
      id: data.id || undefined,
      name: data.name,
      rateGroup: data.rateGroup?.name,
      rateSubGroup: data.rateSubGroup?.name,
      rateKind: data.rateKind?.name,
    };

    return tableData;

  }

  private convertInstitutionToTable(data: any): InstitutionTableData {
    const tableData: InstitutionTableData = {
      id: data.id, iasId: data.iasId, shortName: data.shortName || data.name, ogrn: data.ogrn,
      chief: `${data.managerPosition?.toLowerCase() || ''}${data.managerPosition ? ':' : ''} ${data.managerFio?.toLowerCase().replace(/(^|\s)\S/g, (char: string) => char.toUpperCase()) || ''}`,
      contact: data.contactPhone.split(',') || '',
      status: data.status,
      email: data.email,
    };
    return tableData;
  }

  private remarktypeToTable(value: RemarkType): RemarkTypeTable {
    const ratePriorityDaysForFix: ratePriorityDaysForFixItem[] = value.ratePriorityDaysForFix || [];
    const priorityArray = ratePriorityDaysForFix.reduce((acc, e) => {
      if (!e.ratePriority) return acc;
      acc[e.ratePriority.code as keyof typeof acc] = `${e.daysFrom}-${e.daysTo}`;
      return acc;
    }, {low: '', average: '', high: '', critical: ''});

    return new RemarkTypeTable(
      value.id,
      value.name,
      value.shortName,
      value.significance,
      value.rateGroup?.name,
      value.rateSubGroup?.name,
      value.rateKind?.name,
      priorityArray.low,
      priorityArray.average,
      priorityArray.high,
      priorityArray.critical,
      value.status,
      value.notHasRemarks
    );
  }

  private onChangeData(data: { values: any[], pager: PageEvent } | null): void {
    console.log('onChangeData', data);
    let values = data && data.values && data.values.length ? data.values : [];
    const pager = data && data.pager;
    values && values.length && (values = values.map((value: any) => {
      if (isInstitutionType(value)) {
        return this.convertInstitutionToTable(value);
      }
      if (isFacilityTableType(value)) {
        return value;
      }
      if (isRemarkType(value) || isKindType(value) || isSubGroupType(value) || isGroupType(value)) {
        return this.remarktypeToTable(value);
      }
      return this.consvertRawDataToTable(value);
    }));
    values = values && values.length ? values : [];
    this.setTableData({pager, values});
  }

  initResolversObserver(): void {
    const data$ = this.actRoute.data;
    const params$ = this.actRoute.params;
    combineLatest([data$, params$])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([data, params]) => {
        this.onChangeData(data['remarks']);
        if (params['id'] === 'new') {
          this.runDialog(this.consvertRawDataToTable(data['newRemark']), true, data['type']);
        }
      });
  }

  onRowSelected(event: Event, row: any): void {
    console.log('event', event, row);
    event.stopPropagation();
    event.preventDefault();
    if(!this.accessService.list$.value['registry_open']) return;
    this.runDialog(row, false, this.type);
  }


  runDialog(row: any, isNew: boolean = false, type: RegistrType | string = this.type): void {
    const method = isNew ? 'apiRemarktypePost' : 'apiRemarktypeIdPut';

    const mapType: Record<string, ComponentType<any>> = {
      group: RegisterDialogGroupEditComponent,
      subgroup: RegisterDialogSubGroupEditComponent,
      kind: RegisterDialogKindEditComponent,
      remarktype: RegisterDialogRemarktypeEditComponent,
      institution: RegisterDialogInstitutionEditComponent,
      facility: RegisterDialogFacilityEditComponent,
    };
    const dialogComponent = mapType[type];

    const dialogRef = this.dialog.open(dialogComponent, {
      width: '690px',
      height: '100svh',
      position: {
        right: '0px'
      },
      data: {...row, isNew},
    });

    //отправка запроса на изменение данных
    dialogRef
      .afterClosed()
      .pipe(takeWhile(result => !!result))
      .pipe(switchMap((result: any, _: number) =>
        this.remarkTypeService[method]({id: result.id, apiRemarktypePostRequest: result})))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .pipe(catchError(error => of(error)))
      .subscribe(() => {
          this.onPageChange(this.pager$.value);
          isNew && this.router.navigate([window.location.pathname.replace(/[^/]+$/, '')]);
          this.communicationService.publish('Данные сохранены успешно', {type: 'success'});
        }
      );
  }

  onSearch(): void {
    this.search$.next(this.searchPhrase);
  }

  onPageChange(pager: PageEvent): void {
    this.pager$.next(pager);
  }

  setTableData(remarks: any): void {
    this.dataTableService.setData(remarks);
  }

  private _convertTypeToFilterType(type: RegistrType): FilterType {
    const mapConverter: Record<RegistrType, FilterType> = {
      group: 'group',
      subgroup: 'sub-group',
      kind: 'kind',
      remarktype: 'remark-type',
      institution: 'institution',
      facility: 'facility',
    };
    return mapConverter[type];
  }

  openFilterDialog(): void {
    const dialogRef = this.dialog.open(this.filterComponent, {
      width: '690px',
      height: '100vh',
      position: {
        right: '0px'
      },
      data: this.filtersFromDialog$.value,
    });


    //--- filter ---
    dialogRef.afterClosed().subscribe((v) => {
      if (!v) return;
      this.filtersFromDialog$.next(v);

      this.filters$.next({
        ...v,
        StatusId: v.archive || v.StatusId,
        firmIds: v?.firmIds && v?.firmIds.map((e: any) => e.id)
      });
    });

  }


  ngOnInit(): void {
    const currentFilter = this.filterStore.get(this._convertTypeToFilterType(this.type));
    if(!currentFilter) return;
    this.filtersFromDialog$.next(currentFilter);
    this.filters$.next({
      ...currentFilter,
      StatusId: currentFilter.archive || currentFilter.StatusId,
      firmIds: currentFilter?.firmIds && currentFilter?.firmIds.map((e: any) => e.id)
    });
  }


  deleteElement($event: MouseEvent, element: any): void {
    console.log(element);
    if(!this.accessService.list$.value['registry_archive']) return this.toastService.error('Недостаточно прав');
    const dialogRef = this.dialog.open(RegisterDialogDeleteComponent, {
      width: '30vw',
      data: element,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      const id = result.id;
      delete result.id;
      this.remarkTypeService.apiRemarktypeSetstatusIdPut({id, apiRatecardSetstatusIdPutRequest: result})
        .subscribe(() => {
          this.onPageChange(this.pager$.value);
        });
    });
  }

  restoreElement(element: RemarkTypeTable): void {
    if(!this.accessService.list$.value['registry_archive']) return this.toastService.error('Недостаточно прав');
    console.log(element);
    this.remarkTypeService.apiRemarktypeSetstatusIdPut({
      id: element.id!, apiRatecardSetstatusIdPutRequest: {
        ...element.status,
        statusId: 1
      }
    })
      .subscribe(() => {
        this.onPageChange(this.pager$.value);
      });
  }

  onSortedColumn($event: Sort): void {
    console.log($event);
    this.sort$.next($event);
  }


  private filterToQuery(v: FilterFormData | null): FiltersQueryType {

    const IasId = v?.IasId;
    const rateGroupIds = v?.group?.map(item => item.id);
    const rateSubGroupIds = v?.subgroup?.map(item => item.id);
    const rateKindIds = v?.kind?.map(item => item.id);
    const remarkTypeIds = v?.remarktype?.map(item => item.id);
    const Address = v?.Address;
    const DistrictIds = v?.DistrictIds;
    const RegionIds = v?.RegionIds;
    const FirmIds = v?.firmIds;
    const CadastralNumber = v?.CadastralNumber;
    const FacilityTypeIds = v?.facilityTypeIds;
    const Name = v?.Name;
    const Ogrn = v?.Ogrn;
    const isLongspanStructureObject = v?.isLongspanStructureObject;
    const isInvestmentObject = v?.isInvestmentObject;
    const isInspectionRequired = v?.isInspectionRequired;
    const ManagerFioPart = v?.ManagerFioPart;
    const StatusId = v?.StatusId;
    const significanceMin = v?.cost?.from;
    const significanceMax = v?.cost?.to;
    const notHasRemarks = v?.notHasRemarks;

    return {
      IasId,
      rateGroupIds,
      rateSubGroupIds,
      rateKindIds,
      remarkTypeIds,
      Address,
      DistrictIds,
      RegionIds,
      isLongspanStructureObject,
      isInvestmentObject,
      isInspectionRequired,
      FirmIds,
      CadastralNumber,
      FacilityTypeIds,
      Name,
      Ogrn,
      ManagerFioPart,
      StatusId,
      significanceMin,
      significanceMax,
      notHasRemarks: notHasRemarks === undefined || notHasRemarks === null ? null : notHasRemarks,
    };
  }

  public resetFilters(): void {
    this.filters$.next({
      group: [],
      rateGroupIds: [],
      subgroup: [],
      rateSubGroupIds:[],
      kind: [],
      rateKindIds: [],
      remarktype: [],
      remarkTypeIds: [],
      Address: '',
      DistrictIds: [],
      RegionIds: [],
      isLongspanStructureObject: null,
      firmIds: [],
      CadastralNumber: '',
      facilityTypeIds: [],
      Name: '',
      Ogrn: '',
      isInvestmentObject: null,
      isInspectionRequired: null,
      ManagerFioPart: '',
      cost: {
        from: 0,
        to: 100
      },

    });
    this.filtersFromDialog$.next({} as FilterFormData);
  }

  refresh(): void {
    window.location.reload();
  }

  excel(): void {
    const prevPager = this.pager$.value;
    this.refreshData()
      .pipe(first())
      .subscribe(v => {
        if (v.pager?.count !== 1e6) return;
        setTimeout(async () => {
          const worksheet = XLSX.utils.table_to_sheet(this.table!.tableRef.nativeElement, {raw: true});

          // @ts-expect-error: тредование XLXS
          const range = XLSX.utils.decode_range(worksheet['!ref']);
          console.log('worksheet[\'A1\'].', worksheet['A1']);

          const lastCol = range.e.c;

// Пройтись по всем строкам и удалить ячейки последнего столбца
          for (let R = range.s.r; R <= range.e.r; R++) {
            const cellAddress = XLSX.utils.encode_cell({r: R, c: lastCol});
            delete worksheet[cellAddress];
          }

// Обновить диапазон листа, уменьшив количество столбцов на 1
          range.e.c = lastCol - 1;
          worksheet['!ref'] = XLSX.utils.encode_range(range);

          worksheet['!cols'] = _autoFitColumns(worksheet);

          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
          this.actRoute.title.subscribe(title => {
            XLSX.writeFile(workbook, title + '.xlsx');
            this.onPageChange(prevPager);
          });
        });
      });
    this.onPageChange({
      pageSize: 1e6,
      pageIndex: 1,
      length: this.table!.recordCount
    });
  }

  protected readonly Array = Array;

  onClickOutside(): void {
    if(!this.mobileOpen) return;
    this.mobileOpen = false;
  }

  changeInspectionRequired($event: MouseEvent, element: any):void {
    const target = $event.currentTarget as HTMLElement;
    const input = target.querySelector('input');
    const value = input?.checked;

    const id = element.id as number;

    this.remarkTypeService.apiFacilityIdPatch({id,apiFacilityIdPatchRequest:{isInspectionRequired: !!value as boolean}})
      .subscribe({
        next(){},
        error(){
          input && (input.checked = !value);
        }
      });
  }
}
