import {inject, Injectable} from '@angular/core';
import {ITableColumn} from '../../../core/common/interfaces/table-column.interface';
import {StorageService} from '../../../core/services/storage.service';
import {RegisterDataTableService} from '../../../core/services/register-data-table.service';
import {RateGroupService} from '../../apiModule';
import {map} from 'rxjs/operators';
import {GroupEstimate} from '../../apiModule/model/groupEstimate';


@Injectable({
  providedIn: 'root'
})
export class RatingOrgsDataTableService extends RegisterDataTableService {
  groupService = inject(RateGroupService);

  private _columns: ITableColumn[] = [
    {
      name: 'number',
      title: '№',
      useCustomTemplate: true,
      default: true,
      isRequired: true,
    },
    {
      name: 'firm',
      title: 'Организация',
      useCustomTemplate: true,
      default: true,
      isRequired: true
    },
    {
      name: 'overallRating',
      title: 'Общий рейтинг',
      useCustomTemplate: true,
      default: true,
      isRequired: true
    },

  ];

  constructor(
    storageService: StorageService
  ) {
    super(storageService);
    this.groupService.apiRategroupGet()
      .pipe(map((res: any) => res.values))
      .subscribe((res: GroupEstimate[]) => {
        const columns: ITableColumn[] = res.map((group: GroupEstimate) => ({
          name: 'group' + group.id,
          title: group.name,
          useCustomTemplate: false,
          default: false,
          innerDiv: 'group-column'
        }));
        this.addColumns(...columns);
        this.isLoaded.set(true);
      });
  }


  override getColumns(): ITableColumn[] {
    return this._columns;
  }

  addColumns(...columns: ITableColumn[]): void {
    this._columns.push(...columns);
  }


}
