import {AfterContentInit, Component, ContentChild, forwardRef, Input, TemplateRef} from '@angular/core';
import {AsyncPipe, NgClass, NgIf, NgStyle, NgTemplateOutlet} from '@angular/common';
import {
  CdkFixedSizeVirtualScroll,
  CdkVirtualForOf,
  CdkVirtualScrollViewport,
  ScrollingModule
} from '@angular/cdk/scrolling';
import {MatAutocomplete, MatAutocompleteTrigger, MatOption} from '@angular/material/autocomplete';
import {MatFormField, MatLabel, MatPrefix, MatSuffix} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {MatInput} from '@angular/material/input';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule} from '@angular/forms';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Component({
  selector: 'ostso-autocomplete',
  standalone: true,
  imports: [
    AsyncPipe,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    CdkVirtualScrollViewport,
    MatAutocomplete,
    MatAutocompleteTrigger,
    MatFormField,
    MatIcon,
    MatIconButton,
    MatInput,
    MatLabel,
    MatOption,
    MatPrefix,
    MatSuffix,
    NgIf,
    ReactiveFormsModule,
    NgStyle,
    ScrollingModule,
    NgClass,
    NgTemplateOutlet,
  ],
  templateUrl: './autocomplete.component.html',
  styleUrl: './autocomplete.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true
    }
  ]
})
export class AutocompleteComponent<T extends {
  id: number,
  name: string
}> implements ControlValueAccessor, AfterContentInit {
  _value: T | undefined;
  _data: T[] = [];
  isDisabled: boolean = false;

  @ContentChild(TemplateRef) contentTemplate: TemplateRef<any> | null = null;

  get value(): T | undefined {
    return this._value;
  }

  @Input() field:string = 'name';

  @Input() set value(value: T | undefined) {
    if (this._value?.name === value?.name) return;
    this._value = value;
    this.control.setValue(value?.name || '');
    this.onChange(value);
  }

  @Input({}) set data(value: T[] | null) {
    this._data = value || [];
    const res = this._data.find(v => v.name === this.control.value);
    this.control.setValue(res?.name || '');
    this.value = res;
  }

  @Input() label: string = '';

  onChange = (value: T | undefined): void => {
  };
  onTouched = (): void => {
  };

  control: FormControl = new FormControl();

  writeValue(value: T): void {
    this.value = value;
    this.control.setValue(value?.name || '');
  }

  registerOnChange(fn: (value: unknown) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  includesValue(obj: Record<string, any>, value: string): boolean {
    if (value === null) value = '';
    for (const key in obj) {
      if (obj[key] === null) return false;
      if (typeof obj[key] === 'object') {
        if (this.includesValue(obj[key], value)) {
          return true;
        }
      }
      if (obj[key].toString().toLowerCase().includes(value.toLowerCase())) {
        return true;
      }
    }
    return false;
  }

  private _filter(value: string, data: T[]): T[] {
    if(!value) return data;
    const words = value.split(' ');
    const res = data.filter(e => words.every(word => JSON.stringify(e).toLowerCase().includes(word.toLowerCase())));
    return res;
  }

  public filteredData!: Observable<T[]>;


  ngAfterContentInit(): void {
    this.control.setValue(this.value);
    this.filteredData = this.control.valueChanges.pipe(
      map((value: string) => {
        return this._filter(value, this._data);
      }),
    );
    this.control.valueChanges
      .pipe(map((value: string) => {
        const res = this._data.find(v => v[this.field as keyof T] === value);
        return res;
      }))
      .subscribe((value) => {
        if (this._value !== value) this.onChange(value);
        this._value = value;
      });
  }

}
