import {AfterViewInit, Component, DestroyRef, effect, ElementRef, OnInit, signal, ViewChild} from '@angular/core';
import {provideNativeDateAdapter} from '@angular/material/core';
import {CommonModule} from '@angular/common';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BehaviorSubject, catchError, combineLatest, Observable, of, switchMap, tap} from 'rxjs';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialog} from '@angular/material/dialog';
import {SideBarDoubleComponent} from '../../../shared/components/side-bar-double/side-bar-double.component';
import {TableCellDirective} from '../../../shared/components/table/table-cell.directive';
import {TableComponent} from '../../../shared/components/table/table.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ActivatedRoute, RouterLink} from '@angular/router';
import {DataTableService} from '../../../core/services/data-table.service';
import {Chart, registerables} from 'chart.js';
import {RegisterHeaderComponent} from '../register/register-header/register-header.component';
import {Sort} from '@angular/material/sort';
import {RatingFacilitiesDataTableService} from './rating-facilities-data-table.service';
import {ApiFacilityRateGetRequestParams, FacilityRateService} from '../../apiModule/api/facilityRate.service';
import {CommunicationService} from '../../../core/services/communication.service';
import {colorizeByDiapason, GroupRating, sortType} from './org-rating.component';
import {FacilityFilterComponent} from './facility-filter/facility-filter.component';
import {FiltersStoreService} from '../../../core/services/filters-store.service';
import {map} from 'rxjs/operators';
import {FilterIndicatorsBarComponent} from '../register/filter-indicators-bar/filter-indicators-bar.component';
import {ZoneRatingService} from '../../apiModule/api/zone-rating.service';
import {RenderLastRowService} from './render-last-row.service';
import {ExcelService} from '../../../core/services/excel.service';
import {ToPdfDirective} from '../../../core/directives/to-pdf.directive';

Chart.register(...registerables);

interface TableData {
  name: string | null,
  address: string | null,
  balanceHolder: any,
  rating: number | null,
}


@Component({
  selector: 'ostso-facilities-rating',
  standalone: true,
  providers: [
    provideNativeDateAdapter(),
    {provide: DataTableService, useClass: RatingFacilitiesDataTableService},
    RenderLastRowService
  ],
  imports: [
    MatSelectModule,
    MatSidenavModule,
    MatIconButton,
    MatIcon,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    CommonModule,
    MatDatepickerModule,
    SideBarDoubleComponent,
    TableCellDirective,
    TableComponent,
    RegisterHeaderComponent,
    RouterLink,
    FilterIndicatorsBarComponent,
    ToPdfDirective,
  ],
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss', '../../../shared/components/table/table.component.scss', '../register/register.component.scss'],
})

export class RatingComponent implements OnInit, AfterViewInit {

  @ViewChild('table', {static: false, read: ElementRef}) table?: ElementRef<HTMLTableElement>;
  @ViewChild('table', {read: TableComponent}) tableComponent?: TableComponent<TableData>;
  @ViewChild(ToPdfDirective) toPdf?: ToPdfDirective;


  public opened = true;
  mobileOpen = false;
  isPdfReport = false;

  updateList = new BehaviorSubject<void>(undefined);

  isRateDataLoaded = signal(false);
  isRateDataEmpty = signal(true);
  snapshoting = signal(false);


  transformDataFn = (data: { values: any[], pager: Pager }): { values: any[], pager: Pager } => {
    return data;
  };

  search$ = new BehaviorSubject<string>('');
  sort$ = new BehaviorSubject<Sort>({direction: '', active: ''});
  filters$ = new BehaviorSubject<FilterFormData | null>(this.filterStore.get('facility-rating') || {} as FilterFormData
  );

  searchObservable$ = this.search$.asObservable();
  sortObservable$ = this.sort$.asObservable();
  filtersObservable$ = this.filters$.asObservable();

  zoneRating?: { min: number, max: number, color: string }[];
  isLastRowRendering = false;


  constructor(
    private route: ActivatedRoute,
    private destroyRef: DestroyRef,
    public dataTableService: DataTableService,
    private dataService: FacilityRateService,
    private dialog: MatDialog,
    private communicationService: CommunicationService,
    private filterStore: FiltersStoreService,
    private zoneRatingService: ZoneRatingService,
    private renderLastRowService: RenderLastRowService<FacilityStat>,
    private xlsService: ExcelService
  ) {
    this.filters$.subscribe(filters => this.filterStore.set('facility-rating', filters || ({} as FilterFormData)));
  }

  renderLastRow(): void{
    this.isLastRowRendering = false;
    this.tableComponent?.changedSelectedColumn();
    this.table && this.renderLastRowService.init('facility', this.table, this.filters$.value).subscribe((v: boolean) => {
      v && this.zoneRating && colorizeByDiapason('tr > td:nth-child(n+4)', this.zoneRating.reverse());
      v && this.zoneRating && colorizeByDiapason('tr.overall-row > td:nth-child(n+1)', this.zoneRating.reverse());
      this.isLastRowRendering = true;
    });
  }

  ngAfterViewInit(): void {
    // setTimeout(() => {
    //   const done = this.renderOverallRow();
    //   if (!done) return;
    //   done.subscribe((v: boolean) => {
    //     v && this.zoneRating && colorizeByDiapason('tr.overall-row > td:nth-child(n+1)', this.zoneRating.reverse());
    //     v && this.zoneRating && colorizeByDiapason('tr > td:nth-child(n+4)', this.zoneRating.reverse());
    //   });
    // }, 1000);
    setTimeout(()=>this.renderLastRow() , 500);
  }



  parseGroupsData(data: (TableData & { groupRating: GroupRating })[]): (TableData & { [key: string]: number })[] {
    if (!data.length) return [];
    return data.map(el => {
      const groupRating: GroupRating = el.groupRating;
      if (!groupRating) return;
      const groups = Object.entries(groupRating).map(([_, value]) => [
        'group' + value.id, value.rating + '%'
      ]);
      return {...el, ...Object.fromEntries(groups)};
    }).filter(Boolean);
  }


  onChangeData(v: any): void {
    if (!v) {
      this.dataTableService.setData({pager: {}, values: []});
      return;
    }
    const pager = v && v['pager'];
    const values = v['values'] && (v['values'] as TableData[]).map(el => this.fromDataToTable(el)) || [];
    const valuesWithGroups = this.parseGroupsData(values);
    console.log('this.dataTableService.getColumns', this.dataTableService.getColumns());
    this.dataTableService.setData({pager, values: valuesWithGroups});
    this.isRateDataLoaded.set(true);
    this.isRateDataEmpty.set(!valuesWithGroups.length);

  }

  onClickOutside(): void {
    if (!this.mobileOpen) return;
    this.mobileOpen = false;
  }

  fromDataToTable(value: Partial<TableData>): Partial<TableData> {
    return {
      ...value,
      rating: value.rating,
      name: value.name,
      address: value.address,
      balanceHolder: value.balanceHolder?.shortName,
    };
  }


  setTableData(remarks: any): void {
    this.dataTableService.setData(remarks);
  }

  onSortedColumn({active, direction}: { active: string, direction: sortType }): void {
    console.log(active, direction);
    this.sort$.next({active, direction});
  }

  refreshData(): Observable<any> {
    return combineLatest([this.searchObservable$, this.sortObservable$, this.filtersObservable$])
      .pipe(tap(v => console.log('combineLatest', v)))
      .pipe(switchMap(() => {
          console.log('filters ', this.filters$.value);
          const payload: ApiFacilityRateGetRequestParams = {
            ...this.filters$.value,
            searchString: this.search$.value,
            sortColumnName: this.sort$.value.active ? this.sort$.value.active : undefined,
            sortAscending: this.sort$.value.direction === 'asc' ? true : undefined,
            pageIndex: 0,
            pageSize: 1e4
          };
          console.log('payload', payload);
          return this.dataService.apiFacilityRateGet(payload);
        }
      ))
      .pipe(catchError(err => {
        console.error(err);
        return of(this.communicationService.publish('Ошибка запроса данных', {type: 'error'}));
      }))
      .pipe(takeUntilDestroyed(this.destroyRef));
  }

  ngOnInit(): void {
    this.route.data.subscribe((v: any) => {
      const res = v['data'] ? v['data'] : v;
      this.onChangeData(res);
    });
    this.refreshData().subscribe(v => {
      this.onChangeData(v);
    });
    console.log(this.route.data);
    this.zoneRatingService.apiZoneRatingGet()
      .pipe(map(v => v.values))
      .subscribe(v => {
        this.zoneRating = v;
      });
    this.filters$.subscribe(()=>this.renderLastRow());
  }


  toggleClass(): void {
    this.isPdfReport = !this.isPdfReport;
  }

  openFilterDialog(): void {
    const dialogRef = this.dialog.open(FacilityFilterComponent, {
      width: '690px',
      height: '100vh',
      position: {
        right: '0px'
      },
      data: this.filters$.value,
    });

    //включение фильтрации
    dialogRef.afterClosed().subscribe((v) => {
      if (!v) return;
      this.filters$.next(v);

    });
  }

  onResetFilter(): void {
    this.filters$.next({} as FilterFormData);
  }

  onChangeColumns($event: string[]): void {
    this.table && this.renderLastRowService.init('facility', this.table, this.filters$.value);
  }

  async onExportPdf(): Promise<void> {
    if (!this.toPdf) return;
    await this.toPdf.snapshot(() => {
      // this.charts.forEach(c => c.refresh())
    },'general-report');
  }

  onSnapshot(event: boolean): void {
    this.snapshoting.set(event);
  }

  effectSnapshot = effect(()=> {
    console.log(this.snapshoting());
    this.renderLastRow();
  });

  onExportExcel(): void {
    this.table?.nativeElement && this.xlsService.export(this.table.nativeElement, 'Объекты Москомспорта');
  }
}


