<div class="ostso-filter-indicators">
  @if (filter) {
    @if (filter.group && !!filter.group.length) {
      <ostso-filter-indicator [count]="filter.group.length" label="Группа"/>
    }
    @if (filter.rateGroupIds && !!filter.rateGroupIds.length) {
      <ostso-filter-indicator [count]="filter.rateGroupIds.length" label="Группа"/>
    }
    @if (filter.subgroup && !!filter.subgroup.length) {
      <ostso-filter-indicator [count]="filter.subgroup.length" label="Подгруппа"/>
    }
    @if (filter.rateSubGroupIds && !!filter.rateSubGroupIds.length) {
      <ostso-filter-indicator [count]="filter.rateSubGroupIds.length" label="Подгруппа"/>
    }
    @if (!!filter.kind && !!filter.kind.length) {
      <ostso-filter-indicator [count]="filter.kind.length" label="Вид"/>
    }
    @if (!!filter.rateKindIds && !!filter.rateKindIds.length) {
      <ostso-filter-indicator [count]="filter.rateKindIds.length" label="Вид"/>
    }
    @if (filter.remarktype && !!filter.remarktype.length) {
      <ostso-filter-indicator [count]="filter.remarktype.length" label="Замечания"/>
    }
    @if (filter.remarkTypeIds && !!filter.remarkTypeIds.length) {
      <ostso-filter-indicator [count]="filter.remarkTypeIds.length" label="Замечания"/>
    }
    @if (filter.cost && filter.cost.from && filter.cost.from > 0 || filter.cost && filter.cost.to && filter.cost.to < 100) {
      <ostso-filter-indicator label="Стоимость"/>
    }
    @if (filter.IasId) {
      <ostso-filter-indicator label="ID ИАС Спорт"/>
    }
    @if (filter.Name) {
      <ostso-filter-indicator label="Наименование"/>
    }
    @if (filter.ManagerFioPart) {
      <ostso-filter-indicator label="Руководитель"/>
    }
    @if (filter.Ogrn) {
      <ostso-filter-indicator label="ОГРН"/>
    }
    @if (filter.StatusId && !!(+filter.StatusId)) {
      <ostso-filter-indicator label="Архив"/>
    }
    @if (filter.statusId && !!(+filter.statusId)) {
      <ostso-filter-indicator label="Архив"/>
    }
    @if (filter.archiveId && !!(+filter.archiveId)) {
      <ostso-filter-indicator label="Архив"/>
    }
    @if (filter.firmIds && filter.firmIds.length) {
      <ostso-filter-indicator [count]="filter.firmIds.length" label="Учреждение"/>
    }
    @if (filter.Address) {
      <ostso-filter-indicator label="Адрес"/>
    }
    @if (filter.DistrictIds && filter.DistrictIds.length) {
      <ostso-filter-indicator [count]="filter.DistrictIds.length" label="Округ"/>
    }
    @if (filter.RegionIds && filter.RegionIds.length) {
      <ostso-filter-indicator [count]="filter.RegionIds.length" label="Регион"/>
    }
    @if (filter.CadastralNumber) {
      <ostso-filter-indicator label="Кадастр"/>
    }
    @if (filter.isInvestmentObject !== undefined && filter.isInvestmentObject !== null) {
      <ostso-filter-indicator label="Объект ивестстроительства"/>
    }
    @if (filter.isLongspanStructureObject !== undefined && filter.isLongspanStructureObject !== null) {
      <ostso-filter-indicator label="Объект БПК"/>
    }
    @if (filter.isInspectionRequired !== undefined && filter.isInspectionRequired !== null) {
      <ostso-filter-indicator label="Подлежит проверке"/>
    }
    @if (filter.facilityIds && filter.facilityIds.length) {
      <ostso-filter-indicator [count]="filter.facilityIds.length" label="Объект"/>
    }
    @if (filter.facilityTypeIds && filter.facilityTypeIds.length) {
      <ostso-filter-indicator [count]="filter.facilityTypeIds.length" label="Тип объекта"/>
    }
    @if (filter && filter.priorityIds && filter.priorityIds.length > 0) {
      <ostso-filter-indicator [count]="filter.priorityIds.length" label="Приоритет"/>
    }
    @if (filter && filter.facilityAddress) {
      <ostso-filter-indicator [count]="1" label="Адрес объекта"/>
    }
    @if (filter && filter.ids && filter.ids.length) {
      <ostso-filter-indicator [count]="filter.ids.length" label="ID"/>
    }
    @if (filter && filter.statusesIds && filter.statusesIds.length) {
      <ostso-filter-indicator [count]="filter.statusesIds.length" label="Статус"/>
    }
    @if (filter && filter.daysInWorkFrom) {
      <ostso-filter-indicator label="Дней в работе от"/>
    }
    @if (filter && filter.daysInWorkTo) {
      <ostso-filter-indicator label="Дней в работе до"/>
    }
    @if (filter && filter['dateTo']) {
      <ostso-filter-indicator label="Дата проведения оценки до"/>
    }
    @if (filter && filter['dateFrom']) {
      <ostso-filter-indicator label="Дата проведения оценки от"/>
    }
    @if (filter && filter.OperatorIds && filter.OperatorIds.length) {
      <ostso-filter-indicator [count]="filter.OperatorIds.length" label="Оператор"/>
    }
    @if (filter && filter.InspectorIds && filter.InspectorIds.length) {
      <ostso-filter-indicator [count]="filter.InspectorIds.length" label="Инспектор"/>
    }
    @if (filter && filter.createAt) {
      <ostso-filter-indicator label="Дата проведения оценки от"/>
    }
    @if (filter && filter.finishedDate) {
      <ostso-filter-indicator label="Дата проведения оценки до"/>
    }

  }
</div>
