export interface FilterField {
  control: string;
  data: string;
  placeholder: string;
  ext?: boolean
}
export const Fields: FilterField[]= [
  {control: 'statusesIds',data: 'statuses', placeholder: 'Статус'},
  {control: 'firmIds',data: 'firms', placeholder: 'Учреждение'},
  {control: 'facilityTypeIds',data: 'facilityTypes', placeholder: 'Тип объекта'},
  {control: 'facilityIds',data: 'facilities', placeholder: 'Объект'},
  {control: 'facilityAddress',data: 'addresses', placeholder: 'Адрес объекта'},
  {control: 'rateGroupIds',data: 'groups', placeholder: 'Группа замечаний', ext: true},
  {control: 'rateSubGroupIds',data: 'subGroups', placeholder: 'Подгруппа замечаний', ext: true},
  {control: 'rateKindIds',data: 'kinds', placeholder: 'Виды замечаний', ext: true},
  {control: 'remarkTypeIds',data: 'remarkTypes', placeholder: 'Замечания'},
  {control: 'OperatorIds',data: 'operators', placeholder: 'Оператор'},
  {control: 'InspectorIds',data: 'inspectors', placeholder: 'Инспектор'},
  {control: 'priorityIds',data: 'priority', placeholder: 'Приоритет'},

];

