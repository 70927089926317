import {ResolveFn} from '@angular/router';
import {inject} from '@angular/core';
import {FacilityRateService} from '../../../apiModule/api/facilityRate.service';
import { HttpContext } from '@angular/common/http';

export const facilityRateResolver: ResolveFn<any> = (
  route,
  state,
  facilityRateService: FacilityRateService = inject(FacilityRateService)
) => {
  return facilityRateService.apiFacilityRateGet({pageSize: 1e4}, 'body', false, {
    context: new HttpContext()
});
};


