<nav>
  <a routerLink="/" class="main-logo">
    <svg width="64" height="73" viewBox="0 0 64 73" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M34.4666 54.8763C35.7265 56.402 37.0852 57.8593 38.5273 59.2331C45.275 65.6468 53.9202 70.254 63.5673 72.2123V66.4514C55.5521 64.5918 48.3718 60.6525 42.7019 55.2634C40.8423 53.4949 39.1421 51.567 37.6317 49.5101L34.4666 54.8763Z"
            fill="#E22B36"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M13.5431 48.8797C19.8581 42.8759 23.7822 34.5874 23.7822 25.4792V0.256989H29.6874V25.4792C29.6874 29.9118 30.4312 35.1111 31.7823 39.1415C33.1409 35.1111 33.8772 29.9118 33.8772 25.4792V0.256989H39.7823V25.4792C39.7823 34.5874 43.7065 42.8683 50.0215 48.8797C53.7938 52.4622 58.4086 55.2327 63.5624 56.8873V62.7697C56.7843 60.9405 50.7046 57.4718 45.8469 52.8493C42.196 49.373 39.2283 45.2516 37.1637 40.6823C35.6229 44.6444 33.9227 48.3939 31.7975 51.2478L31.7823 51.2706L31.7671 51.2478C29.6419 48.3939 27.9417 44.6368 26.4008 40.6823C24.3363 45.2516 21.3686 49.3806 17.7177 52.8493C12.86 57.4718 6.78023 60.9405 0.00219727 62.7697V56.8873C5.14834 55.2327 9.77076 52.4622 13.5431 48.8797Z"
            fill="#E22B36"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M49.2286 24.955C49.2286 31.5888 52.0826 37.6306 56.6898 42.0025C58.686 43.9001 61.001 45.4788 63.5589 46.6705V52.7882C59.3767 51.2777 55.6196 48.9324 52.5152 45.9798C46.8453 40.5907 43.3235 33.1448 43.3235 24.955V19.2699H49.2286V24.955Z"
            fill="#E22B36"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M14.3303 19.2699H20.2354V24.955C20.2354 33.1448 16.7136 40.5832 11.0437 45.9798C7.93174 48.9399 4.1746 51.2777 0 52.7882V46.6705C2.55789 45.4788 4.8729 43.9001 6.86911 42.0025C11.4688 37.623 14.3303 31.5888 14.3303 24.955V19.2699Z"
            fill="#E22B36"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M25.9356 49.5101C24.4252 51.567 22.7326 53.4949 20.8654 55.2634C15.1955 60.6449 8.01523 64.5918 0 66.4514V72.2123C9.64712 70.254 18.2923 65.6468 25.04 59.2331C26.4821 57.8593 27.8408 56.4095 29.1008 54.8763L25.9356 49.5101Z"
            fill="#E22B36"/>
    </svg>
    <h1>Сервис<br> контрольных<br> мероприятий</h1>
  </a>

  <!--  <a routerLink="/logout" class="username">-->
  <!--    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
  <!--      <mask id="mask0_0_176" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">-->
  <!--        <rect width="24" height="24" fill="#D9D9D9"/>-->
  <!--      </mask>-->
  <!--      <g mask="url(#mask0_0_176)">-->
  <!--        <path-->
  <!--          d="M20.15 13H8V11H20.15L18.6 9.45L20 8L24 12L20 16L18.6 14.55L20.15 13ZM15 9V5H5V19H15V15H17V19C17 19.55 16.8042 20.0208 16.4125 20.4125C16.0208 20.8042 15.55 21 15 21H5C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H15C15.55 3 16.0208 3.19583 16.4125 3.5875C16.8042 3.97917 17 4.45 17 5V9H15Z"-->
  <!--          fill="#1C1B1F"/>-->
  <!--      </g>-->
  <!--    </svg>-->
  <!--    Иванов Иван Иванович-->
  <!--  </a>-->
  <div [ngClass]="['flex-component', opened ? 'flex-component__opened' : 'flex-component__closed']">
    <button mat-icon-button (click)="toggleEmit()">
      <mat-icon>menu</mat-icon>
    </button>
    <span class="username">
    @if (opened) {
      @if (!user.isEmpty) {
        <span (click)="openAccount(user.value)">{{ user.value.lastName ? user.value.lastName : '' }} </span>
        <span (click)="openAccount(user.value)">{{ user.value.firstName ? user.value.firstName.slice(0, 1).toUpperCase() + '.' : '' }} </span>
        <span (click)="openAccount(user.value)">{{ user.value.middleName ? user.value.middleName.slice(0, 1).toUpperCase() + '.' : '' }}</span>
      } @else {
        <mat-progress-spinner mode="indeterminate" class="loader" diameter="24"/>
      }
    }
    </span>
    <a routerLink="/logout">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_0_176" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
          <rect width="24" height="24" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_0_176)">
          <path
            d="M20.15 13H8V11H20.15L18.6 9.45L20 8L24 12L20 16L18.6 14.55L20.15 13ZM15 9V5H5V19H15V15H17V19C17 19.55 16.8042 20.0208 16.4125 20.4125C16.0208 20.8042 15.55 21 15 21H5C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H15C15.55 3 16.0208 3.19583 16.4125 3.5875C16.8042 3.97917 17 4.45 17 5V9H15Z"
            fill="#1C1B1F"/>
        </g>
      </svg>
    </a>
  </div>

  @if (titleCreate && isNewEnabled && !isGrade) {
    <a class="btn btn-red" routerLink="new"><span>{{ titleCreate }}</span>
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"
           [matTooltip]="titleCreate" matTooltipPosition="right">
        <mask id="mask0_73_1701" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
          <rect x="0.462891" width="24" height="24" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_73_1701)">
          <path
            d="M2.46289 24V20H22.4629V24H2.46289ZM6.46289 16H7.86289L15.6629 8.225L14.2379 6.8L6.46289 14.6V16ZM4.46289 18V13.75L15.6629 2.575C15.8462 2.39167 16.0587 2.25 16.3004 2.15C16.5421 2.05 16.7962 2 17.0629 2C17.3296 2 17.5879 2.05 17.8379 2.15C18.0879 2.25 18.3129 2.4 18.5129 2.6L19.8879 4C20.0879 4.18333 20.2337 4.4 20.3254 4.65C20.4171 4.9 20.4629 5.15833 20.4629 5.425C20.4629 5.675 20.4171 5.92083 20.3254 6.1625C20.2337 6.40417 20.0879 6.625 19.8879 6.825L8.71289 18H4.46289Z"
            fill="#1C1B1F"/>
        </g>
      </svg>
    </a>
  }

  <a class="btn btn-grey register" routerLink="/ratecard"><span>Реестр оценок</span>
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"
         matTooltip="Реестр оценок" matTooltipPosition="right">
      <mask id="mask0_69_1677" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
        <rect x="0.462891" width="24" height="24" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_69_1677)">
        <path
          d="M12.463 22.025C12.1963 22.025 11.9422 21.975 11.7005 21.875C11.4588 21.775 11.238 21.6333 11.038 21.45L3.01299 13.425C2.82965 13.225 2.68799 13.0042 2.58799 12.7625C2.48799 12.5208 2.43799 12.2667 2.43799 12C2.43799 11.7333 2.48799 11.475 2.58799 11.225C2.68799 10.975 2.82965 10.7583 3.01299 10.575L11.038 2.55001C11.238 2.35001 11.4588 2.20417 11.7005 2.11251C11.9422 2.02084 12.1963 1.97501 12.463 1.97501C12.7297 1.97501 12.988 2.02084 13.238 2.11251C13.488 2.20417 13.7047 2.35001 13.888 2.55001L21.913 10.575C22.113 10.7583 22.2588 10.975 22.3505 11.225C22.4422 11.475 22.488 11.7333 22.488 12C22.488 12.2667 22.4422 12.5208 22.3505 12.7625C22.2588 13.0042 22.113 13.225 21.913 13.425L13.888 21.45C13.7047 21.6333 13.488 21.775 13.238 21.875C12.988 21.975 12.7297 22.025 12.463 22.025ZM12.463 20.025L20.488 12L12.463 3.97501L4.43799 12L12.463 20.025ZM11.463 13H13.463V7.00001H11.463V13ZM12.463 16C12.7463 16 12.9838 15.9042 13.1755 15.7125C13.3672 15.5208 13.463 15.2833 13.463 15C13.463 14.7167 13.3672 14.4792 13.1755 14.2875C12.9838 14.0958 12.7463 14 12.463 14C12.1797 14 11.9422 14.0958 11.7505 14.2875C11.5588 14.4792 11.463 14.7167 11.463 15C11.463 15.2833 11.5588 15.5208 11.7505 15.7125C11.9422 15.9042 12.1797 16 12.463 16Z"
          fill="#E22B36"/>
      </g>
    </svg>
  </a>


  <h2>Справочники</h2>

  <ul>
    <li>
      <a [routerLink]="['/institutions']">
        <svg width="35" height="25" viewBox="0 0 35 25" fill="none" xmlns="http://www.w3.org/2000/svg"
             matTooltip="Учреждения Москомспорта" matTooltipPosition="right">
          <g clip-path="url(#clip0_0_188)">
            <path
              d="M22.3421 22.5H23.5526V19.375H26.5789V18.125H23.5526V15H22.3421V18.125H19.3158V19.375H22.3421V22.5ZM22.9474 25C21.2728 25 19.8454 24.3906 18.6651 23.1719C17.4849 21.9531 16.8947 20.4792 16.8947 18.75C16.8947 17.0208 17.4849 15.5469 18.6651 14.3281C19.8454 13.1094 21.2728 12.5 22.9474 12.5C24.6219 12.5 26.0493 13.1094 27.2296 14.3281C28.4099 15.5469 29 17.0208 29 18.75C29 20.4792 28.4099 21.9531 27.2296 23.1719C26.0493 24.3906 24.6219 25 22.9474 25ZM6 22.5V7.5L15.6842 0L25.3684 7.5V10.375C24.9851 10.25 24.5917 10.1562 24.1882 10.0938C23.7847 10.0312 23.3711 10 22.9474 10V8.75L15.6842 3.125L8.42105 8.75V20H14.5645C14.625 20.4375 14.7158 20.8646 14.8368 21.2812C14.9579 21.6979 15.1092 22.1042 15.2908 22.5H6Z"
              fill="#272B37"/>
          </g>
          <defs>
            <clipPath id="clip0_0_188">
              <rect width="34.9254" height="24.9467" fill="white"/>
            </clipPath>
          </defs>
        </svg>
        <span>Учреждения Москомспорта</span></a>
    </li>
    <li>
      <a [routerLink]="['/facility']">
        <svg width="35" height="26" viewBox="0 0 35 26" fill="none" xmlns="http://www.w3.org/2000/svg"
             matTooltip="Объекты учреждений Москомспорта" matTooltipPosition="right">
          <g clip-path="url(#clip0_0_192)">
            <path
              d="M3 25.9467V0.946693H17V6.50225H31V25.9467H3ZM5.8 23.1689H8.6V20.3911H5.8V23.1689ZM5.8 17.6134H8.6V14.8356H5.8V17.6134ZM5.8 12.0578H8.6V9.28003H5.8V12.0578ZM5.8 6.50225H8.6V3.72447H5.8V6.50225ZM11.4 23.1689H14.2V20.3911H11.4V23.1689ZM11.4 17.6134H14.2V14.8356H11.4V17.6134ZM11.4 12.0578H14.2V9.28003H11.4V12.0578ZM11.4 6.50225H14.2V3.72447H11.4V6.50225ZM17 23.1689H28.2V9.28003H17V12.0578H19.8V14.8356H17V17.6134H19.8V20.3911H17V23.1689ZM22.6 14.8356V12.0578H25.4V14.8356H22.6ZM22.6 20.3911V17.6134H25.4V20.3911H22.6Z"
              fill="#272B37"/>
          </g>
          <defs>
            <clipPath id="clip0_0_192">
              <rect width="34.9254" height="24.9467" fill="white" transform="translate(0 0.946693)"/>
            </clipPath>
          </defs>
        </svg>
        <span>Объекты учреждений Москомспорта</span></a>
    </li>
    @for (i of typeOfEstimation.list; track i.name) {
      <li>
        <a [routerLink]="['/',i.href]">
          <div [ngClass]="i.icon" [matTooltip]="i.name | capitalize" matTooltipPosition="right"></div>
          <span>{{ i.name | capitalize }}</span></a>
      </li>
    }
  </ul>

  <h2>Аналитика и отчетность</h2>

  <ul>

  <li>
    <a [routerLink]="['/org-rating']">
      <svg width="35" height="26" viewBox="0 0 35 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1112_1102)">
        <path d="M23.4 24.4941V15.0481H29V24.4941H23.4ZM14.2 24.4941V0.494141H19.8V24.4941H14.2ZM5 24.4941V8.34054H10.6V24.4941H5Z" fill="#1C1B1F"/>
        </g>
        <defs>
        <clipPath id="clip0_1112_1102">
        <rect width="34.9254" height="24.9467" fill="white" transform="translate(0 0.333984)"/>
        </clipPath>
        </defs>
      </svg>

      <span>Рейтинг </span></a>
  </li>

  <li>
    <a [routerLink]="['/dashboard']">
      <svg width="35" height="26" viewBox="0 0 35 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1112_1106)">
        <path d="M12.7368 19.7573H22.2105V17.8626H12.7368V19.7573ZM12.7368 14.7047H22.2105V12.8099H12.7368V14.7047ZM10.2835 24.4941C9.64537 24.4941 9.10526 24.2731 8.66316 23.831C8.22105 23.3889 8 22.8488 8 22.2107V2.77761C8 2.13951 8.22105 1.5994 8.66316 1.1573C9.10526 0.715193 9.64537 0.494141 10.2835 0.494141H20.3158L26.9474 7.12572V22.2107C26.9474 22.8488 26.7263 23.3889 26.2842 23.831C25.8421 24.2731 25.302 24.4941 24.6639 24.4941H10.2835ZM19.3684 8.07309V2.38888H10.2835C10.1862 2.38888 10.0972 2.4294 10.0163 2.51046C9.93526 2.5913 9.89474 2.68035 9.89474 2.77761V22.2107C9.89474 22.3079 9.93526 22.397 10.0163 22.4778C10.0972 22.5589 10.1862 22.5994 10.2835 22.5994H24.6639C24.7612 22.5994 24.8502 22.5589 24.9311 22.4778C25.0121 22.397 25.0526 22.3079 25.0526 22.2107V8.07309H19.3684Z" fill="#1C1B1F"/>
        </g>
        <defs>
        <clipPath id="clip0_1112_1106">
        <rect width="34.9254" height="24.9467" fill="white" transform="translate(0 0.280273)"/>
        </clipPath>
        </defs>
      </svg>


      <span>Дашборд </span></a>
  </li>

</ul>


</nav>
