import {ChangeDetectionStrategy, Component, computed, inject, Input, signal} from '@angular/core';
import {
  ApiDashboardActiveremarkByFacilitytypeGetRequestParams,
  DashboardService
} from '../../../../apiModule/api/dashboard.service';
import {toSignal} from '@angular/core/rxjs-interop';
import {map} from 'rxjs/operators';
import {catchError, of} from 'rxjs';

@Component({
  selector: 'ostso-by-priority-dashboard-widget',
  standalone: true,
  imports: [],
  templateUrl: './by-priority-dashboard-widget.component.html',
  styleUrls: ['./by-priority-dashboard-widget.component.scss', '../../dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ByPriorityDashboardWidgetComponent {
  dashboardService = inject(DashboardService);
  private _filter?: ApiDashboardActiveremarkByFacilitytypeGetRequestParams;
  data = signal<any[]>([]);

  @Input() set filter(v: ApiDashboardActiveremarkByFacilitytypeGetRequestParams) {
    this._filter = v;
    this.dashboardService.apiDashboardActiveremarkByRatepriorityGet({...this.filter || {}})
      .pipe(catchError((e: any) => {
        console.log(e);
        return of({values: []});
      }))
      .pipe(map(v => v.values))
      .subscribe(x => {
        this.data.set(x);
      });
  }

  // data = toSignal(this.dashboardService.apiDashboardActiveremarkByRatepriorityGet({...this.filter || {}}).pipe(map(v => v.values)));
  priority = computed(() => this.data()?.reduce((acc: any, item: any) => {
    acc['id' + item.id] = item.count;
    return acc;
  }, {} as Record<string, number>));
  maxPriorityCount = computed(() => Math.max(...Object.values(this.priority() ?? {}) as number[]));
}
