import {AfterViewInit, Component, DestroyRef, effect, ElementRef, OnInit, signal, ViewChild} from '@angular/core';
import {provideNativeDateAdapter} from '@angular/material/core';
import {CommonModule} from '@angular/common';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BehaviorSubject, catchError, combineLatest, filter, Observable, of, switchMap, tap} from 'rxjs';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialog} from '@angular/material/dialog';
import {SideBarDoubleComponent} from '../../../shared/components/side-bar-double/side-bar-double.component';
import {TableCellDirective} from '../../../shared/components/table/table-cell.directive';
import {TableComponent} from '../../../shared/components/table/table.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ActivatedRoute, RouterLink} from '@angular/router';
import {DataTableService} from '../../../core/services/data-table.service';
import {Chart, registerables} from 'chart.js';
import {RegisterHeaderComponent} from '../register/register-header/register-header.component';
import {Sort} from '@angular/material/sort';
import {RatingOrgsDataTableService} from './rating-orgs-data-table.service';
import {ApiFirmRateGetRequestParams, FirmRateService} from '../../apiModule/api/firmRate.service';
import {CommunicationService} from '../../../core/services/communication.service';
import {RatingModalComponent} from '../rating-modal/rating-modal.component';
import {OrgFilterComponent} from './org-filter/org-filter.component';
import {FiltersStoreService} from '../../../core/services/filters-store.service';
import {FilterIndicatorsBarComponent} from '../register/filter-indicators-bar/filter-indicators-bar.component';
import {map} from 'rxjs/operators';
import {ZoneRatingService} from '../../apiModule/api/zone-rating.service';
import {RenderLastRowService} from './render-last-row.service';
import {ToPdfDirective} from '../../../core/directives/to-pdf.directive';
import {ExcelService} from '../../../core/services/excel.service';

Chart.register(...registerables);

interface TableData {
  id: number;
  firm: any,
  overallRating: number | null,
  facilitiesCount: number | null,
}

export interface GroupRate {
  id: number;
  name: string;
  rating: number;
  shortName: string;
}

export type GroupRating = GroupRate[]
export type Diapazone = {
  min: number;
  max: number;
  color: string;
}

export type sortType = '' | 'asc' | 'desc';

@Component({
  selector: 'ostso-org-rating',
  standalone: true,
  providers: [
    provideNativeDateAdapter(),
    {provide: DataTableService, useClass: RatingOrgsDataTableService},
    RenderLastRowService
  ],
  imports: [
    MatSelectModule,
    MatSidenavModule,
    MatIconButton,
    MatIcon,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    CommonModule,
    MatDatepickerModule,
    SideBarDoubleComponent,
    TableCellDirective,
    TableComponent,
    RegisterHeaderComponent,
    RouterLink,
    FilterIndicatorsBarComponent,
    ToPdfDirective
  ],
  templateUrl: './org-rating.component.html',
  styleUrls: ['./rating.component.scss', '../../../shared/components/table/table.component.scss', '../register/register.component.scss'],
})

export class OrgRatingComponent implements OnInit, AfterViewInit {

  @ViewChild('table', {read: ElementRef}) table?: ElementRef;
  @ViewChild('table', {read: TableComponent}) tableComponent?: TableComponent<TableData>;
  @ViewChild(ToPdfDirective) toPdf?: ToPdfDirective;

  public opened = true;
  mobileOpen = false;
  isPdfReport = false;

  snapshoting = signal(false);
  xlsExporting = this.xlsService.executing;
  isLastRowRendering = false;

  updateList = new BehaviorSubject<void>(undefined);


  transformDataFn = (data: { values: any[], pager: Pager }): { values: any[], pager: Pager } => {
    return data;
  };

  search$ = new BehaviorSubject<string>('');
  sort$ = new BehaviorSubject<Sort>({direction: '', active: ''});
  filters$ = new BehaviorSubject<FilterFormData | null>(this.filterStore.get('org-rating') || {} as FilterFormData);
  filtersFromDialog$ = new BehaviorSubject<FilterFormData | null>(null);
  // pager$ = new BehaviorSubject<PageEvent>(this.emptyPager);


  searchObservable$ = this.search$.asObservable();
  sortObservable$ = this.sort$.asObservable();
  filtersObservable$ = this.filters$.asObservable();

  zoneRating?: { min: number, max: number, color: string }[];

  constructor(
    private route: ActivatedRoute,
    private destroyRef: DestroyRef,
    public dataTableService: DataTableService,
    private dataService: FirmRateService,
    private dialog: MatDialog,
    private communicationService: CommunicationService,
    private filterStore: FiltersStoreService,
    private zoneRatingService: ZoneRatingService,
    private renderLastRowService: RenderLastRowService<FirmStat>,
    private xlsService: ExcelService,
  ) {
    this.filters$.subscribe(filters => this.filterStore.set('org-rating', filters || ({} as FilterFormData)));
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.filters$.subscribe(() => this.renderLastRow()), 1500);
    console.log('ngAfterViewInit');
  }


  renderLastRow(): void {
    this.isLastRowRendering = false;
    this.tableComponent?.changedSelectedColumn();
    this.table && this.renderLastRowService.init('firm', this.table, this.filters$.value)
      .pipe(filter(v => !!v))
      .subscribe((v: boolean) => {
        this.zoneRating && colorizeByDiapason('tr > td:nth-child(n+3)', this.zoneRating.reverse());
        this.zoneRating && colorizeByDiapason('tr.overall-row > td:nth-child(n+1)', this.zoneRating.reverse());
        this.isLastRowRendering = true;
      });
  }

  parseGroupsData(data: (TableData & { groupRating: GroupRating })[]): (TableData & { [key: string]: number })[] {
    return data.map(el => {
      const groupRating: GroupRating = el.groupRating;
      if (!groupRating) return;
      const groups = Object.entries(groupRating).map(([_, value]) => [
        'group' + value.id, value.rating + '%'
      ]);
      return {...el, ...Object.fromEntries(groups)};
    }).filter(Boolean);
  }

  fillNumberFieldBySort(data: any[]): any[] {
    const {direction} = this.sort$.value;
    const res = data.map((el: TableData & { [key: string]: number }, index: number) => ({
      ...el,
      number: direction === 'desc' ? data.length - index : index + 1,
    }));
    return res;
  }


  onChangeData(v: any): void {
    if (!v) {
      this.dataTableService.setData({pager: {}, values: []});
      return;
    }
    const values = v['values'] && (v['values'] as TableData[]).map(el => this.fromDataToTable(el)) || [];
    const valuesWithGroups = this.parseGroupsData(this.fillNumberFieldBySort(values));
    setTimeout(() => {
      this.dataTableService.setData({pager: {}, values: valuesWithGroups});
    });
  }

  fromDataToTable(value: Partial<TableData>): Partial<TableData> {
    return {
      ...value,
      id: value.firm.id,
      firm: value.firm.shortName,
      overallRating: value.overallRating,
      facilitiesCount: value.facilitiesCount,
    };
  }


  onSortedColumn({active, direction}: { active: string, direction: sortType }): void {
    console.log(active, direction);
    this.sort$.next({active, direction});
    this.renderLastRow();
  }

  refreshData(): Observable<any> {
    return combineLatest([this.searchObservable$, this.sortObservable$, this.filtersObservable$])
      .pipe(tap(v => console.log('combineLatest', v)))
      .pipe(switchMap(() => {
          console.log('filters ', this.filters$.value);
          const payload: ApiFirmRateGetRequestParams = {
            ...this.filters$.value,
            searchString: this.search$.value,
            sortColumnName: this.sort$.value.active ? (this.sort$.value.active === 'number' ? 'firm' : this.sort$.value.active) : undefined,
            sortAscending: this.sort$.value.direction === 'asc' ? true : (this.sort$.value.direction === 'desc' ? false : undefined),
            pageIndex: 0,
            pageSize: 1e4
          };
          console.log('payload', payload);
          return this.dataService.apiFirmRateGet(payload);
        }
      ))
      .pipe(catchError(err => {
        console.error(err);
        return of(this.communicationService.publish('Ошибка запроса данных', {type: 'error'}));
      }))
      .pipe(takeUntilDestroyed(this.destroyRef));
  }

  ngOnInit(): void {
    this.route.data.subscribe((v: any) => {
      const res = v['data'] ? v['data'] : v;
      this.onChangeData(res);
    });
    this.refreshData().subscribe(v => {
      this.onChangeData(v);
    });
    console.log(this.route.data);
    this.zoneRatingService.apiZoneRatingGet()
      .pipe(map(v => v.values))
      .subscribe(v => {
        this.zoneRating = v;
      });

  }

  toggleClass(): void {
    this.isPdfReport = !this.isPdfReport;
  }


  showMoreInfo(data: any): void {
    this.dialog.open(RatingModalComponent, {
      width: 'max(320px,80vw)',
      data: data
    });
  }

  onChangeColumns($event: any[]): void {
    console.log('onChangeColumns', $event);
    this.table && this.renderLastRowService.init('firm', this.table, this.filters$.value);
  }

  onClickOutside(): void {
    if (!this.mobileOpen) return;
    this.mobileOpen = false;
  }

  openFilterDialog(): void {
    // if (!this.filters$.value) this.filters$.next({} as FilterFormData);
    const dialogRef = this.dialog.open(OrgFilterComponent, {
      width: '690px',
      height: '100vh',
      position: {
        right: '0px'
      },
      data: this.filters$.value,
    });

    //включение фильтрации
    dialogRef.afterClosed().subscribe((v) => {
      if (!v) return;
      this.filters$.next(v);

    });
  }

  onResetFilter(): void {
    this.filters$.next({} as FilterFormData);
  }

  async onExportPdf(): Promise<void> {
    if (!this.toPdf) return;
    await this.toPdf.snapshot(() => {
      // this.charts.forEach(c => c.refresh())
    }, 'general-report');
  }

  onSnapshot(event: boolean): void {
    this.snapshoting.set(event);
  }

  effectSnapshot = effect(() => {
    console.log(this.snapshoting());
    this.renderLastRow();
  });

  onExportExcel(): void {
    this.tableComponent && this.xlsService.export(this.tableComponent.tableRef?.nativeElement, 'Организации МСК');
  }
}

const colorMap: Record<string, string> = {
  red: 'rgb(228, 33, 48)',
  green: 'rgb(0, 156, 65)',
  yellow: 'rgb(250, 175, 63)',
};

export function colorizeByDiapason(selector: string, diapazones: Diapazone[]): void {
  const items = document.querySelectorAll(selector);
  items.forEach(el => {
    const data = (el as HTMLElement).textContent?.trim();
    if (!data) return;
    const digits = parseFloat(data);
    if (!digits) return;
    const diapa = diapazones.find(diapa => digits > diapa.min && digits <= diapa.max);
    diapa && ((el as HTMLElement).style.color = colorMap[diapa.color]);
    diapa && (el as HTMLElement).setAttribute('data-color', diapa.color);
  });
}



