import {AfterViewInit, Component, DestroyRef, inject, OnInit, signal, ViewChild} from '@angular/core';
import {DatePipe, NgStyle} from '@angular/common';
import {FilterIndicatorsBarComponent} from '../register/filter-indicators-bar/filter-indicators-bar.component';
import {First3Pipe} from '../../../core/pipes/first3.pipe';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {MatInput, MatPrefix} from '@angular/material/input';
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import {MatSidenav, MatSidenavContainer, MatSidenavContent} from '@angular/material/sidenav';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RegisterHeaderComponent} from '../register/register-header/register-header.component';
import {SideBarDoubleComponent} from '../../../shared/components/side-bar-double/side-bar-double.component';
import {TableActionDirective} from '../../../shared/components/table/table-action.directive';
import {TableCellDirective} from '../../../shared/components/table/table-cell.directive';
import {TableComponent} from '../../../shared/components/table/table.component';
import {ActivatedRoute, Router} from '@angular/router';
import {BreakpointObserver} from '@angular/cdk/layout';
import {MatDialog} from '@angular/material/dialog';
import {BehaviorSubject, catchError, combineLatest, Observable, of, switchMap, tap} from 'rxjs';
import {Sort} from '@angular/material/sort';
import {PageEvent} from '@angular/material/paginator';
import {ContentMenuItems} from '../register/register.component';
import {RegisterRateCardDataTableService} from '../../../core/services/register-rate-card-data-table.service';
import {DataTableService} from '../../../core/services/data-table.service';
import {ApiRatecardGetRequestParams, RateCardService} from '../../apiModule/api/rate-card.service';
import {CommunicationService} from '../../../core/services/communication.service';
import {FilterRateCardComponent} from './filter/filter-rate-card.component';
import {UserService} from '../../../core/services/user.service';
import {InspectorsService} from '../../../core/services/inspectors.service';
import {OperatorsService} from '../../../core/services/operators.service';
import {RemarkTypeService} from '../../apiModule';
import {RegisterDialogDeleteComponent} from '../register/dialogs/dialogForDelete/register-dialog-delete.component';
import * as XLSX from 'xlsx';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FiltersStoreService} from '../../../core/services/filters-store.service';
import {_autoFitColumns} from '../../../core/services/xls.service';

interface TableData {
  id: number,
  priority: Priority,
  createAt: string,
  firm: string,
  facility: string,
  facilityAddress: string,
  remarkType: string,
  status: string,
  facilityType: string,
  firmAddress?: string | null,
  district: string,
  rateGroup: string,
  rateSubGroup: string,
  rateKindGroup: string,
  daysInWork: number,
  finishedDate?: string | null,
  inspector?: string | null,
  operator?: string | null,
  comment: string,
  statusComment: string,
  archive: StatusRateCard
}

@Component({
  selector: 'ostso-ratecard',
  standalone: true,
  imports: [
    DatePipe,
    FilterIndicatorsBarComponent,
    First3Pipe,
    MatIcon,
    MatIconButton,
    MatInput,
    MatMenu,
    MatPrefix,
    MatSidenav,
    MatSidenavContainer,
    MatSidenavContent,
    ReactiveFormsModule,
    RegisterHeaderComponent,
    SideBarDoubleComponent,
    TableActionDirective,
    TableCellDirective,
    TableComponent,
    FormsModule,
    MatMenuTrigger,
    NgStyle,
    MatMenuItem,
  ],
  templateUrl: './rate-card.component.html',
  styleUrls: [
    '../register/register.component.scss',
    './rate-card.component.scss'
  ],
  providers: [
    {provide: DataTableService, useClass: RegisterRateCardDataTableService}
  ]
})
export class RateCardComponent implements OnInit, AfterViewInit {

  @ViewChild('table', {read: TableComponent}) table!: TableComponent<unknown>;

  public opened = true;
  public legendEnabled = true;
  public searchPhrase: string = '';

  private readonly emptyPager: Pager = {
    length: 0,
    pageIndex: 1,
    pageSize: 10,
    previousPageIndex: 0
  };

  private _snackBar = inject(MatSnackBar);
  public mobileOpen: boolean = false;

  openSnackBar(message: string): void {
    this._snackBar.open('🗸 ' + message, '', {duration: 2000, panelClass: ['snack-bar'], horizontalPosition: 'end'});
  }


  updateList = new BehaviorSubject<void>(undefined);

  colorSelected: Record<string, string> = {
    low: '#EAFA30',
    average: '#61C33E',
    high: '#E22B36',
    critical: '#3D6DCE',
  };

  formatDate(date: string): string {
    return date.split(' ')[0];
  }

  search$ = new BehaviorSubject<string>('');
  sort$ = new BehaviorSubject<Sort>({direction: '', active: ''});
  filters$ = new BehaviorSubject<FilterFormData | null>(null);
  filtersToDialog$ = new BehaviorSubject<FilterFormData>(this.filterStore.get('rate-card') || {} as FilterFormData);
  pager$ = new BehaviorSubject<PageEvent>(this.emptyPager);


  searchObservable$ = this.search$.asObservable();
  sortObservable$ = this.sort$.asObservable();
  filtersObservable$ = this.filters$.asObservable();
  pagerObservable$ = this.pager$.asObservable();

  contentMenuItems: ContentMenuItems = {
    open: 'Открыть',
    edit: 'Редактировать',
    delete: 'Архивировать',
    restore: 'Востановить',
  };

  doExportExcell = signal(false);

  constructor(
    public readonly actRoute: ActivatedRoute,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private dataService: RateCardService,
    private readonly dataTableService: DataTableService,
    private readonly destroyRef: DestroyRef,
    public dialog: MatDialog,
    private communicationService: CommunicationService,
    public user: UserService,
    public inspectors: InspectorsService,
    public operators: OperatorsService,
    public remarkTypeService: RemarkTypeService,
    private filterStore: FiltersStoreService
  ) {
    this.inspectors.inspectors$.asObservable().subscribe(inspectors => {
      console.log(inspectors);
    });

    this.filtersToDialog$.subscribe(v => {
      this.filterStore.set('rate-card', v);

      const res = this.convertFilterDataToRequest(v);
      console.log('from filters', res);
      this.filters$.next(res as unknown as FilterFormData);
    });

    this.breakpointObserver.observe([
      '(max-width: 1000px)'
    ]).subscribe(result => {
      this.opened = !result.matches;
    });
  }

  getRoleName(roleId: number, data: BehaviorSubject<User[]>): string {
    const user = data.value.find(el => el.id === roleId);
    if (!user) return '';
    return user.lastName + ' ' + user.firstName;
  }

  getInspectorName(inspectorId: number): string {
    return this.getRoleName(inspectorId, this.inspectors.inspectors$);
  }

  getOperatorName(operatorId: number): string {
    return this.getRoleName(operatorId, this.operators.operators$);
  }

  onChangeData(v: any): void {
    if (!v) {
      this.dataTableService.setData({pager: this.pager$.value, values: []});
      return;
    }
    const pager = v && v['pager'];
    const values = v['values'] && (v['values'] as RateCard[]).map(el => this.fromDataToTable(el)) || [];
    this.dataTableService.setData({pager, values});
  }

  fromDataToTable(value: Partial<RateCard>): Partial<TableData> {
    return {
      ...value,
      createAt: new Date(value.createAt || '').toLocaleDateString() + ' - ' + new Date(value.createAt || '').toLocaleTimeString(),
      priority: value.priority,
      firm: value.firm?.shortName,
      facility: value.facility?.name,
      remarkType: value.remarkType?.name,
      status: value.status?.name,
      facilityType: value.facilityType?.name,
      district: value.district?.name,
      rateGroup: value.rateGroup?.name,
      rateSubGroup: value.rateSubGroup?.name,
      rateKindGroup: value.rateKindGroup?.name,
      archive: value.archive,
      finishedDate: value.finishedDate

    };
  }

  onSearch(): void {
    this.search$.next(this.searchPhrase);
  }

  convertFilterDataToRequest(filterData: object): FilterFormData {
    const idFromArray = (arr: any[]): any[] | undefined => {
      if (!arr.length) return;
      return arr.map(i => {
        if (i.id) return i.id;
        if (i.name) return window.encodeURIComponent(i.name);
        if (typeof i === 'string' || typeof i === 'number') return i;
      });
    };

    const res = Object.fromEntries(Object.entries(filterData)
      .filter(([_, value]) => value !== undefined && value !== null || (Array.isArray(value) && (value as Array<any>).length > 0))
      .map(([key, value]) => [key, Array.isArray(value) ? idFromArray(value) : value])
      .filter(([_, value]) => !!value)
      .map(([key, value]) => {
        if (value === 'true') return [key, true];
        if (value === 'false') return [key, false];
        return [key, value];
      })
    );

    return res as FilterFormData;
  }

  openFilterDialog(): void {
    if (!this.filters$.value) this.filtersToDialog$.next({} as FilterFormData);
    const dialogRef = this.dialog.open(FilterRateCardComponent, {
      width: '690px',
      height: '100vh',
      position: {
        right: '0px'
      },
      data: this.filtersToDialog$.value,
    });

    //включение фильтрации
    dialogRef.afterClosed().subscribe((v) => {
      if (!v) return;
      this.table.setPage({page: 1});
      this.filtersToDialog$.next(v);
    });
  }

  onPageChange(pager: PageEvent): void {
    this.pager$.next(pager);
  }

  onSortedColumn($event: Sort): void {
    const res = {
      direction: $event.direction,
      active: $event.active
    };
    const convertMap: Record<string, string> = {
      facility: 'facility.name',
      firm: 'firm.shortName',
      remarkType: 'remarktype.name',
      rateGroup: 'rategroup.name',
      rateSubgroup: 'ratesubgroup.name',
      rateKind: 'ratekind.name'
    };

    Object.keys(convertMap).includes(res.active) && (res.active = convertMap[res.active]);
    this.sort$.next(res);
  }

  onRowSelected(event: Event, row: Partial<TableData>): void {
    event.stopPropagation();
    event.preventDefault();
    // this.runDialog(row, false);
    console.log(row);
    row.id && this.router.navigate(['grade', row.id]).then(() => {
      console.log(this.router.url, window.location.href);
    });
  }

  onCopyUrl(event: Event, row: Partial<TableData>): void {
    const fullUrl = window.location.origin + `/grade/${row.id}`;
    navigator.clipboard.writeText(fullUrl);
    this.openSnackBar('Ссылка скопирована в буфер обмена');
  }


  deleteElement($event: MouseEvent, element: any): void {
    console.log(element);
    const dialogRef = this.dialog.open(RegisterDialogDeleteComponent, {
      width: 'max(320px,70vw)',
      data: element,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      const id = result.id;
      delete result.id;
      this.dataService.apiRatecardSetarchiveIdPut({id, apiRatecardSetstatusIdPutRequest: result})
        .subscribe(() => {
          this.onPageChange(this.pager$.value);
        });
    });
  }

  restoreElement(element: RateCard): void {
    console.log('restoreElement', element);
    this.dataService.apiRatecardSetarchiveIdPut({
      id: element.id!, apiRatecardSetstatusIdPutRequest: {
        comment: element.archive.comment,
        date: element.archive.date,
        statusId: 1
      }
    })
      .subscribe(() => {
        this.onPageChange(this.pager$.value);
      });
  }

  refreshData(): Observable<any> {
    return combineLatest([this.searchObservable$, this.sortObservable$, this.filtersObservable$, this.pagerObservable$])
      .pipe(tap(v => console.log('combineLatest', v)))
      .pipe(switchMap(() => {
          console.log('filters ', this.filters$.value);
          const payload: ApiRatecardGetRequestParams = {
            ...this.filters$.value,
            searchString: this.search$.value,
            sortColumnName: this.sort$.value.active ? this.sort$.value.active : undefined,
            sortAscending: this.sort$.value.direction === 'asc' ? true : undefined,
            pageIndex: this.pager$.value.pageIndex,
            pageSize: this.pager$.value.pageSize
          };
          console.log('payload', payload);
          return this.dataService.apiRatecardGet(payload);
        }
      ))
      .pipe(catchError(err => {
        console.error(err);
        return of(this.communicationService.publish('Ошибка запроса данных', {type: 'error'}));
      }))
      .pipe(takeUntilDestroyed(this.destroyRef));
  }

  ngOnInit(): void {
    this.actRoute.data.subscribe((v: any) => {
      const res = v['data'] ? v['data'] : v;
      this.onChangeData(res);
    });

    // const currentFilter = this.filterStore.get('rate-card');
    // if(!currentFilter) return;
    // const filter = this.convertFilterDataToRequest(currentFilter);
    // this.filtersToDialog$.next(currentFilter); //передаем в диалог
    // this.filters$.next(filter);

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.refreshData().subscribe(v => {
        this.onChangeData(v);
      });
    });
  }

  refresh(): void {
    window.location.reload();
  }


  exportExcell(): void {
    console.log('start export excell');
    this.doExportExcell.set(true);
    const prevPager = this.pager$.value;
    this.refreshData()
      .subscribe((v: { pager: Pager }) => {
          console.log('exportExcell', v);
          if (v.pager?.count !== 1e6) return;
          setTimeout(() => {
            const worksheet = XLSX.utils.table_to_sheet(this.table.tableRef.nativeElement,{raw: true});
            // @ts-expect-error: тредование XLXS
            const range = XLSX.utils.decode_range(worksheet['!ref']);
            console.log('worksheet[\'A1\'].', worksheet['A1']);

            const lastCol = range.e.c;

// Пройтись по всем строкам и удалить ячейки последнего столбца
            for (let R = range.s.r; R <= range.e.r; R++) {
              const cellAddress = XLSX.utils.encode_cell({r: R, c: lastCol});
              delete worksheet[cellAddress];
            }

// Обновить диапазон листа, уменьшив количество столбцов на 1
            range.e.c = lastCol - 1;
            worksheet['!ref'] = XLSX.utils.encode_range(range);
            worksheet['!cols'] = _autoFitColumns(worksheet);

// Указываем индекс колонки, которую нужно сделать текстовой (например, колонка 2, индекс начинается с 0)
            const textColumnIndex = 5;

// Применяем текстовый формат к ячейкам указанной колонки
            for (let row = 1; row <= this.table.tableRef.nativeElement.rows.length; row++) {
              const cellAddress = XLSX.utils.encode_cell({r: row - 1, c: textColumnIndex});
              if (worksheet[cellAddress]) {
                worksheet[cellAddress].z = '@'; // Указывает текстовый формат
                worksheet[cellAddress].t = 's'; // Указываем, что тип ячейки — строка (string)
              }
            }

            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

            console.log('write file excell', worksheet);
            XLSX.writeFile(workbook, 'Реестр_оценок.xlsx');
            this.onPageChange(prevPager);
            this.doExportExcell.set(false);
          }, 1000);
        }
      );
    this.onPageChange({
      pageSize: 1e6,
      pageIndex: 1,
      length: this.table.recordCount
    });
  }

  resetAllFilters(): void {
    this.filtersToDialog$.next({} as FilterFormData);
  }

  onClickOutside(): void {
    if (!this.mobileOpen) return;
    this.mobileOpen = false;
  }
}


