<div class="top5">
  <h4>
    ТОП-5
    <a
      class="btn"
      [ngClass]="{ 'btn-red': activeTab === 'objects', 'btn-white': activeTab !== 'objects' }"
      (click)="setActiveTab('objects')">
      объектов
    </a>
    <a
      class="btn"
      [ngClass]="{ 'btn-red': activeTab === 'institutions', 'btn-white': activeTab !== 'institutions' }"
      (click)="setActiveTab('institutions')">
      учреждений
    </a>
    <span (click)="showMoreInfo(activeTab === 'objects' ? 'objects' : 'institutions')">Подробнее</span>
  </h4>

  <div *ngIf="activeTab === 'objects'">
    <div class="horizontal-bar-chart">
      <div *ngFor="let facility of top5facility()">
        <div class="title">{{ facility.shortName }}
          <br>
          <span>{{ facility.address }}</span>
          <span>{{ facility.balanceHolder.shortName }}</span>
        </div>
        <div class="bar">
          <div [style.width.%]="(facility.count / maxTop5facilityCount()) * 100">{{ facility.count }}</div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="activeTab === 'institutions'">
    <div class="horizontal-bar-chart">
      <div *ngFor="let firm of top5firms()">
        <div class="title">{{ firm.shortName }}</div>
        <div class="bar">
          <div [style.width.%]="(firm.count / maxTop5firmsCount()) * 100">{{ firm.count }}</div>
        </div>
      </div>
    </div>
  </div>

</div>
