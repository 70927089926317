<div class="account">
    <div class="account__header">
        <h1>Карточка пользователя</h1>
        <mat-icon (click)="dialogRef.close()">close</mat-icon>
    </div>
    <div class="account__body">
        @for (item of Object.entries(accountData); track item) {
            <div class="account__item">
                <div class="account__param">{{ item[0] }}</div>
                <div class="account__value">{{ item[1] || 'не указано' }}</div>
            </div>
        }
        @if (isInspector) {
            <form class="account__statuses statuses" [formGroup]="form">
                <div class="statuses__header">Выбор статусов для отправки на электронную почту</div>
                @defer (when statuses.length) {
                    <ul class="statuses__list" formArrayName="statuses">
                        @for (status of statuses.controls; track status; let i = $index) {
                            <li class="statuses__item">
                                <mat-checkbox color="red"
                                              class="statuses__checkbox"
                                              type="checkbox"
                                              [formControlName]="i"
                                />
                                <span>
                                C "{{ rateStatusIntervals && rateStatusIntervals[i] && rateStatusIntervals[i]['fromStatus'].name }}
                                    " на "{{ rateStatusIntervals && rateStatusIntervals[i] && rateStatusIntervals[i]['toStatus'].name }}
                                    "
                            </span>
                            </li>
                        }
                    </ul>
                } @placeholder () {
                    <div class="spinner-container">
                        <mat-spinner></mat-spinner>
                    </div>
                }
            </form>
        }
    </div>
    <div class="account__controls" mat-dialog-actions>
        @if (isInspector) {
            <button class="btn btn-blue" mat-button (click)="apply()">Применить</button>
            <button class="btn btn-secondary" mat-button (click)="reset()">Сбросить</button>
        }
    </div>
</div>
