import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatIcon} from '@angular/material/icon';
import {FormArray, FormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {ReferenceService} from '../../../../modules/apiModule/api/reference.service';
import {map} from 'rxjs/operators';
import {MatCheckbox} from '@angular/material/checkbox';
import {AccountControllerService} from '../../../../modules/apiModule/api/account-controller.service';
import {UserUpdateDto} from '../../../../modules/apiModule/model/user-update-dto';
import {UserService} from '../../../../core/services/user.service';
import {MatProgressSpinner} from '@angular/material/progress-spinner';

@Component({
  selector: 'ostso-account',
  standalone: true,
  imports: [
    MatIcon,
    ReactiveFormsModule,
    MatCheckbox,
    MatProgressSpinner
  ],
  templateUrl: './account.component.html',
  styleUrl: './account.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountComponent implements OnInit {

  data: User = inject(MAT_DIALOG_DATA);
  dialogRef = inject(MatDialogRef<AccountComponent>);
  fb = inject(FormBuilder);
  referenceService = inject(ReferenceService);
  accountService = inject(AccountControllerService);
  userService = inject(UserService);
  cdr = inject(ChangeDetectorRef);

  get isAdmin(): boolean {
    return this.data.userRoles[0].code === 'admin';
  }

  get isInspector(): boolean {
    return this.data.userRoles[0].code === 'inspector';
  }

  get isOperator(): boolean {
    return this.data.userRoles[0].code === 'operator';
  }

  rateStatusIntervals: RateStatusInterval[] = [];

  form = this.fb.group({
    statuses: this.fb.array([], Validators.required),
  });

  get statuses(): FormArray {
    return this.form.get('statuses')! as FormArray;
  }

  get statusesValue(): boolean[] {
    return this.statuses.controls.map(control => !!control.value);
  }

  accountData = {
    ['Роль']: this.data.userRoles[0].name,
    ['ФИО']: `${this.data.lastName} ${this.data.firstName} ${this.data.middleName}`,
    ['Должность']: this.data.position,
    ['Электронная почта']: this.data.emailAddress,
    ['Телефон']: this.data.phone,
  };

  ngOnInit(): void {
    this.statuses.valueChanges.subscribe(status => {
      console.log(this.statusesValue);
    });
    this.referenceService.apiReferenceRateStatusIntervalsGet()
      .pipe(map(v => v.values))
      .subscribe((v: RateStatusInterval[]) => {
        console.log('apiReferenceRateStatusIntervalsGet', v);
        v.forEach((item: RateStatusInterval) => {
          this.rateStatusIntervals.push(item);
          this.statuses.push(this.fb.control(item.selectedByDefault, Validators.required));
        });
        this.data.rateStatusIntervalList.forEach((item: RateStatusInterval) => {
          const found = this.rateStatusIntervals.findIndex(v => v.id === item.id);
          if (found!==-1) this.statuses.controls[found].setValue(true);
        });
        this.cdr.markForCheck();
      });
  }

  reset(): void {
    this.statuses.setValue([true,false,false,false]);
  }

  async apply(): Promise<void> {
    console.log(this.statuses.value);
    const rateStatusIntervalList = this.statuses.value.map((v: boolean, i: number) => v && this.rateStatusIntervals[i]).filter(Boolean);
    this.accountService.apiAccountIdPut({
      id: this.data.id,
      apiAccountIdPutRequest: {...this.data, rateStatusIntervalList} as UserUpdateDto
    })
      .subscribe(v => {
        console.log(v);
        this.userService.user$.next(v);
        this.dialogRef.close();
      });
  }

  protected readonly Object = Object;

}
