<button mat-icon-button class="close-button" (click)="onNoClick()">
  <mat-icon>close</mat-icon>
</button>
<div class="ostso-dialog" [class.blocked]="!loaded()">
  @if (!loaded()) {
    <ostso-fallback/>
  }
  <h1>Фильтр</h1>
  <form [formGroup]="form" class="filter-form">

    <ostso-multi-select [formControlName]="'firmIds'"
                        [data]="firms()??[]"
                        [placeholder]="'Учреждение'"
    >
      <ng-template let-item="item">
        <span><strong>{{ item?.name }}</strong> <div>({{ item?.shortName }}) (ID: <strong>{{ item?.id }}</strong>)</div><br/></span>
      </ng-template>
    </ostso-multi-select>

    <ostso-multi-select [formControlName]="'facilityIds'"
                        [data]="facilities()??[]"
                        [placeholder]="'Объекты'"
    >
      <ng-template let-item="item">
        <span><strong>{{ item?.name }}</strong> <div>({{ item?.shortName }}) (ID: <strong>{{ item?.id }}</strong>)</div><br/></span>
      </ng-template>
    </ostso-multi-select>

    <ostso-quick-autocomplete [label]="'Адрес объекта'"
                              [data]="facilityAddresses()??[]"
                              [formControlName]="'facilityAddress'"
    >
      <ng-template let-item="option">
        {{ item }}
      </ng-template>
    </ostso-quick-autocomplete>

    <div class="date-estimate">
      <mat-form-field
        appearance="outline"
      >
        <mat-label>Дата проведения оценки от:</mat-label>
        <mat-datepicker-toggle matIconPrefix [for]="pickerFrom"></mat-datepicker-toggle>
        <mat-datepicker #pickerFrom></mat-datepicker>
        <input matInput [matDatepicker]="pickerFrom" [formControlName]="'createAt'">
      </mat-form-field>
      <mat-form-field
        appearance="outline"
      >
        <mat-label>Дата проведения оценки до:</mat-label>
        <mat-datepicker-toggle matIconPrefix [for]="pickerTo"></mat-datepicker-toggle>
        <mat-datepicker #pickerTo></mat-datepicker>
        <input matInput [matDatepicker]="pickerTo" [formControlName]="'finishedDate'">
      </mat-form-field>
    </div>

  </form>
  <div mat-dialog-actions class="filter-form__actions">
    <button [ngClass]="['btn', form.status==='VALID' ? 'btn-blue': 'btn-gray']"
            [ngStyle]="{color:form.status==='VALID' ? '#fff' : '#777'}"
            mat-button
            [disabled]="form.status!=='VALID'"
            (click)="onApply()"
    >
      Применить
    </button>
    <button class="btn btn-secondary" mat-button (click)="onReset()">Сбросить</button>
  </div>

</div>

