import {ResolveFn} from '@angular/router';
import {inject} from '@angular/core';
import {FirmRateService} from '../../../apiModule/api/firmRate.service';
import { HttpContext } from '@angular/common/http';

export const orgRateResolver: ResolveFn<any> = (
  route,
  state,
  firmRateService: FirmRateService = inject(FirmRateService)
) => {
  return firmRateService.apiFirmRateGet({pageSize: 1e4}, 'body', false, {
    context: new HttpContext()
});
};


