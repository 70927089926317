import {Component, DestroyRef, OnInit, signal, ViewChild} from '@angular/core';
import {provideNativeDateAdapter} from '@angular/material/core';
import {CommonModule} from '@angular/common';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BehaviorSubject, catchError, forkJoin, of} from 'rxjs';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialog} from '@angular/material/dialog';
import {SideBarDoubleComponent} from '../../../shared/components/side-bar-double/side-bar-double.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ActivatedRoute} from '@angular/router';
import {DataTableService} from '../../../core/services/data-table.service';
import {Chart, registerables} from 'chart.js';
import {RegisterHeaderComponent} from '../register/register-header/register-header.component';
import {ApiDashboardFacilityfirmCountGetRequestParams, DashboardService} from '../../apiModule/api/dashboard.service';
import {DashboardFilterComponent} from './filter/dashboard-filter.component';
import {combineLatest, switchMap} from 'rxjs';
import {
  ByGroupsDashboardWidgetComponent
} from './widgets/by-groups-dashboard-widget/by-groups-dashboard-widget.component';
import {
  BySubgroupsDashboardWidgetComponent
} from './widgets/by-subgroups-dashboard-widget/by-subgroups-dashboard-widget.component';
import {
  StatisticsDashboardWidgetComponent
} from './widgets/statistics-dashboard-widget/statistics-dashboard-widget.component';
import {
  ByObjectsDashboardWidgetComponent
} from './widgets/by-objects-dashboard-widget/by-objects-dashboard-widget.component';
import {
  ByPriorityDashboardWidgetComponent
} from './widgets/by-priority-dashboard-widget/by-priority-dashboard-widget.component';
import {Top5DashboardWidgetComponent} from './widgets/top5-dashboard-widget/top5-dashboard-widget.component';
import {ChartDashboardWidgetComponent} from './widgets/chart-dashboard-widget/chart-dashboard-widget.component';
import {ToPdfDirective} from '../../../core/directives/to-pdf.directive';
import {FiltersStoreService} from '../../../core/services/filters-store.service';
import {FilterIndicatorsBarComponent} from '../register/filter-indicators-bar/filter-indicators-bar.component';


interface Facility {
  id: number;
  rank: number;
  name: string;
  shortName: string;
  address: string;
  balanceHolder: {
    id: number;
    name: string;
    shortName: string;
  };
  count: number;
}

Chart.register(...registerables);

@Component({
  selector: 'ostso-dashboard',
  standalone: true,
  providers: [
    provideNativeDateAdapter(),
  ],
  imports: [
    MatSelectModule,
    MatSidenavModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    CommonModule,
    MatDatepickerModule,
    SideBarDoubleComponent,
    RegisterHeaderComponent,
    ByGroupsDashboardWidgetComponent,
    BySubgroupsDashboardWidgetComponent,
    StatisticsDashboardWidgetComponent,
    ByObjectsDashboardWidgetComponent,
    ByPriorityDashboardWidgetComponent,
    Top5DashboardWidgetComponent,
    ChartDashboardWidgetComponent,
    ToPdfDirective,
    FilterIndicatorsBarComponent,
  ],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss', '../../../shared/components/table/table.component.scss', '../register/register.component.scss'],
})


export class DashboardComponent implements OnInit {

  @ViewChild(ToPdfDirective) toPdf?: ToPdfDirective;


  public opened = true;
  mobileOpen = false;
  snapshoting = signal(false);

  filter$ = new BehaviorSubject<ApiDashboardFacilityfirmCountGetRequestParams>(this.filterStore.get('dashboard') as ApiDashboardFacilityfirmCountGetRequestParams);
  get filter(): FilterFormData {
    return this.filter$.value as FilterFormData;
  }
  grouping$: BehaviorSubject<Partial<DashboardGrouping>> = new BehaviorSubject<Partial<DashboardGrouping>>(this.filterStore.get('dashboard-grouping') as Partial<DashboardGrouping>);

  public statistics: any;
  public count: any;
  public byperiod: any;
  public chart: any = {};
  public priority: any;


  constructor(
    private route: ActivatedRoute,
    private destroyRef: DestroyRef,
    private dataTableService: DataTableService,
    private dialog: MatDialog,
    private dashboardService: DashboardService,
    private filterStore: FiltersStoreService,
  ) {
    this.route.data.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(({group}) => {
      this.dataTableService.setData(group);
    });
    this.filter$.subscribe(filters => this.filterStore.set('dashboard', filters as FilterFormData || ({} as FilterFormData)));
    this.grouping$.subscribe(grouping => this.filterStore.set('dashboard-grouping', grouping as FilterFormData || ({} as FilterFormData)));
  }

  ngOnInit(): void {
    combineLatest([this.filter$, this.grouping$])
      .pipe(switchMap(([filter, grouping]) => forkJoin({
        statistics: this.dashboardService.apiDashboardRemarkStatisticsGet({...filter, ...grouping}),
        count: this.dashboardService.apiDashboardFacilityfirmCountGet({...filter, ...grouping}),
        byperiod: this.dashboardService.apiDashboardActiveremarkByperiodGet({...filter, ...grouping}),
      })))
      .pipe(catchError((error: any) => {
        console.log('error', error);
        return of({statistics: null, count: null, byperiod: {values: []}});
      }))
      .subscribe(({statistics, count, byperiod}) => {
        this.statistics = statistics;
        this.count = count;
        this.byperiod = byperiod.values.sort((a: any, b: any) => {
          if (a.year === b.year) {
            return a.month - b.month;
          }
          return a.year - b.year;
        });

      });

  }


  onFilterClick(): void {
    this.dialog.open(DashboardFilterComponent, {
      width: '690px',
      height: '100vh',
      position: {
        right: '0px'
      },
      data: {
        filter: this.filter$.value,
        grouping: this.grouping$.value
      }
    })
      .afterClosed().subscribe(result => {
      if (!result) return;
      this.filter$.next(result.filter);
      this.grouping$.next(result.grouping);
    });
  }

  onSnapshot(event: boolean): void {
    this.snapshoting.set(event);
  }

  async onExportPdf(): Promise<void> {
    if (!this.toPdf) return;
    this.toPdf.orientation = 'l';
    this.toPdf.topMargin = 2;
    this.toPdf.bottomMargin = 0;
    await this.toPdf.snapshot(() => {
      // this.charts.forEach(c => c.refresh())
    }, 'dashdoard');
  }

  toggle(): void {
    this.snapshoting.update(v => !v);
  }

  onResetFilter(): void {
    this.filter$.next({} as ApiDashboardFacilityfirmCountGetRequestParams);
    this.grouping$.next({} as DashboardGrouping);
  }
}


