import {ChangeDetectionStrategy, Component, computed, inject, Input, signal} from '@angular/core';
import {NgClass, NgForOf, NgIf} from '@angular/common';
import {
  ApiDashboardActiveremarkByFacilitytypeGetRequestParams,
  DashboardService
} from '../../../../apiModule/api/dashboard.service';
import {toSignal} from '@angular/core/rxjs-interop';
import {map} from 'rxjs/operators';
import {DashboardModal} from '../../../dashboard-modal/dashboard-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {catchError, of} from 'rxjs';

@Component({
  selector: 'ostso-top5-dashboard-widget',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    NgClass
  ],
  templateUrl: './top5-dashboard-widget.component.html',
  styleUrls: ['./top5-dashboard-widget.component.scss','../../dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Top5DashboardWidgetComponent {
  dialog = inject(MatDialog);
  dashboardService = inject(DashboardService);
  private _filter?: ApiDashboardActiveremarkByFacilitytypeGetRequestParams;
  top5facility = signal<any[]>([]);
  top5firms = signal<any[]>([]);

  @Input() set filter(v: ApiDashboardActiveremarkByFacilitytypeGetRequestParams) {
    this._filter = v;
    this.dashboardService.apiDashboardActiveremarkTop5facilityGet({...v || {}})
      .pipe(catchError(err=>{
        console.error(err);
        return of({values:[]});
      }))
      .pipe(map(v => v.values)).subscribe(x => {
      this.top5facility.set(x);
    });
    this.dashboardService.apiDashboardActiveremarkTop5FirmAllGet({...v || {}})
      .pipe(catchError(err=>{
        console.error(err);
        return of({values:[]});
      }))
      .pipe(map(v => v.values)).subscribe(x => {
      this.top5firms.set(x);
    });
  }
  // top5facility = toSignal(this.dashboardService.apiDashboardActiveremarkTop5facilityGet({...this.filter || {}}).pipe(map(v => v.values)));
  maxTop5facilityCount = computed(() => Math.max(...this.top5facility()?.map((f: any) => f.count) ?? 0));

  // top5firms = toSignal(this.dashboardService.apiDashboardActiveremarkTop5FirmAllGet({...this.filter || {}}).pipe(map(v => v.values)));
  maxTop5firmsCount = computed(() => Math.max(...this.top5firms()?.map((f: any) => f.count) ?? 0));

  activeTab: string = 'objects';
  setActiveTab(tab: string): void {
    this.activeTab = tab;
  }
  showMoreInfo(type: string): void {
    this.dialog.open(DashboardModal, {
      width: 'max(320px,50vw)',
      data: {type}
    });
  }
}
