<h4>
  {{ data.type === 'objects' ? 'Объекты' : 'Учреждения' }}
  Москомспорта

  <div class="header-input">
    <mat-icon matPrefix>search</mat-icon>
    <input matInput type="text" [formControl]="facilityFilter" *ngIf="data.type === 'objects'">
    <input matInput type="text" [formControl]="firmFilter" *ngIf="data.type === 'institutions'">
    <input matInput type="text" [formControl]="groupFilter" *ngIf="data.type === 'groups'">
    <input matInput type="text" [formControl]="subgroupFilter" *ngIf="data.type === 'subgroups'">

  </div>


</h4>
<mat-dialog-content>
  <button mat-icon-button class="close-button" (click)="onClose()">
    <mat-icon>close</mat-icon>
  </button>

  <div class="horizontal-bar-chart" *ngIf="data.type === 'objects'">
    <div *ngFor="let facility of filteredFacilities">
      <div class="title">{{ facility.shortName }}
        <br>
        <span>{{ facility.address }}</span>
        <span>{{ facility.balanceHolder.shortName }}</span>
      </div>
      <div class="bar">
        <div [style.width.%]="(facility.count / maxTopFacilityCount) * 100">{{ facility.count }}</div>
      </div>
    </div>
  </div>

  <div class="horizontal-bar-chart" *ngIf="data.type === 'institutions'">
    <div *ngFor="let firm of filteredFirms">
      <div class="title">{{ firm.shortName }}</div>
      <div class="bar">
        <div [style.width.%]="(firm.count / maxTopFirmsCount) * 100">{{ firm.count }}</div>
      </div>
    </div>
  </div>

  <div class="horizontal-bar-chart" *ngIf="data.type === 'groups'">
    <div *ngFor="let group of filteredGroups">
      <div class="title" [style]="{width: '30% !important'}" >{{ group.name }}</div>
      <div class="bar">
        <div [style.width.%]="(+group.activeRemarksCount / maxTopGroupsCount) * 100">{{ +group.activeRemarksCount }}</div>
      </div>
    </div>
  </div>

  <div class="horizontal-bar-chart" *ngIf="data.type === 'subgroups'">
    <div *ngFor="let subgroup of filteredSubgroups">
      <div class="title" [style]="{width: '30% !important'}" >{{ subgroup.name }}</div>
      <div class="bar">
        <div [style.width.%]="(+subgroup.activeRemarksCount / maxTopSubgroupsCount) * 100">{{ +subgroup.activeRemarksCount }}</div>
      </div>
    </div>
  </div>

  <div class="horizontal-bar-chart" *ngIf="data.type === 'facilityTypes'">
    <div *ngFor="let ft of filteredFacilityTypes">
      <div class="title" [style]="{width: '30% !important'}" >{{ ft.name }}</div>
      <div class="bar">
        <div [style.width.%]="(+ft.remarkCount / maxTopFacilityTypesCount) * 100">{{ +ft.remarkCount }}</div>
      </div>
    </div>
  </div>


</mat-dialog-content>
