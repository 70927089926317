import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {CapitalizePipe} from '../../../core/pipes/capitalize.pipe';
import {MatTooltip} from '@angular/material/tooltip';
import {ActivatedRoute, RouterLink, UrlSegment} from '@angular/router';
import {TypeOfEstimation} from '../side-bar/type-of-estimation';
import {NgClass} from '@angular/common';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {UserService} from '../../../core/services/user.service';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {AccessService, guideKeys} from '../../../core/services/access.service';
import {MatDialog} from '@angular/material/dialog';
import {AccountComponent} from './account/account.component';

@Component({
  selector: 'ostso-side-bar-double',
  standalone: true,
  imports: [
    CapitalizePipe,
    MatTooltip,
    RouterLink,
    NgClass,
    MatIcon,
    MatIconButton,
    MatProgressSpinner
  ],
  templateUrl: './side-bar-double.component.html',
  styleUrl: './side-bar-double.component.scss'
})
export class SideBarDoubleComponent implements OnInit{
  _type: RegistrType = 'remarktype';
  @Input() titleCreate: string = 'Создать заявку';
  @Output() toggle = new EventEmitter();
  @Output() clickOutside = new EventEmitter();
  @Input() opened = false;
  @Input() set type(value: string) {
    const mapSet: Record<string, UserRightsKeys> = {
      group: 'rategroup',
      subgroup: 'ratesubgroup',
      kind: 'ratekind',
      remarktype: 'remarktype',
    } as const;
    this._type = mapSet[value];
  }

  @HostListener('document:click', ['$event']) docClick(event: MouseEvent):void {
      const isSelfTarget = (event.target as HTMLElement).closest('mat-sidenav');
      if (isSelfTarget) return;
      this.clickOutside.emit();
  }

  isGrade = false; //для скрытия кнопки "создать" на grade компоненте
  isNewEnabled  = false; //для скрытия кнопки в зависимости от прав доступа

  constructor(
    public typeOfEstimation: TypeOfEstimation,
    public user: UserService,
    public route: ActivatedRoute,
    public accessService: AccessService,
    private dialog: MatDialog,
  ) {
      this.route.url.subscribe((url: UrlSegment[])  =>  {
        if((['group','subgroup','kind','remarktype','ratecard']).includes(url[0].path)){
          this.accessService.newButton.subscribe(v=>{
            this.isNewEnabled = v[url[0].path as guideKeys];
          });
        }
        console.log({url: url[0].path});
      });
  }

  toggleEmit(): void {
    this.toggle.emit();
  }

  ngOnInit(): void  {
    !this.user.value.lastName && !this.user.value.firstName  && this.user.fetchUser();
    this.route.url.subscribe(url => {
      this.isGrade  =  url[0].path.includes('grade');
    });
  }


  openAccount(value: User):void {
    this.dialog.open(AccountComponent, {
      width: '35vw',
      height: '100svh',
      position: {right: '0px'},
      data: value
    });
  }
}
