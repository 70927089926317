import {EventEmitter, Injectable, Output, signal} from '@angular/core';
import ExcelJS from 'exceljs';
import {Cell} from 'exceljs';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  executing = signal(false);
  export(table: HTMLTableElement, title: string): void {

    this.executing.set(true);

    if (!table) {
      console.error('Таблица не найдена!');
      return;
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(title);

    // Экспорт заголовков (thead)
    const headerRow = table.querySelector('thead tr');
    if (headerRow) {
      const headers = Array.from((headerRow as HTMLTableRowElement).cells).map(cell => cell.textContent || '');
      worksheet.addRow(headers);
    }

    // Экспорт строк (tbody)
    const rowsRaw: HTMLTableRowElement[] = Array.from(table.querySelectorAll('tbody tr'));
    const rows = rowsRaw.map((row: HTMLTableRowElement) => {
      const cells = Array.from((row as HTMLTableRowElement).cells);
      return cells.map(cell => cell.textContent || '');
    });
    worksheet.addRows(rows);


    rowsRaw.forEach((row: HTMLTableRowElement, rowIndex: number) => {
      Array.from(row.cells).forEach((cell: HTMLTableCellElement, cellIndex: number) => {
        const style = window.getComputedStyle(cell);
        const excelCell: Cell = worksheet.getCell(rowIndex+2, cellIndex+1);
        const rgbColor = style.color.startsWith('rgb') ? this.rgbToColor(style.color) : this.hexToRgb(style.color);
        rgbColor && (excelCell.font = { color: { argb: rgbColor }});
        excelCell.alignment = { vertical: 'middle', horizontal: 'left' };
      });
    });

    //--- merge last row ---
    const colspan = rowsRaw[rowsRaw.length-1].cells[0].colSpan;
    console.log('colspan',colspan);
    const nullArray : null[] = Array.from({length:+colspan-1}, ()=>null);
    colspan > 1 && worksheet.getRow(worksheet.rowCount).splice(2,0,...nullArray);
    colspan > 1 && worksheet.mergeCells(worksheet.rowCount,1,worksheet.rowCount,colspan);
    const lastRow = worksheet.getRow(worksheet.rowCount);
    lastRow.height = 14;



    // Пример стилизации заголовков
    worksheet.getRow(1).font = {bold: true, color: {argb: 'FFFFFFFF'}};
    worksheet.getRow(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'FFFF0000'}
    };

    // Установка ширины столбцов на основе максимальной длины содержимого
    this.setColumnWidths(worksheet);

    //--- remove last column ---
    worksheet.spliceColumns(worksheet.columnCount,3);

    // Сохранение файла
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = title + '.xlsx';
      a.click();
      window.URL.revokeObjectURL(url);
      this.executing.set(false);
    });
  }

  // Функция для преобразования цвета в RGB (если это необходимо)
  hexToRgb(hex: string): string | null {
    const hexRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
    const result = hexRegex.exec(hex);
    if (!result) return null;

    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);

    // Возвращаем цвет в формате "RRGGBB"
    return `FF${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  }

  rgbToColor(rgb: string): string | null {
    const c = rgb.match(/(\d+)/g);
    if (!c) return null;
    const r = parseInt(c[0]).toString(16).padStart(2, '0');
    const g = parseInt(c[1]).toString(16).padStart(2, '0');
    const b = parseInt(c[2]).toString(16).padStart(2, '0');
    return `FF${r}${g}${b}`;
  }

  // Функция для автоматической установки ширины столбцов на основе максимальной длины данных
  setColumnWidths(worksheet: ExcelJS.Worksheet): void {
    const columnWidths: number[] = [];

    // Пройти по всем столбцам и найти максимальную длину текста
    worksheet.eachRow({includeEmpty: true}, (row, rowIndex) => {
      row.eachCell({includeEmpty: true}, (cell, colIndex) => {
        const cellValue = cell.value?.toString() || '';
        const columnWidth = cellValue.length;

        // Обновить максимальную ширину для текущего столбца
        if (!columnWidths[colIndex] || columnWidths[colIndex] < columnWidth) {
          columnWidths[colIndex] = columnWidth;
        }
      });
    });

    // Установить ширину столбцов
    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index).width = width + 2; // Добавляем небольшое пространство для удобства
    });
  }
}
