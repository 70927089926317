<div class="by-objects-dashboard-widget">
  <h4>Активные замечания по типам объектов
    <span (click)="showMoreInfo('facilityTypes')">Подробнее</span>
  </h4>
  <div *ngFor="let item of byFacilitytype()" class="horizontal-bar-chart">
    <div>
      <div class="title">{{item.name}}</div>
      <div class="bar"><div [style.width.%]="(item.remarkCount / maxByFacilitytypeCount()) * 100">{{item.remarkCount}}</div></div>
    </div>
  </div>
</div>
