/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';


// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface ApiDashboardActiveremarkByFacilitytypeGetRequestParams {
  iasId?: Array<number>;
  firmIds?: Array<number>;
  facilityIds?: Array<number>;
  egipRegistryNumber?: Array<number>;
  facilityAddresses?: string;
  remarkGroupIds?: Array<number>;
  remarkSubGroupIds?: Array<number>;
  facilityTypeIds?: Array<number>;
  remarkPriorityIds?: Array<number>;
  isInvestmentObject?: boolean;
  isLongspanStructureObject?: boolean;
  isOverdueRemark?: boolean;
  includeArchived?: boolean;
  dateFrom?: string;
  dateTo?: string;
  groupByDay?: boolean;
  groupByMonth?: boolean;
  groupByYear?: boolean;
  pageIndex?: number;
  pageSize?: number;
  createAt?: string;
  finishedDate?: string;
  IsOverdueRemark?: boolean|null;
}

export interface ApiDashboardActiveremarkByGroupGetRequestParams extends ApiDashboardActiveremarkByFacilitytypeGetRequestParams{

}

export interface ApiDashboardActiveremarkByRatepriorityGetRequestParams extends ApiDashboardActiveremarkByFacilitytypeGetRequestParams{
  firmIds?: Array<number>;
  facilityIds?: Array<number>;
  facilityAddress?: string;
  facilityTypeIds?: Array<number>;
  inspectorIds?: Array<number>;
  operatorIds?: Array<number>;
  dateFrom?: string;
  dateTo?: string;
  ids?: Array<number>;
  rateGroupIds?: Array<number>;
  rateSubGroupIds?: Array<number>;
  rateKindIds?: Array<number>;
  remarkTypeIds?: Array<number>;
  daysInWorkFrom?: number;
  daysInWorkTo?: number;
  priorityIds?: Array<number>;
  statusesIds?: Array<number>;
  isInvestmentObject?: boolean;
  isLongspanStructureObject?: boolean;
  statusId?: number;
  searchString?: string;
  sortColumnName?: string;
  sortAscending?: boolean;
  pageIndex?: number;
  pageSize?: number;

}

export interface ApiDashboardActiveremarkBySubgroupGetRequestParams extends ApiDashboardActiveremarkByFacilitytypeGetRequestParams{

}

export interface ApiDashboardActiveremarkByperiodGetRequestParams extends ApiDashboardActiveremarkByFacilitytypeGetRequestParams{
}

export interface ApiDashboardActiveremarkTop5FirmAllGetRequestParams extends ApiDashboardActiveremarkByFacilitytypeGetRequestParams{
}

export interface ApiDashboardActiveremarkTop5facilityGetRequestParams extends ApiDashboardActiveremarkByFacilitytypeGetRequestParams{
}

export interface ApiDashboardActiveremarkTopFirmAllGetRequestParams extends ApiDashboardActiveremarkByFacilitytypeGetRequestParams{
}

export interface ApiDashboardActiveremarkTopfacilityGetRequestParams extends ApiDashboardActiveremarkTopFirmAllGetRequestParams {

}

export interface ApiDashboardFacilityfirmCountGetRequestParams extends ApiDashboardActiveremarkTopfacilityGetRequestParams {
}

export interface ApiDashboardRatingFacilityByRegistryNumberRegistrynumberGetRequestParams {
    registrynumber: number;
}

export interface ApiDashboardRatingFacilityIdGetRequestParams {
    id: number;
}

export interface ApiDashboardRemarkStatisticsGetRequestParams extends ApiDashboardFacilityfirmCountGetRequestParams {}


@Injectable({
  providedIn: 'root'
})
export class DashboardService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

  /**
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiDashboardActiveremarkAllBySubgroupGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
  public apiDashboardActiveremarkAllBySubgroupGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
  public apiDashboardActiveremarkAllBySubgroupGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
  public apiDashboardActiveremarkAllBySubgroupGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/api/Dashboard/activeremark/all_by_subgroup`;
    return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiDashboardActiveremarkAllGroupGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
  public apiDashboardActiveremarkAllGroupGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
  public apiDashboardActiveremarkAllGroupGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
  public apiDashboardActiveremarkAllGroupGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/api/Dashboard/activeremark/all_group`;
    return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiDashboardActiveremarkByAllFacilitytypeGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
  public apiDashboardActiveremarkByAllFacilitytypeGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
  public apiDashboardActiveremarkByAllFacilitytypeGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
  public apiDashboardActiveremarkByAllFacilitytypeGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/api/Dashboard/activeremark/by-allFacilitytype`;
    return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiDashboardActiveremarkByFacilitytypeGet(requestParameters: ApiDashboardActiveremarkByFacilitytypeGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
  public apiDashboardActiveremarkByFacilitytypeGet(requestParameters: ApiDashboardActiveremarkByFacilitytypeGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
  public apiDashboardActiveremarkByFacilitytypeGet(requestParameters: ApiDashboardActiveremarkByFacilitytypeGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
  public apiDashboardActiveremarkByFacilitytypeGet(requestParameters: ApiDashboardActiveremarkByFacilitytypeGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
    const iasId = requestParameters.iasId;
    const firmIds = requestParameters.firmIds;
    const facilityIds = requestParameters.facilityIds;
    const egipRegistryNumber = requestParameters.egipRegistryNumber;
    const facilityAddresses = requestParameters.facilityAddresses;
    const remarkGroupIds = requestParameters.remarkGroupIds;
    const remarkSubGroupIds = requestParameters.remarkSubGroupIds;
    const facilityTypeIds = requestParameters.facilityTypeIds;
    const remarkPriorityIds = requestParameters.remarkPriorityIds;
    const isInvestmentObject = requestParameters.isInvestmentObject;
    const isLongspanStructureObject = requestParameters.isLongspanStructureObject;
    const isOverdueRemark = requestParameters.isOverdueRemark;
    const includeArchived = requestParameters.includeArchived;
    const dateFrom = requestParameters.dateFrom;
    const dateTo = requestParameters.dateTo;
    const groupByDay = requestParameters.groupByDay;
    const groupByMonth = requestParameters.groupByMonth;
    const groupByYear = requestParameters.groupByYear;
    const pageIndex = requestParameters.pageIndex;
    const pageSize = requestParameters.pageSize;

    let localVarQueryParameters = new HttpParams({encoder: this.encoder});
    if (iasId) {
      iasId.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'IasId');
      })
    }
    if (firmIds) {
      firmIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FirmIds');
      })
    }
    if (facilityIds) {
      facilityIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FacilityIds');
      })
    }
    if (egipRegistryNumber) {
      egipRegistryNumber.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'EgipRegistryNumber');
      })
    }
    if (facilityAddresses !== undefined && facilityAddresses !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>facilityAddresses, 'FacilityAddresses');
    }
    if (remarkGroupIds) {
      remarkGroupIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RemarkGroupIds');
      })
    }
    if (remarkSubGroupIds) {
      remarkSubGroupIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RemarkSubGroupIds');
      })
    }
    if (facilityTypeIds) {
      facilityTypeIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FacilityTypeIds');
      })
    }
    if (remarkPriorityIds) {
      remarkPriorityIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RemarkPriorityIds');
      })
    }
    if (isInvestmentObject !== undefined && isInvestmentObject !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isInvestmentObject, 'IsInvestmentObject');
    }
    if (isLongspanStructureObject !== undefined && isLongspanStructureObject !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isLongspanStructureObject, 'IsLongspanStructureObject');
    }
    if (isOverdueRemark !== undefined && isOverdueRemark !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isOverdueRemark, 'IsOverdueRemark');
    }
    if (includeArchived !== undefined && includeArchived !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>includeArchived, 'IncludeArchived');
    }
    if (dateFrom !== undefined && dateFrom !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>dateFrom, 'DateFrom');
    }
    if (dateTo !== undefined && dateTo !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>dateTo, 'DateTo');
    }
    if (groupByDay !== undefined && groupByDay !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>groupByDay, 'GroupByDay');
    }
    if (groupByMonth !== undefined && groupByMonth !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>groupByMonth, 'GroupByMonth');
    }
    if (groupByYear !== undefined && groupByYear !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>groupByYear, 'GroupByYear');
    }
    if (pageIndex !== undefined && pageIndex !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>pageIndex, 'PageIndex');
    }
    if (pageSize !== undefined && pageSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>pageSize, 'PageSize');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/api/Dashboard/activeremark/by-facilitytype`;
    return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiDashboardActiveremarkByGroupGet(requestParameters: ApiDashboardActiveremarkByGroupGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
  public apiDashboardActiveremarkByGroupGet(requestParameters: ApiDashboardActiveremarkByGroupGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
  public apiDashboardActiveremarkByGroupGet(requestParameters: ApiDashboardActiveremarkByGroupGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
  public apiDashboardActiveremarkByGroupGet(requestParameters: ApiDashboardActiveremarkByGroupGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
    const iasId = requestParameters.iasId;
    const firmIds = requestParameters.firmIds;
    const facilityIds = requestParameters.facilityIds;
    const egipRegistryNumber = requestParameters.egipRegistryNumber;
    const facilityAddresses = requestParameters.facilityAddresses;
    const remarkGroupIds = requestParameters.remarkGroupIds;
    const remarkSubGroupIds = requestParameters.remarkSubGroupIds;
    const facilityTypeIds = requestParameters.facilityTypeIds;
    const remarkPriorityIds = requestParameters.remarkPriorityIds;
    const isInvestmentObject = requestParameters.isInvestmentObject;
    const isLongspanStructureObject = requestParameters.isLongspanStructureObject;
    const isOverdueRemark = requestParameters.isOverdueRemark;
    const includeArchived = requestParameters.includeArchived;
    const dateFrom = requestParameters.dateFrom;
    const dateTo = requestParameters.dateTo;
    const groupByDay = requestParameters.groupByDay;
    const groupByMonth = requestParameters.groupByMonth;
    const groupByYear = requestParameters.groupByYear;
    const pageIndex = requestParameters.pageIndex;
    const pageSize = requestParameters.pageSize;

    let localVarQueryParameters = new HttpParams({encoder: this.encoder});
    if (iasId) {
      iasId.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'IasId');
      })
    }
    if (firmIds) {
      firmIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FirmIds');
      })
    }
    if (facilityIds) {
      facilityIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FacilityIds');
      })
    }
    if (egipRegistryNumber) {
      egipRegistryNumber.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'EgipRegistryNumber');
      })
    }
    if (facilityAddresses !== undefined && facilityAddresses !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>facilityAddresses, 'FacilityAddresses');
    }
    if (remarkGroupIds) {
      remarkGroupIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RemarkGroupIds');
      })
    }
    if (remarkSubGroupIds) {
      remarkSubGroupIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RemarkSubGroupIds');
      })
    }
    if (facilityTypeIds) {
      facilityTypeIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FacilityTypeIds');
      })
    }
    if (remarkPriorityIds) {
      remarkPriorityIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RemarkPriorityIds');
      })
    }
    if (isInvestmentObject !== undefined && isInvestmentObject !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isInvestmentObject, 'IsInvestmentObject');
    }
    if (isLongspanStructureObject !== undefined && isLongspanStructureObject !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isLongspanStructureObject, 'IsLongspanStructureObject');
    }
    if (isOverdueRemark !== undefined && isOverdueRemark !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isOverdueRemark, 'IsOverdueRemark');
    }
    if (includeArchived !== undefined && includeArchived !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>includeArchived, 'IncludeArchived');
    }
    if (dateFrom !== undefined && dateFrom !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>dateFrom, 'DateFrom');
    }
    if (dateTo !== undefined && dateTo !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>dateTo, 'DateTo');
    }
    if (groupByDay !== undefined && groupByDay !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>groupByDay, 'GroupByDay');
    }
    if (groupByMonth !== undefined && groupByMonth !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>groupByMonth, 'GroupByMonth');
    }
    if (groupByYear !== undefined && groupByYear !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>groupByYear, 'GroupByYear');
    }
    if (pageIndex !== undefined && pageIndex !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>pageIndex, 'PageIndex');
    }
    if (pageSize !== undefined && pageSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>pageSize, 'PageSize');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/api/Dashboard/activeremark/by-group`;
    return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiDashboardActiveremarkByRatepriorityGet(requestParameters: ApiDashboardActiveremarkByRatepriorityGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
  public apiDashboardActiveremarkByRatepriorityGet(requestParameters: ApiDashboardActiveremarkByRatepriorityGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
  public apiDashboardActiveremarkByRatepriorityGet(requestParameters: ApiDashboardActiveremarkByRatepriorityGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
  public apiDashboardActiveremarkByRatepriorityGet(requestParameters: ApiDashboardActiveremarkByRatepriorityGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
    const firmIds = requestParameters.firmIds;
    const facilityIds = requestParameters.facilityIds;
    const facilityAddress = requestParameters.facilityAddresses;
    const facilityTypeIds = requestParameters.facilityTypeIds;
    const inspectorIds = requestParameters.inspectorIds;
    const operatorIds = requestParameters.operatorIds;
    const dateFrom = requestParameters.dateFrom;
    const dateTo = requestParameters.dateTo;
    const ids = requestParameters.ids;
    const rateGroupIds = requestParameters.rateGroupIds;
    const rateSubGroupIds = requestParameters.rateSubGroupIds;
    const rateKindIds = requestParameters.rateKindIds;
    const remarkTypeIds = requestParameters.remarkTypeIds;
    const daysInWorkFrom = requestParameters.daysInWorkFrom;
    const daysInWorkTo = requestParameters.daysInWorkTo;
    const priorityIds = requestParameters.priorityIds;
    const statusesIds = requestParameters.statusesIds;
    const isInvestmentObject = requestParameters.isInvestmentObject;
    const isLongspanStructureObject = requestParameters.isLongspanStructureObject;
    const statusId = requestParameters.statusId;
    const searchString = requestParameters.searchString;
    const sortColumnName = requestParameters.sortColumnName;
    const sortAscending = requestParameters.sortAscending;
    const pageIndex = requestParameters.pageIndex;
    const pageSize = requestParameters.pageSize;

    let localVarQueryParameters = new HttpParams({encoder: this.encoder});
    if (firmIds) {
      firmIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FirmIds');
      })
    }
    if (facilityIds) {
      facilityIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FacilityIds');
      })
    }
    if (facilityAddress !== undefined && facilityAddress !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>facilityAddress, 'FacilityAddress');
    }
    if (facilityTypeIds) {
      facilityTypeIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FacilityTypeIds');
      })
    }
    if (inspectorIds) {
      inspectorIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'InspectorIds');
      })
    }
    if (operatorIds) {
      operatorIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'OperatorIds');
      })
    }
    if (dateFrom !== undefined && dateFrom !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>dateFrom, 'DateFrom');
    }
    if (dateTo !== undefined && dateTo !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>dateTo, 'DateTo');
    }
    if (ids) {
      ids.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'Ids');
      })
    }
    if (rateGroupIds) {
      rateGroupIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RateGroupIds');
      })
    }
    if (rateSubGroupIds) {
      rateSubGroupIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RateSubGroupIds');
      })
    }
    if (rateKindIds) {
      rateKindIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RateKindIds');
      })
    }
    if (remarkTypeIds) {
      remarkTypeIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RemarkTypeIds');
      })
    }
    if (daysInWorkFrom !== undefined && daysInWorkFrom !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>daysInWorkFrom, 'DaysInWorkFrom');
    }
    if (daysInWorkTo !== undefined && daysInWorkTo !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>daysInWorkTo, 'DaysInWorkTo');
    }
    if (priorityIds) {
      priorityIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'PriorityIds');
      })
    }
    if (statusesIds) {
      statusesIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'StatusesIds');
      })
    }
    if (isInvestmentObject !== undefined && isInvestmentObject !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isInvestmentObject, 'IsInvestmentObject');
    }
    if (isLongspanStructureObject !== undefined && isLongspanStructureObject !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isLongspanStructureObject, 'IsLongspanStructureObject');
    }
    if (statusId !== undefined && statusId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>statusId, 'StatusId');
    }
    if (searchString !== undefined && searchString !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>searchString, 'SearchString');
    }
    if (sortColumnName !== undefined && sortColumnName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>sortColumnName, 'Sort.ColumnName');
    }
    if (sortAscending !== undefined && sortAscending !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>sortAscending, 'Sort.Ascending');
    }
    if (pageIndex !== undefined && pageIndex !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>pageIndex, 'PageIndex');
    }
    if (pageSize !== undefined && pageSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>pageSize, 'PageSize');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/api/Dashboard/activeremark/by-ratepriority`;
    return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiDashboardActiveremarkBySubgroupGet(requestParameters: ApiDashboardActiveremarkBySubgroupGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
  public apiDashboardActiveremarkBySubgroupGet(requestParameters: ApiDashboardActiveremarkBySubgroupGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
  public apiDashboardActiveremarkBySubgroupGet(requestParameters: ApiDashboardActiveremarkBySubgroupGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
  public apiDashboardActiveremarkBySubgroupGet(requestParameters: ApiDashboardActiveremarkBySubgroupGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
    const iasId = requestParameters.iasId;
    const firmIds = requestParameters.firmIds;
    const facilityIds = requestParameters.facilityIds;
    const egipRegistryNumber = requestParameters.egipRegistryNumber;
    const facilityAddresses = requestParameters.facilityAddresses;
    const remarkGroupIds = requestParameters.remarkGroupIds;
    const remarkSubGroupIds = requestParameters.remarkSubGroupIds;
    const facilityTypeIds = requestParameters.facilityTypeIds;
    const remarkPriorityIds = requestParameters.remarkPriorityIds;
    const isInvestmentObject = requestParameters.isInvestmentObject;
    const isLongspanStructureObject = requestParameters.isLongspanStructureObject;
    const isOverdueRemark = requestParameters.isOverdueRemark;
    const includeArchived = requestParameters.includeArchived;
    const dateFrom = requestParameters.dateFrom;
    const dateTo = requestParameters.dateTo;
    const groupByDay = requestParameters.groupByDay;
    const groupByMonth = requestParameters.groupByMonth;
    const groupByYear = requestParameters.groupByYear;
    const pageIndex = requestParameters.pageIndex;
    const pageSize = requestParameters.pageSize;

    let localVarQueryParameters = new HttpParams({encoder: this.encoder});
    if (iasId) {
      iasId.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'IasId');
      })
    }
    if (firmIds) {
      firmIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FirmIds');
      })
    }
    if (facilityIds) {
      facilityIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FacilityIds');
      })
    }
    if (egipRegistryNumber) {
      egipRegistryNumber.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'EgipRegistryNumber');
      })
    }
    if (facilityAddresses !== undefined && facilityAddresses !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>facilityAddresses, 'FacilityAddresses');
    }
    if (remarkGroupIds) {
      remarkGroupIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RemarkGroupIds');
      })
    }
    if (remarkSubGroupIds) {
      remarkSubGroupIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RemarkSubGroupIds');
      })
    }
    if (facilityTypeIds) {
      facilityTypeIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FacilityTypeIds');
      })
    }
    if (remarkPriorityIds) {
      remarkPriorityIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RemarkPriorityIds');
      })
    }
    if (isInvestmentObject !== undefined && isInvestmentObject !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isInvestmentObject, 'IsInvestmentObject');
    }
    if (isLongspanStructureObject !== undefined && isLongspanStructureObject !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isLongspanStructureObject, 'IsLongspanStructureObject');
    }
    if (isOverdueRemark !== undefined && isOverdueRemark !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isOverdueRemark, 'IsOverdueRemark');
    }
    if (includeArchived !== undefined && includeArchived !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>includeArchived, 'IncludeArchived');
    }
    if (dateFrom !== undefined && dateFrom !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>dateFrom, 'DateFrom');
    }
    if (dateTo !== undefined && dateTo !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>dateTo, 'DateTo');
    }
    if (groupByDay !== undefined && groupByDay !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>groupByDay, 'GroupByDay');
    }
    if (groupByMonth !== undefined && groupByMonth !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>groupByMonth, 'GroupByMonth');
    }
    if (groupByYear !== undefined && groupByYear !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>groupByYear, 'GroupByYear');
    }
    if (pageIndex !== undefined && pageIndex !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>pageIndex, 'PageIndex');
    }
    if (pageSize !== undefined && pageSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>pageSize, 'PageSize');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/api/Dashboard/activeremark/by-subgroup`;
    return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiDashboardActiveremarkByperiodGet(requestParameters: ApiDashboardActiveremarkByperiodGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
  public apiDashboardActiveremarkByperiodGet(requestParameters: ApiDashboardActiveremarkByperiodGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
  public apiDashboardActiveremarkByperiodGet(requestParameters: ApiDashboardActiveremarkByperiodGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
  public apiDashboardActiveremarkByperiodGet(requestParameters: ApiDashboardActiveremarkByperiodGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
    const iasId = requestParameters.iasId;
    const firmIds = requestParameters.firmIds;
    const facilityIds = requestParameters.facilityIds;
    const egipRegistryNumber = requestParameters.egipRegistryNumber;
    const facilityAddresses = requestParameters.facilityAddresses;
    const remarkGroupIds = requestParameters.remarkGroupIds;
    const remarkSubGroupIds = requestParameters.remarkSubGroupIds;
    const facilityTypeIds = requestParameters.facilityTypeIds;
    const remarkPriorityIds = requestParameters.remarkPriorityIds;
    const isInvestmentObject = requestParameters.isInvestmentObject;
    const isLongspanStructureObject = requestParameters.isLongspanStructureObject;
    const isOverdueRemark = requestParameters.isOverdueRemark;
    const includeArchived = requestParameters.includeArchived;
    const dateFrom = requestParameters.dateFrom;
    const dateTo = requestParameters.dateTo;
    const groupByDay = requestParameters.groupByDay;
    const groupByMonth = requestParameters.groupByMonth;
    const groupByYear = requestParameters.groupByYear;
    const pageIndex = requestParameters.pageIndex;
    const pageSize = requestParameters.pageSize;

    let localVarQueryParameters = new HttpParams({encoder: this.encoder});
    if (iasId) {
      iasId.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'IasId');
      })
    }
    if (firmIds) {
      firmIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FirmIds');
      })
    }
    if (facilityIds) {
      facilityIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FacilityIds');
      })
    }
    if (egipRegistryNumber) {
      egipRegistryNumber.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'EgipRegistryNumber');
      })
    }
    if (facilityAddresses !== undefined && facilityAddresses !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>facilityAddresses, 'FacilityAddresses');
    }
    if (remarkGroupIds) {
      remarkGroupIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RemarkGroupIds');
      })
    }
    if (remarkSubGroupIds) {
      remarkSubGroupIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RemarkSubGroupIds');
      })
    }
    if (facilityTypeIds) {
      facilityTypeIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FacilityTypeIds');
      })
    }
    if (remarkPriorityIds) {
      remarkPriorityIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RemarkPriorityIds');
      })
    }
    if (isInvestmentObject !== undefined && isInvestmentObject !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isInvestmentObject, 'IsInvestmentObject');
    }
    if (isLongspanStructureObject !== undefined && isLongspanStructureObject !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isLongspanStructureObject, 'IsLongspanStructureObject');
    }
    if (isOverdueRemark !== undefined && isOverdueRemark !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isOverdueRemark, 'IsOverdueRemark');
    }
    if (includeArchived !== undefined && includeArchived !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>includeArchived, 'IncludeArchived');
    }
    if (dateFrom !== undefined && dateFrom !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>dateFrom, 'DateFrom');
    }
    if (dateTo !== undefined && dateTo !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>dateTo, 'DateTo');
    }
    if (groupByDay !== undefined && groupByDay !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>groupByDay, 'GroupByDay');
    }
    if (groupByMonth !== undefined && groupByMonth !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>groupByMonth, 'GroupByMonth');
    }
    if (groupByYear !== undefined && groupByYear !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>groupByYear, 'GroupByYear');
    }
    if (pageIndex !== undefined && pageIndex !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>pageIndex, 'PageIndex');
    }
    if (pageSize !== undefined && pageSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>pageSize, 'PageSize');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/api/Dashboard/activeremark/byperiod`;
    return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiDashboardActiveremarkTop5FirmAllGet(requestParameters: ApiDashboardActiveremarkTop5FirmAllGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
  public apiDashboardActiveremarkTop5FirmAllGet(requestParameters: ApiDashboardActiveremarkTop5FirmAllGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
  public apiDashboardActiveremarkTop5FirmAllGet(requestParameters: ApiDashboardActiveremarkTop5FirmAllGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
  public apiDashboardActiveremarkTop5FirmAllGet(requestParameters: ApiDashboardActiveremarkTop5FirmAllGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
    const iasId = requestParameters.iasId;
    const firmIds = requestParameters.firmIds;
    const facilityIds = requestParameters.facilityIds;
    const egipRegistryNumber = requestParameters.egipRegistryNumber;
    const facilityAddresses = requestParameters.facilityAddresses;
    const remarkGroupIds = requestParameters.remarkGroupIds;
    const remarkSubGroupIds = requestParameters.remarkSubGroupIds;
    const facilityTypeIds = requestParameters.facilityTypeIds;
    const remarkPriorityIds = requestParameters.remarkPriorityIds;
    const isInvestmentObject = requestParameters.isInvestmentObject;
    const isLongspanStructureObject = requestParameters.isLongspanStructureObject;
    const isOverdueRemark = requestParameters.isOverdueRemark;
    const includeArchived = requestParameters.includeArchived;
    const dateFrom = requestParameters.dateFrom;
    const dateTo = requestParameters.dateTo;
    const groupByDay = requestParameters.groupByDay;
    const groupByMonth = requestParameters.groupByMonth;
    const groupByYear = requestParameters.groupByYear;
    const pageIndex = requestParameters.pageIndex;
    const pageSize = requestParameters.pageSize;

    let localVarQueryParameters = new HttpParams({encoder: this.encoder});
    if (iasId) {
      iasId.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'IasId');
      })
    }
    if (firmIds) {
      firmIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FirmIds');
      })
    }
    if (facilityIds) {
      facilityIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FacilityIds');
      })
    }
    if (egipRegistryNumber) {
      egipRegistryNumber.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'EgipRegistryNumber');
      })
    }
    if (facilityAddresses !== undefined && facilityAddresses !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>facilityAddresses, 'FacilityAddresses');
    }
    if (remarkGroupIds) {
      remarkGroupIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RemarkGroupIds');
      })
    }
    if (remarkSubGroupIds) {
      remarkSubGroupIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RemarkSubGroupIds');
      })
    }
    if (facilityTypeIds) {
      facilityTypeIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FacilityTypeIds');
      })
    }
    if (remarkPriorityIds) {
      remarkPriorityIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RemarkPriorityIds');
      })
    }
    if (isInvestmentObject !== undefined && isInvestmentObject !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isInvestmentObject, 'IsInvestmentObject');
    }
    if (isLongspanStructureObject !== undefined && isLongspanStructureObject !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isLongspanStructureObject, 'IsLongspanStructureObject');
    }
    if (isOverdueRemark !== undefined && isOverdueRemark !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isOverdueRemark, 'IsOverdueRemark');
    }
    if (includeArchived !== undefined && includeArchived !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>includeArchived, 'IncludeArchived');
    }
    if (dateFrom !== undefined && dateFrom !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>dateFrom, 'DateFrom');
    }
    if (dateTo !== undefined && dateTo !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>dateTo, 'DateTo');
    }
    if (groupByDay !== undefined && groupByDay !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>groupByDay, 'GroupByDay');
    }
    if (groupByMonth !== undefined && groupByMonth !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>groupByMonth, 'GroupByMonth');
    }
    if (groupByYear !== undefined && groupByYear !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>groupByYear, 'GroupByYear');
    }
    if (pageIndex !== undefined && pageIndex !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>pageIndex, 'PageIndex');
    }
    if (pageSize !== undefined && pageSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>pageSize, 'PageSize');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/api/Dashboard/activeremark/top5-firm-all`;
    return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiDashboardActiveremarkTop5facilityGet(requestParameters: ApiDashboardActiveremarkTop5facilityGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
  public apiDashboardActiveremarkTop5facilityGet(requestParameters: ApiDashboardActiveremarkTop5facilityGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
  public apiDashboardActiveremarkTop5facilityGet(requestParameters: ApiDashboardActiveremarkTop5facilityGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
  public apiDashboardActiveremarkTop5facilityGet(requestParameters: ApiDashboardActiveremarkTop5facilityGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
    const iasId = requestParameters.iasId;
    const firmIds = requestParameters.firmIds;
    const facilityIds = requestParameters.facilityIds;
    const egipRegistryNumber = requestParameters.egipRegistryNumber;
    const facilityAddresses = requestParameters.facilityAddresses;
    const remarkGroupIds = requestParameters.remarkGroupIds;
    const remarkSubGroupIds = requestParameters.remarkSubGroupIds;
    const facilityTypeIds = requestParameters.facilityTypeIds;
    const remarkPriorityIds = requestParameters.remarkPriorityIds;
    const isInvestmentObject = requestParameters.isInvestmentObject;
    const isLongspanStructureObject = requestParameters.isLongspanStructureObject;
    const isOverdueRemark = requestParameters.isOverdueRemark;
    const includeArchived = requestParameters.includeArchived;
    const dateFrom = requestParameters.dateFrom;
    const dateTo = requestParameters.dateTo;
    const groupByDay = requestParameters.groupByDay;
    const groupByMonth = requestParameters.groupByMonth;
    const groupByYear = requestParameters.groupByYear;
    const pageIndex = requestParameters.pageIndex;
    const pageSize = requestParameters.pageSize;

    let localVarQueryParameters = new HttpParams({encoder: this.encoder});
    if (iasId) {
      iasId.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'IasId');
      })
    }
    if (firmIds) {
      firmIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FirmIds');
      })
    }
    if (facilityIds) {
      facilityIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FacilityIds');
      })
    }
    if (egipRegistryNumber) {
      egipRegistryNumber.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'EgipRegistryNumber');
      })
    }
    if (facilityAddresses !== undefined && facilityAddresses !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>facilityAddresses, 'FacilityAddresses');
    }
    if (remarkGroupIds) {
      remarkGroupIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RemarkGroupIds');
      })
    }
    if (remarkSubGroupIds) {
      remarkSubGroupIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RemarkSubGroupIds');
      })
    }
    if (facilityTypeIds) {
      facilityTypeIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FacilityTypeIds');
      })
    }
    if (remarkPriorityIds) {
      remarkPriorityIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RemarkPriorityIds');
      })
    }
    if (isInvestmentObject !== undefined && isInvestmentObject !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isInvestmentObject, 'IsInvestmentObject');
    }
    if (isLongspanStructureObject !== undefined && isLongspanStructureObject !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isLongspanStructureObject, 'IsLongspanStructureObject');
    }
    if (isOverdueRemark !== undefined && isOverdueRemark !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isOverdueRemark, 'IsOverdueRemark');
    }
    if (includeArchived !== undefined && includeArchived !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>includeArchived, 'IncludeArchived');
    }
    if (dateFrom !== undefined && dateFrom !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>dateFrom, 'DateFrom');
    }
    if (dateTo !== undefined && dateTo !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>dateTo, 'DateTo');
    }
    if (groupByDay !== undefined && groupByDay !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>groupByDay, 'GroupByDay');
    }
    if (groupByMonth !== undefined && groupByMonth !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>groupByMonth, 'GroupByMonth');
    }
    if (groupByYear !== undefined && groupByYear !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>groupByYear, 'GroupByYear');
    }
    if (pageIndex !== undefined && pageIndex !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>pageIndex, 'PageIndex');
    }
    if (pageSize !== undefined && pageSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>pageSize, 'PageSize');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/api/Dashboard/activeremark/top5facility`;
    return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiDashboardActiveremarkTopFirmAllGet(requestParameters: ApiDashboardActiveremarkTopFirmAllGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
  public apiDashboardActiveremarkTopFirmAllGet(requestParameters: ApiDashboardActiveremarkTopFirmAllGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
  public apiDashboardActiveremarkTopFirmAllGet(requestParameters: ApiDashboardActiveremarkTopFirmAllGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
  public apiDashboardActiveremarkTopFirmAllGet(requestParameters: ApiDashboardActiveremarkTopFirmAllGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
    const iasId = requestParameters.iasId;
    const firmIds = requestParameters.firmIds;
    const facilityIds = requestParameters.facilityIds;
    const egipRegistryNumber = requestParameters.egipRegistryNumber;
    const facilityAddresses = requestParameters.facilityAddresses;
    const remarkGroupIds = requestParameters.remarkGroupIds;
    const remarkSubGroupIds = requestParameters.remarkSubGroupIds;
    const facilityTypeIds = requestParameters.facilityTypeIds;
    const remarkPriorityIds = requestParameters.remarkPriorityIds;
    const isInvestmentObject = requestParameters.isInvestmentObject;
    const isLongspanStructureObject = requestParameters.isLongspanStructureObject;
    const isOverdueRemark = requestParameters.isOverdueRemark;
    const includeArchived = requestParameters.includeArchived;
    const dateFrom = requestParameters.dateFrom;
    const dateTo = requestParameters.dateTo;
    const groupByDay = requestParameters.groupByDay;
    const groupByMonth = requestParameters.groupByMonth;
    const groupByYear = requestParameters.groupByYear;
    const pageIndex = requestParameters.pageIndex;
    const pageSize = requestParameters.pageSize;

    let localVarQueryParameters = new HttpParams({encoder: this.encoder});
    if (iasId) {
      iasId.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'IasId');
      })
    }
    if (firmIds) {
      firmIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FirmIds');
      })
    }
    if (facilityIds) {
      facilityIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FacilityIds');
      })
    }
    if (egipRegistryNumber) {
      egipRegistryNumber.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'EgipRegistryNumber');
      })
    }
    if (facilityAddresses !== undefined && facilityAddresses !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>facilityAddresses, 'FacilityAddresses');
    }
    if (remarkGroupIds) {
      remarkGroupIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RemarkGroupIds');
      })
    }
    if (remarkSubGroupIds) {
      remarkSubGroupIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RemarkSubGroupIds');
      })
    }
    if (facilityTypeIds) {
      facilityTypeIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FacilityTypeIds');
      })
    }
    if (remarkPriorityIds) {
      remarkPriorityIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RemarkPriorityIds');
      })
    }
    if (isInvestmentObject !== undefined && isInvestmentObject !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isInvestmentObject, 'IsInvestmentObject');
    }
    if (isLongspanStructureObject !== undefined && isLongspanStructureObject !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isLongspanStructureObject, 'IsLongspanStructureObject');
    }
    if (isOverdueRemark !== undefined && isOverdueRemark !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isOverdueRemark, 'IsOverdueRemark');
    }
    if (includeArchived !== undefined && includeArchived !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>includeArchived, 'IncludeArchived');
    }
    if (dateFrom !== undefined && dateFrom !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>dateFrom, 'DateFrom');
    }
    if (dateTo !== undefined && dateTo !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>dateTo, 'DateTo');
    }
    if (groupByDay !== undefined && groupByDay !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>groupByDay, 'GroupByDay');
    }
    if (groupByMonth !== undefined && groupByMonth !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>groupByMonth, 'GroupByMonth');
    }
    if (groupByYear !== undefined && groupByYear !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>groupByYear, 'GroupByYear');
    }
    if (pageIndex !== undefined && pageIndex !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>pageIndex, 'PageIndex');
    }
    if (pageSize !== undefined && pageSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>pageSize, 'PageSize');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/api/Dashboard/activeremark/top-firm-all`;
    return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiDashboardActiveremarkTopfacilityGet(requestParameters: ApiDashboardActiveremarkTopfacilityGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
  public apiDashboardActiveremarkTopfacilityGet(requestParameters: ApiDashboardActiveremarkTopfacilityGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
  public apiDashboardActiveremarkTopfacilityGet(requestParameters: ApiDashboardActiveremarkTopfacilityGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
  public apiDashboardActiveremarkTopfacilityGet(requestParameters: ApiDashboardActiveremarkTopfacilityGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
    const iasId = requestParameters.iasId;
    const firmIds = requestParameters.firmIds;
    const facilityIds = requestParameters.facilityIds;
    const egipRegistryNumber = requestParameters.egipRegistryNumber;
    const facilityAddresses = requestParameters.facilityAddresses;
    const remarkGroupIds = requestParameters.remarkGroupIds;
    const remarkSubGroupIds = requestParameters.remarkSubGroupIds;
    const facilityTypeIds = requestParameters.facilityTypeIds;
    const remarkPriorityIds = requestParameters.remarkPriorityIds;
    const isInvestmentObject = requestParameters.isInvestmentObject;
    const isLongspanStructureObject = requestParameters.isLongspanStructureObject;
    const isOverdueRemark = requestParameters.isOverdueRemark;
    const includeArchived = requestParameters.includeArchived;
    const dateFrom = requestParameters.dateFrom;
    const dateTo = requestParameters.dateTo;
    const groupByDay = requestParameters.groupByDay;
    const groupByMonth = requestParameters.groupByMonth;
    const groupByYear = requestParameters.groupByYear;
    const pageIndex = requestParameters.pageIndex;
    const pageSize = requestParameters.pageSize;

    let localVarQueryParameters = new HttpParams({encoder: this.encoder});
    if (iasId) {
      iasId.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'IasId');
      })
    }
    if (firmIds) {
      firmIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FirmIds');
      })
    }
    if (facilityIds) {
      facilityIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FacilityIds');
      })
    }
    if (egipRegistryNumber) {
      egipRegistryNumber.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'EgipRegistryNumber');
      })
    }
    if (facilityAddresses !== undefined && facilityAddresses !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>facilityAddresses, 'FacilityAddresses');
    }
    if (remarkGroupIds) {
      remarkGroupIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RemarkGroupIds');
      })
    }
    if (remarkSubGroupIds) {
      remarkSubGroupIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RemarkSubGroupIds');
      })
    }
    if (facilityTypeIds) {
      facilityTypeIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FacilityTypeIds');
      })
    }
    if (remarkPriorityIds) {
      remarkPriorityIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RemarkPriorityIds');
      })
    }
    if (isInvestmentObject !== undefined && isInvestmentObject !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isInvestmentObject, 'IsInvestmentObject');
    }
    if (isLongspanStructureObject !== undefined && isLongspanStructureObject !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isLongspanStructureObject, 'IsLongspanStructureObject');
    }
    if (isOverdueRemark !== undefined && isOverdueRemark !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isOverdueRemark, 'IsOverdueRemark');
    }
    if (includeArchived !== undefined && includeArchived !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>includeArchived, 'IncludeArchived');
    }
    if (dateFrom !== undefined && dateFrom !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>dateFrom, 'DateFrom');
    }
    if (dateTo !== undefined && dateTo !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>dateTo, 'DateTo');
    }
    if (groupByDay !== undefined && groupByDay !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>groupByDay, 'GroupByDay');
    }
    if (groupByMonth !== undefined && groupByMonth !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>groupByMonth, 'GroupByMonth');
    }
    if (groupByYear !== undefined && groupByYear !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>groupByYear, 'GroupByYear');
    }
    if (pageIndex !== undefined && pageIndex !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>pageIndex, 'PageIndex');
    }
    if (pageSize !== undefined && pageSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>pageSize, 'PageSize');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/api/Dashboard/activeremark/topfacility`;
    return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiDashboardFacilityfirmCountGet(requestParameters: ApiDashboardFacilityfirmCountGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
  public apiDashboardFacilityfirmCountGet(requestParameters: ApiDashboardFacilityfirmCountGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
  public apiDashboardFacilityfirmCountGet(requestParameters: ApiDashboardFacilityfirmCountGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
  public apiDashboardFacilityfirmCountGet(requestParameters: ApiDashboardFacilityfirmCountGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
    const iasId = requestParameters.iasId;
    const firmIds = requestParameters.firmIds;
    const facilityIds = requestParameters.facilityIds;
    const egipRegistryNumber = requestParameters.egipRegistryNumber;
    const facilityAddresses = requestParameters.facilityAddresses;
    const remarkGroupIds = requestParameters.remarkGroupIds;
    const remarkSubGroupIds = requestParameters.remarkSubGroupIds;
    const facilityTypeIds = requestParameters.facilityTypeIds;
    const remarkPriorityIds = requestParameters.remarkPriorityIds;
    const isInvestmentObject = requestParameters.isInvestmentObject;
    const isLongspanStructureObject = requestParameters.isLongspanStructureObject;
    const isOverdueRemark = requestParameters.isOverdueRemark;
    const includeArchived = requestParameters.includeArchived;
    const dateFrom = requestParameters.dateFrom;
    const dateTo = requestParameters.dateTo;
    const groupByDay = requestParameters.groupByDay;
    const groupByMonth = requestParameters.groupByMonth;
    const groupByYear = requestParameters.groupByYear;
    const pageIndex = requestParameters.pageIndex;
    const pageSize = requestParameters.pageSize;

    let localVarQueryParameters = new HttpParams({encoder: this.encoder});
    if (iasId) {
      iasId.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'IasId');
      })
    }
    if (firmIds) {
      firmIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FirmIds');
      })
    }
    if (facilityIds) {
      facilityIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FacilityIds');
      })
    }
    if (egipRegistryNumber) {
      egipRegistryNumber.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'EgipRegistryNumber');
      })
    }
    if (facilityAddresses !== undefined && facilityAddresses !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>facilityAddresses, 'FacilityAddresses');
    }
    if (remarkGroupIds) {
      remarkGroupIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RemarkGroupIds');
      })
    }
    if (remarkSubGroupIds) {
      remarkSubGroupIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RemarkSubGroupIds');
      })
    }
    if (facilityTypeIds) {
      facilityTypeIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FacilityTypeIds');
      })
    }
    if (remarkPriorityIds) {
      remarkPriorityIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RemarkPriorityIds');
      })
    }
    if (isInvestmentObject !== undefined && isInvestmentObject !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isInvestmentObject, 'IsInvestmentObject');
    }
    if (isLongspanStructureObject !== undefined && isLongspanStructureObject !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isLongspanStructureObject, 'IsLongspanStructureObject');
    }
    if (isOverdueRemark !== undefined && isOverdueRemark !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isOverdueRemark, 'IsOverdueRemark');
    }
    if (includeArchived !== undefined && includeArchived !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>includeArchived, 'IncludeArchived');
    }
    if (dateFrom !== undefined && dateFrom !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>dateFrom, 'DateFrom');
    }
    if (dateTo !== undefined && dateTo !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>dateTo, 'DateTo');
    }
    if (groupByDay !== undefined && groupByDay !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>groupByDay, 'GroupByDay');
    }
    if (groupByMonth !== undefined && groupByMonth !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>groupByMonth, 'GroupByMonth');
    }
    if (groupByYear !== undefined && groupByYear !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>groupByYear, 'GroupByYear');
    }
    if (pageIndex !== undefined && pageIndex !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>pageIndex, 'PageIndex');
    }
    if (pageSize !== undefined && pageSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>pageSize, 'PageSize');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/api/Dashboard/facilityfirm/count`;
    return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiDashboardRatingFacilityByRegistryNumberRegistrynumberGet(requestParameters: ApiDashboardRatingFacilityByRegistryNumberRegistrynumberGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
  public apiDashboardRatingFacilityByRegistryNumberRegistrynumberGet(requestParameters: ApiDashboardRatingFacilityByRegistryNumberRegistrynumberGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
  public apiDashboardRatingFacilityByRegistryNumberRegistrynumberGet(requestParameters: ApiDashboardRatingFacilityByRegistryNumberRegistrynumberGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
  public apiDashboardRatingFacilityByRegistryNumberRegistrynumberGet(requestParameters: ApiDashboardRatingFacilityByRegistryNumberRegistrynumberGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
    const registrynumber = requestParameters.registrynumber;
    if (registrynumber === null || registrynumber === undefined) {
      throw new Error('Required parameter registrynumber was null or undefined when calling apiDashboardRatingFacilityByRegistryNumberRegistrynumberGet.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/api/Dashboard/rating/facility/by-registry-number/${this.configuration.encodeParam({name: "registrynumber", value: registrynumber, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})}`;
    return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiDashboardRatingFacilityIdGet(requestParameters: ApiDashboardRatingFacilityIdGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
  public apiDashboardRatingFacilityIdGet(requestParameters: ApiDashboardRatingFacilityIdGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
  public apiDashboardRatingFacilityIdGet(requestParameters: ApiDashboardRatingFacilityIdGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
  public apiDashboardRatingFacilityIdGet(requestParameters: ApiDashboardRatingFacilityIdGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
    const id = requestParameters.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling apiDashboardRatingFacilityIdGet.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/api/Dashboard/rating/facility/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})}`;
    return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiDashboardRemarkStatisticsGet(requestParameters: ApiDashboardRemarkStatisticsGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
  public apiDashboardRemarkStatisticsGet(requestParameters: ApiDashboardRemarkStatisticsGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
  public apiDashboardRemarkStatisticsGet(requestParameters: ApiDashboardRemarkStatisticsGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
  public apiDashboardRemarkStatisticsGet(requestParameters: ApiDashboardRemarkStatisticsGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
    const iasId = requestParameters.iasId;
    const firmIds = requestParameters.firmIds;
    const facilityIds = requestParameters.facilityIds;
    const egipRegistryNumber = requestParameters.egipRegistryNumber;
    const facilityAddresses = requestParameters.facilityAddresses;
    const remarkGroupIds = requestParameters.remarkGroupIds;
    const remarkSubGroupIds = requestParameters.remarkSubGroupIds;
    const facilityTypeIds = requestParameters.facilityTypeIds;
    const remarkPriorityIds = requestParameters.remarkPriorityIds;
    const isInvestmentObject = requestParameters.isInvestmentObject;
    const isLongspanStructureObject = requestParameters.isLongspanStructureObject;
    const isOverdueRemark = requestParameters.isOverdueRemark;
    const includeArchived = requestParameters.includeArchived;
    const dateFrom = requestParameters.dateFrom;
    const dateTo = requestParameters.dateTo;
    const groupByDay = requestParameters.groupByDay;
    const groupByMonth = requestParameters.groupByMonth;
    const groupByYear = requestParameters.groupByYear;
    const pageIndex = requestParameters.pageIndex;
    const pageSize = requestParameters.pageSize;

    let localVarQueryParameters = new HttpParams({encoder: this.encoder});
    if (iasId) {
      iasId.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'IasId');
      })
    }
    if (firmIds) {
      firmIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FirmIds');
      })
    }
    if (facilityIds) {
      facilityIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FacilityIds');
      })
    }
    if (egipRegistryNumber) {
      egipRegistryNumber.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'EgipRegistryNumber');
      })
    }
    if (facilityAddresses !== undefined && facilityAddresses !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>facilityAddresses, 'FacilityAddresses');
    }
    if (remarkGroupIds) {
      remarkGroupIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RemarkGroupIds');
      })
    }
    if (remarkSubGroupIds) {
      remarkSubGroupIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RemarkSubGroupIds');
      })
    }
    if (facilityTypeIds) {
      facilityTypeIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FacilityTypeIds');
      })
    }
    if (remarkPriorityIds) {
      remarkPriorityIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RemarkPriorityIds');
      })
    }
    if (isInvestmentObject !== undefined && isInvestmentObject !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isInvestmentObject, 'IsInvestmentObject');
    }
    if (isLongspanStructureObject !== undefined && isLongspanStructureObject !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isLongspanStructureObject, 'IsLongspanStructureObject');
    }
    if (isOverdueRemark !== undefined && isOverdueRemark !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isOverdueRemark, 'IsOverdueRemark');
    }
    if (includeArchived !== undefined && includeArchived !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>includeArchived, 'IncludeArchived');
    }
    if (dateFrom !== undefined && dateFrom !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>dateFrom, 'DateFrom');
    }
    if (dateTo !== undefined && dateTo !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>dateTo, 'DateTo');
    }
    if (groupByDay !== undefined && groupByDay !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>groupByDay, 'GroupByDay');
    }
    if (groupByMonth !== undefined && groupByMonth !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>groupByMonth, 'GroupByMonth');
    }
    if (groupByYear !== undefined && groupByYear !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>groupByYear, 'GroupByYear');
    }
    if (pageIndex !== undefined && pageIndex !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>pageIndex, 'PageIndex');
    }
    if (pageSize !== undefined && pageSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>pageSize, 'PageSize');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/api/Dashboard/remark/statistics`;
    return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }
}
