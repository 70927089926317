import {
  AfterContentInit, ChangeDetectorRef,
  Directive,
  effect,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject, Input,
  Output,
  Renderer2,
  signal,
} from '@angular/core';
import {ConfigType, defaultConfigExportToPdf, ExportPdfService} from '../services/export-pdf.service';

export function delay(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[pasToPdf]',
  standalone: true
})
export class ToPdfDirective implements AfterContentInit {
  @Input() set pasToPdf(value: HTMLElement) {
    console.log('pasToPdf', value);
    this.regiterHeader = value;
  }
  @Output() snapshoting: EventEmitter<any> = new EventEmitter();

  @HostBinding('style.--mat-table-row-item-container-height') styleHeight: string = '52px';

  public element = inject(ElementRef);
  exportPdfService = inject(ExportPdfService);
  renderer = inject(Renderer2);
  cdr = inject(ChangeDetectorRef);

  parent?: HTMLElement;
  regiterHeader?: HTMLElement;

  pdfReportStatus = signal<boolean>(false);
  hidden = signal<boolean>(false);
  public orientation: 'p'|'l' = 'p';
  public scale: number = defaultConfigExportToPdf.scale;
  public bottomMargin: number = defaultConfigExportToPdf.bottomMargin;
  public topMargin: number = defaultConfigExportToPdf.topMargin;

  constructor() {
    effect(async () => {
      this.pdfReportStatus()
        ? this.renderer.addClass(this.element.nativeElement, 'pdf-report')
        : this.renderer.removeClass(this.element.nativeElement, 'pdf-report');
      this.pdfReportStatus() ? this.styleHeight = '10px' : this.styleHeight = '52px';
      this.cdr.detectChanges();
      this.renderer.setStyle(this.parent, 'opacity', this.hidden() ? 0 : 1);
    });
  }

  ngAfterContentInit(): void {
    this.parent = this.element.nativeElement.parentElement;
    console.log('regiterHeader', this.regiterHeader);
  }

  async snapshot(cb?: () => void, name: string = 'general-report'): Promise<void> {
    const config: ConfigType = {
      ...defaultConfigExportToPdf,
      orientation: this.orientation,
      scale: this.scale,
      topMargin: this.topMargin,
      bottomMargin: this.bottomMargin
    };

    const DELAY = 300;
    if (!this.element.nativeElement) return;
    this.snapshoting.emit(true);
    this.hidden.set(true);
    await delay(DELAY);
    this.pdfReportStatus.set(true);
    await delay(DELAY);
    console.log('styleHeight', this.styleHeight, getComputedStyle(this.element.nativeElement).height);
    await this.exportPdfService.exportToPDF(this.element.nativeElement, this.regiterHeader, name, config);
    await delay(DELAY);
    this.pdfReportStatus.set(false);
    this.hidden.set(false);
    await delay(DELAY);
    cb && cb();
    this.snapshoting.emit(false);
  }
}

//директива помечает элемент и в дальнейшем используется для передачи в сервис для экпорта в PDF
//[pasToPdf]='registerHeader' - входной параметр - указательна на элемент, который будет являться хедером со второй страницы и далее
//событие snapshoting возникает после того, как экспорт начался (передается true) и завершен (передается false)
