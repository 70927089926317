import {ChangeDetectionStrategy, Component, computed, effect, inject, OnInit, signal} from '@angular/core';
import {FallbackComponent} from '../../../../shared/components/fallback/fallback.component';
import {FormBuilder, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButton, MatIconButton} from '@angular/material/button';
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from '@angular/material/datepicker';
import {MatFormField, MatLabel, MatPrefix} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatInput} from '@angular/material/input';
import {NgClass, NgStyle} from '@angular/common';
import {toSignal} from '@angular/core/rxjs-interop';
import {map} from 'rxjs/operators';
import {MultiSelectComponent} from '../../../../shared/components/multi-select/multi-select.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FacilityService} from '../../../apiModule/api/facility.service';
import {FirmRateService} from '../../../apiModule/api/firmRate.service';
import {RemarkTypeService} from '../../../apiModule';
import {
  QuickAutocompleteComponent
} from '../../../../shared/components/quick-autocomplete/quick-autocomplete.component';

@Component({
  selector: 'ostso-facility-filter',
  standalone: true,
  imports: [
    FallbackComponent,
    FormsModule,
    MatButton,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatFormField,
    MatIcon,
    MatIconButton,
    MatInput,
    MatLabel,
    MatPrefix,
    ReactiveFormsModule,
    NgClass,
    NgStyle,
    MultiSelectComponent,
    QuickAutocompleteComponent
  ],
  templateUrl: './facility-filter.component.html',
  styleUrls: ['./facility-filter.component.scss',
    '../../register/dialogs/dialogForGroup/register-dialog.component.scss',
    '../../rate-card/filter/filter-rate-card.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacilityFilterComponent implements OnInit {
  fb = inject(FormBuilder);
  firmService = inject(FirmRateService);
  facilityService = inject(FacilityService);
  facilityAddressService = inject(RemarkTypeService);
  dialogRef = inject(MatDialogRef<FacilityFilterComponent>);
  data = inject(MAT_DIALOG_DATA);


  loaded = signal<boolean>(true);
  firms = toSignal<OrgRatingFirm[]>(this.firmService.apiFirmRateGet({pageSize: 1e4})
    .pipe(
      map(x => x.values.map((v: OrgRating) => v.firm))
    ));
  facilities = toSignal<OrgRatingFirm[]>(this.facilityService.apiRemarktypeGet({pageSize: 1e4})
    .pipe(
      map(x => x.values)
    ));
  facilityAddresses = toSignal<string[]>(this.facilityAddressService.apiFacilityAdressesGet()
    .pipe(
      map(x => x.values)
    ));

  firmsGotFromData = computed<OrgRatingFirm[]>(() =>
    this.data?.firmIds?.map((id: number) => {
      const res = this.firms()?.find((f: OrgRatingFirm) => f.id === id);
      return res;
    }).filter(Boolean) ?? []);
  facilitiesGotFromData = computed<OrgRatingFirm[]>(() =>
    this.data?.facilityIds?.map((id: number) => {
      const res = this.facilities()?.find((f: OrgRatingFirm) => f.id === id);
      return res;
    }).filter(Boolean) ?? []);

  effectfirmsGotFromData = effect(() => {
    this.form.get('firmIds')!.setValue(this.firmsGotFromData() ?? []);
  });
  effectFacilitiesGotFromData = effect(() => {
    this.form.get('facilityIds')!.setValue(this.facilitiesGotFromData() ?? []);
  });
  // effectAddressGotFromData = effect(() => {
  //   this.form.get('facilityAddress')!.setValue(this.facilityAddresses() ?? []);
  // });

  form = this.fb.group({
    firmIds: [this.firmsGotFromData() ?? []],
    facilityIds: [this.facilitiesGotFromData() ?? []],
    createAt: [this.data && this.data['createAt'] || []],
    finishedDate: [this.data && this.data['finishedDate'] || []],
    facilityAddress: [this.data && this.data['facilityAddress']||''],
  });


  ngOnInit(): void {
    console.log('init');
    console.log('this.data', this.data);
    this.form.valueChanges.subscribe(value => {
      console.log('value', value);
    });
  }

  onApply(): void {
    const res: {
      firmIds: number[];
      facilityIds: number[];
      createAt: string;
      finishedDate: string;
      facilityAddress: string;
    } = {
      firmIds: this.form.value.firmIds && this.form.value.firmIds.map((f: OrgRatingFirm) => f.id) || [] as number[],
      facilityIds: this.form.value.facilityIds && this.form.value.facilityIds.map((f: OrgRatingFirm) => f.id) || [] as number[],
      createAt: (typeof this.form.value.createAt === 'string') ? this.form.value.createAt : this.form.value.createAt.utc().format(),
      finishedDate: (typeof this.form.value.finishedDate === 'string') ? this.form.value.finishedDate : this.form.value.finishedDate.utc().format(),
      facilityAddress: this.form.value.facilityAddress
    };
    this.dialogRef.close(res);
  }

  onReset(): void {
    this.form.patchValue({
      firmIds: [],
      facilityIds: [],
      facilityAddress: '',
      createAt: '',
      finishedDate: '',
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
