<mat-form-field class="full-width">
  <mat-label>{{ label }}</mat-label>
  <input type="text"
         #searchElement
         [placeholder]="placeholder"
         aria-label="Number"
         matInput
         [formControl]="searchControl"
         [matAutocomplete]="auto">
  <mat-autocomplete #auto="matAutocomplete">
    @for (option of filteredOptions(); track option) {
      <mat-option [value]="option">
        <ng-container *ngTemplateOutlet="contentItem; context:{option}"></ng-container>
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
