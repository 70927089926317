import {ChangeDetectionStrategy, Component, computed, effect, inject, OnInit, signal} from '@angular/core';
import {FallbackComponent} from '../../../../shared/components/fallback/fallback.component';
import {FormBuilder, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButton, MatIconButton} from '@angular/material/button';
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from '@angular/material/datepicker';
import {MatFormField, MatLabel, MatPrefix} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatInput} from '@angular/material/input';
import {NgClass, NgStyle} from '@angular/common';
import {FirmRateService} from '../../../apiModule/api/firmRate.service';
import {toObservable, toSignal} from '@angular/core/rxjs-interop';
import {map} from 'rxjs/operators';
import {MultiSelectComponent} from '../../../../shared/components/multi-select/multi-select.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'ostso-org-filter',
  standalone: true,
  imports: [
    FallbackComponent,
    FormsModule,
    MatButton,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatFormField,
    MatIcon,
    MatIconButton,
    MatInput,
    MatLabel,
    MatPrefix,
    ReactiveFormsModule,
    NgClass,
    NgStyle,
    MultiSelectComponent
  ],
  templateUrl: './org-filter.component.html',
  styleUrls: ['./org-filter.component.scss',
    '../../register/dialogs/dialogForGroup/register-dialog.component.scss',
    '../../rate-card/filter/filter-rate-card.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrgFilterComponent implements OnInit {
  fb = inject(FormBuilder);
  firmService = inject(FirmRateService);
  dialogRef = inject(MatDialogRef<OrgFilterComponent>);
  data = inject(MAT_DIALOG_DATA);


  loaded = signal<boolean>(true);
  firms = toSignal<OrgRatingFirm[]>(this.firmService.apiFirmRateGet({pageSize: 1e4})
    .pipe(
      map(x => x.values.map((v: OrgRating) => v.firm))
    ));

  firmsGotFromData = computed<OrgRatingFirm[]>(() =>
    this.data?.firmIds?.map((id: number) => {
      const res = this.firms()?.find((f: OrgRatingFirm) => f.id === id);
      return res;
    }).filter(Boolean)??[]);
  effectfirmsGotFromData=effect(()=>{
    this.form.get('firmIds')!.setValue(this.firmsGotFromData()??[]);
  });

  form = this.fb.group({
    firmIds: [this.firmsGotFromData()??[]],
    createAt: [this.data && this.data['createAt'] || []],
    finishedDate: [this.data && this.data['finishedDate'] || []],
  });


  ngOnInit(): void {
    console.log('init');
    console.log('this.data', this.data);
    this.form.valueChanges.subscribe(value => {
      console.log('value', value);
    });
  }

  onApply(): void {
    const res: {
      firmIds: number[];
      createAt: string;
      finishedDate: string;
    } = {
      firmIds: this.form.value.firmIds && this.form.value.firmIds.map((f: OrgRatingFirm) => f.id) || [] as number[],
      createAt: (typeof this.form.value.createAt ==='string') ? this.form.value.createAt: this.form.value.createAt.utc().format(),
      finishedDate: (typeof this.form.value.finishedDate ==='string') ? this.form.value.finishedDate: this.form.value.finishedDate.utc().format()
    };
    this.dialogRef.close(res);
  }

  onReset(): void {
    this.form.patchValue({
      firmIds: [],
      createAt: '',
      finishedDate: '',
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
