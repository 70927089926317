/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';


// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {environment} from "../../../../environments/environment";

export interface ApiFacilityRateStatsGetRequestParams {
  iasId?: Array<number>;
  egipRegistryNumber?: Array<number>;
  createAt?: string;
  finishedDate?: string;
  facilityAddress?: string;
  facilityIds?: Array<number>;
  firmIds?: Array<number>;
  statusId?: number;
  searchString?: string;
  sortColumnName?: string;
  sortAscending?: boolean;
  pageIndex?: number;
  pageSize?: number;
}

export interface ApiFacilityRateGetRequestParams {
    iasId?: Array<number>;
    egipRegistryNumber?: Array<number>;
    createAt?: string;
    finishedDate?: string;
    facilityAddress?: string;
    facilityIds?: Array<number>;
    firmIds?: Array<number>;
    statusId?: number;
    searchString?: string;
    sortColumnName?: string;
    sortAscending?: boolean;
    pageIndex?: number;
    pageSize?: number;
}


@Injectable({
  providedIn: 'root'
})
export class FacilityRateService {

    protected basePath = environment.api_url;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiFacilityRateGet(requestParameters: ApiFacilityRateGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public apiFacilityRateGet(requestParameters: ApiFacilityRateGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiFacilityRateGet(requestParameters: ApiFacilityRateGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiFacilityRateGet(requestParameters: ApiFacilityRateGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        const iasId = requestParameters.iasId;
        const egipRegistryNumber = requestParameters.egipRegistryNumber;
        const createAt = requestParameters.createAt;
        const finishedDate = requestParameters.finishedDate;
        const facilityAddress = requestParameters.facilityAddress;
        const facilityIds = requestParameters.facilityIds;
        const firmIds = requestParameters.firmIds;
        const statusId = requestParameters.statusId;
        const searchString = requestParameters.searchString;
        const sortColumnName = requestParameters.sortColumnName;
        const sortAscending = requestParameters.sortAscending;
        const pageIndex = requestParameters.pageIndex;
        const pageSize = requestParameters.pageSize;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (iasId) {
            iasId.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'IasId');
            })
        }
        if (egipRegistryNumber) {
            egipRegistryNumber.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'EgipRegistryNumber');
            })
        }
        if (createAt !== undefined && createAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createAt, 'CreateAt');
        }
        if (finishedDate !== undefined && finishedDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>finishedDate, 'FinishedDate');
        }
        if (facilityAddress !== undefined && facilityAddress !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>facilityAddress, 'FacilityAddress');
        }
        if (facilityIds) {
            facilityIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'FacilityIds');
            })
        }
        if (firmIds) {
            firmIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'FirmIds');
            })
        }
        if (statusId !== undefined && statusId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>statusId, 'StatusId');
        }
        if (searchString !== undefined && searchString !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>searchString, 'SearchString');
        }
        if (sortColumnName !== undefined && sortColumnName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortColumnName, 'Sort.ColumnName');
        }
        if (sortAscending !== undefined && sortAscending !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortAscending, 'Sort.Ascending');
        }
        if (pageIndex !== undefined && pageIndex !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageIndex, 'PageIndex');
        }
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'PageSize');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<any>(`${this.configuration.basePath}/api/FacilityRate`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiFacilityRateStatsGet(requestParameters: ApiFacilityRateStatsGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
  public apiFacilityRateStatsGet(requestParameters: ApiFacilityRateStatsGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
  public apiFacilityRateStatsGet(requestParameters: ApiFacilityRateStatsGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
  public apiFacilityRateStatsGet(requestParameters: ApiFacilityRateStatsGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
    const iasId = requestParameters.iasId;
    const egipRegistryNumber = requestParameters.egipRegistryNumber;
    const createAt = requestParameters.createAt;
    const finishedDate = requestParameters.finishedDate;
    const facilityAddress = requestParameters.facilityAddress;
    const facilityIds = requestParameters.facilityIds;
    const firmIds = requestParameters.firmIds;
    const statusId = requestParameters.statusId;
    const searchString = requestParameters.searchString;
    const sortColumnName = requestParameters.sortColumnName;
    const sortAscending = requestParameters.sortAscending;
    const pageIndex = requestParameters.pageIndex;
    const pageSize = requestParameters.pageSize;

    let localVarQueryParameters = new HttpParams({encoder: this.encoder});
    if (iasId) {
      iasId.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'IasId');
      })
    }
    if (egipRegistryNumber) {
      egipRegistryNumber.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'EgipRegistryNumber');
      })
    }
    if (createAt !== undefined && createAt !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>createAt, 'CreateAt');
    }
    if (finishedDate !== undefined && finishedDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>finishedDate, 'FinishedDate');
    }
    if (facilityAddress !== undefined && facilityAddress !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>facilityAddress, 'FacilityAddress');
    }
    if (facilityIds) {
      facilityIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FacilityIds');
      })
    }
    if (firmIds) {
      firmIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FirmIds');
      })
    }
    if (statusId !== undefined && statusId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>statusId, 'StatusId');
    }
    if (searchString !== undefined && searchString !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>searchString, 'SearchString');
    }
    if (sortColumnName !== undefined && sortColumnName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>sortColumnName, 'Sort.ColumnName');
    }
    if (sortAscending !== undefined && sortAscending !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>sortAscending, 'Sort.Ascending');
    }
    if (pageIndex !== undefined && pageIndex !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>pageIndex, 'PageIndex');
    }
    if (pageSize !== undefined && pageSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>pageSize, 'PageSize');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/api/FacilityRate/stats`;
    return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

}
