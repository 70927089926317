<div class="container">
  <table #table mat-table [dataSource]="data" class="mat-elevation-z8" matSort multiTemplateDataRows
         (matSortChange)="sortChange($event)">
    @for (column of columns; track column.name; let i = $index; let last = $last) {
      <ng-container [matColumnDef]="column.name" [sticky]="column.sticky">
        <th
          mat-header-cell
          *matHeaderCellDef
          [mat-sort-header]="column.name"
          [disabled]="column.disabled || last"
          [ngStyle]="{color: column.hideTitle?'#0000':''}"
        >
          @if (column.name === SETTINGS_COLUMN) {
            <button mat-icon-button [matMenuTriggerFor]="settingsMenu" #menuTrigger="matMenuTrigger">
              <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_69_724" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="23"
                      height="23">
                  <rect width="22.5494" height="22.5494" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask0_69_724)">
                  <path
                    d="M8.69082 20.6704L8.31499 17.6638C8.11142 17.5855 7.91959 17.4916 7.73951 17.3819C7.55943 17.2723 7.38326 17.1549 7.21101 17.0296L4.41582 18.2041L1.83203 13.7412L4.2514 11.909C4.23574 11.7994 4.22791 11.6937 4.22791 11.5919V10.9577C4.22791 10.8559 4.23574 10.7502 4.2514 10.6406L1.83203 8.80846L4.41582 4.34556L7.21101 5.52C7.38326 5.39473 7.56334 5.27729 7.75126 5.16767C7.93917 5.05805 8.12708 4.9641 8.31499 4.8858L8.69082 1.87921H13.8584L14.2342 4.8858C14.4378 4.9641 14.6296 5.05805 14.8097 5.16767C14.9898 5.27729 15.1659 5.39473 15.3382 5.52L18.1334 4.34556L20.7172 8.80846L18.2978 10.6406C18.3135 10.7502 18.3213 10.8559 18.3213 10.9577V11.5919C18.3213 11.6937 18.3056 11.7994 18.2743 11.909L20.6937 13.7412L18.1099 18.2041L15.3382 17.0296C15.1659 17.1549 14.9859 17.2723 14.798 17.3819C14.61 17.4916 14.4221 17.5855 14.2342 17.6638L13.8584 20.6704H8.69082ZM10.335 18.7913H12.1907L12.5195 16.3015C13.005 16.1762 13.4552 15.9922 13.8701 15.7495C14.2851 15.5067 14.6648 15.2131 15.0094 14.8686L17.3348 15.8317L18.2508 14.2344L16.2308 12.7076C16.3091 12.4884 16.3639 12.2574 16.3952 12.0147C16.4265 11.772 16.4422 11.5254 16.4422 11.2748C16.4422 11.0243 16.4265 10.7776 16.3952 10.5349C16.3639 10.2922 16.3091 10.0612 16.2308 9.84198L18.2508 8.31519L17.3348 6.71794L15.0094 7.70448C14.6648 7.34432 14.2851 7.04287 13.8701 6.80015C13.4552 6.55744 13.005 6.37344 12.5195 6.24816L12.2142 3.75833H10.3585L10.0297 6.24816C9.54425 6.37344 9.09404 6.55744 8.67907 6.80015C8.2641 7.04287 7.88436 7.33649 7.53986 7.68099L5.21445 6.71794L4.29837 8.31519L6.31843 9.81849C6.24013 10.0534 6.18532 10.2883 6.154 10.5232C6.12269 10.758 6.10703 11.0086 6.10703 11.2748C6.10703 11.5254 6.12269 11.7681 6.154 12.003C6.18532 12.2379 6.24013 12.4727 6.31843 12.7076L4.29837 14.2344L5.21445 15.8317L7.53986 14.8451C7.88436 15.2053 8.2641 15.5067 8.67907 15.7495C9.09404 15.9922 9.54425 16.1762 10.0297 16.3015L10.335 18.7913Z"
                    fill="white"/>
                </g>
              </svg>
            </button>
            <mat-menu #settingsMenu="matMenu" class="custom-menu">
            <span mat-menu-item class="close-button" (click)="menuTrigger.closeMenu()">
              <mat-icon>close</mat-icon>
            </span>

              @for (col of columns; track col.name; let last = $last) {
                @if (!last) {
                  <div [ngStyle]="{margin: '10px 0'}">
                    <mat-checkbox
                      [disabled]="col.isRequired || false"
                      [(ngModel)]="col.isSelected"
                      (ngModelChange)="changedSelectedColumn()"
                      [title]="col.settingTitle ?? col.title || ''"
                    > {{ col.settingTitle ?? col.title || '' }}
                    </mat-checkbox>
                  </div>
                }
              }

            </mat-menu>
          } @else {
            <div [ngStyle]="{width: column.hideTitle?'0px':'', oveflow: 'hidden'}">
              {{ column.title }}
            </div>
          }
        </th>
        <td mat-cell *matCellDef="let element">
          @if (column.name === SETTINGS_COLUMN) {
            @if (actionTemplate) {
              <ng-template
                [ngTemplateOutlet]="actionTemplate"
                [ngTemplateOutletContext]="{ $implicit: element, renderIndex: i }"
              >
              </ng-template>
            }
          } @else {
            @if (column.useCustomTemplate) {
              <ng-template
                [ngTemplateOutlet]="cellTemplates[column.name]"
                [ngTemplateOutletContext]="{ $implicit: element, renderIndex: i }"
              >
              </ng-template>
            } @else {
              @if (column.innerDiv) {
                <div [class]="column.innerDiv">
                  {{ column.parseFunction ? column.parseFunction(element[column.name]) : element[column.name] }}
                </div>
              } @else {
                {{ column.parseFunction ? column.parseFunction(element[column.name]) : element[column.name] }}
              }
            }
          }
        </td>
      </ng-container>
    }
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="rowSelected.emit(row)"></tr>
  </table>
</div>

<div class="table-controls" *ngIf="hasControls">

  <div class="page-count">
    <form [formGroup]="form">
      <mat-form-field>
        <mat-select
          class="page-size"
          formControlName="pageSizeControl"
        >
          <mat-option [value]="10">10</mat-option>
          <mat-option [value]="25">25</mat-option>
          <mat-option [value]="50">50</mat-option>
        </mat-select>
      </mat-form-field>
    </form>
    {{ 1 + (this.page - 1) * this.pageSize }}
    -
    <ng-container *ngIf="this.page*this.pageSize < this.recordCount; else pagesize">{{ this.page * this.pageSize }}
    </ng-container>

    <ng-template #pagesize>{{ this.recordCount }}</ng-template>

    из {{ this.recordCount }} записей
  </div>

  <datatable-pager [pagerLeftArrowIcon]="'left'"
                   [pagerRightArrowIcon]="'right'"
                   [pagerPreviousIcon]="'prev'"
                   [pagerNextIcon]="'next'"
                   [page]="page"
                   [size]="pageSize"
                   [count]="recordCount"
                   [hidden]="!((recordCount / page) >= 1)"
                   (change)="setPage($event)">
  </datatable-pager>


</div>
