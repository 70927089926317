<div>
  <h4>Активные замечания по подгруппам замечаний
    <span (click)="showMoreInfo('subgroups')">Подробнее</span>
  </h4>
  <div *ngFor="let item of bySubGroup()" class="horizontal-bar-chart">
    <div>
      <div class="title">{{item.name}}</div>

      <div class="bar"><div [style.width.%]="(item.activeRemarksCount / maxBySubGroupCount()) * 100">{{item.activeRemarksCount}}</div></div>
    </div>
  </div>
</div>
