<div class="select-inspector" #selectinspector>
  <div class="select-inspector__label" [attr.data-disabled]="isDisabled()"
       (click)="!isDisabled() && toggle.set(!toggle())">
    <span>{{ name }}</span>
    <mat-icon fontSet="mdi" fontIcon="mdi-chevron-down"></mat-icon>
  </div>
  <div class="select-inspector__list-container" [attr.data-open]="toggle()">
    <mat-icon (click)="toggle.set(false)">close</mat-icon>
    <div class="select-inspector__list">
      @for (user of data; track user) {
        <div [attr.data-current]="user.id === value?.id" class="select-inspector__item" (click)="select(user)">
          <div class="select-inspector__radio">
          </div>
          <span class="select-inspector__name">
            {{ getNameFromUser(user) }}
          </span>
        </div>
      }
    </div>
  </div>

</div>
<!--<ng-template #list>-->
<!--  <div class="select-inspector__list">-->
<!--    @for (user of data; track user) {-->
<!--      <div [attr.data-current]="user.id === value?.id" class="select-inspector__item">{{ user.lastName }}</div>-->
<!--    }-->
<!--  </div>-->
<!--</ng-template>-->
