import {Injectable} from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class XlsService {

  public export(element: HTMLTableElement, title: string): void {

    const expectedElement: HTMLTableElement | null = (element instanceof HTMLTableElement)
                                                          ? element
                                                          : (element as HTMLElement).querySelector('table');
    const worksheet = XLSX.utils.table_to_sheet(expectedElement, {raw: true});

    if(!expectedElement) return;

    // Извлекаем стили из HTML
    const rows = expectedElement.rows;
    for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
      const cells = rows[rowIndex].cells;
      for (let colIndex = 0; colIndex < cells.length; colIndex++) {
        const cell = cells[colIndex];
        const cellStyle = window.getComputedStyle(cell);
        const textColor = cellStyle.color;

        const cellAddress = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex });
        worksheet[cellAddress].z = '@'; // Указывает текстовый формат
        worksheet[cellAddress].t = 's'; // Задает тип ячейки как строку

        // Преобразуем цвет текста в формат RGB
        const rgbMatch = textColor.match(/\d+/g);
        if (rgbMatch) {
          const [r, g, b] = rgbMatch.map(Number);
          const rgbHex = `${r.toString(16).padStart(2, '0')}${g
            .toString(16)
            .padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;

          // Получаем адрес ячейки
          // const cellAddress = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex });

          // Применяем цвет текста к ячейке
          if(!worksheet[cellAddress]) continue;
          if (!worksheet[cellAddress]?.s) worksheet[cellAddress].s = {};
          worksheet[cellAddress].s.font = { color: { rgb: rgbHex.toUpperCase() } };
        }
      }
    }

    // @ts-expect-error: требование XLXS
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    console.log('worksheet[\'A1\'].', worksheet['A1']);

    const lastCol = range.e.c;

// Пройтись по всем строкам и удалить ячейки последнего столбца
    for (let R = range.s.r; R <= range.e.r; R++) {
      const cellAddress = XLSX.utils.encode_cell({r: R, c: lastCol});
      delete worksheet[cellAddress];
    }

// Обновить диапазон листа, уменьшив количество столбцов на 1
    range.e.c = lastCol - 1;
    worksheet['!ref'] = XLSX.utils.encode_range(range);

    worksheet['!cols'] = _autoFitColumns(worksheet);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, title + '.xlsx');

  }
}
export function _autoFitColumns(worksheet: XLSX.WorkSheet):  {wch: any }[] {
  // @ts-expect-error: тредование XLXS
  const range = XLSX.utils.decode_range(worksheet['!ref']);
  const colWidths = Array(range.e.c + 1).fill(0);

  for (let R = range.s.r; R <= range.e.r; R++) {
    for (let C = range.s.c; C <= range.e.c; C++) {
      const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
      const cell = worksheet[cellAddress];

      if (cell && cell.v) {
        const cellValue = cell.v.toString();
        const cellWidth = cellValue.length;
        colWidths[C] = Math.max(colWidths[C], cellWidth);
      }
    }
  }
  return colWidths.map(width => ({ wch: width }));

}
