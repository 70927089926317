import {ChangeDetectionStrategy, Component, computed, inject, Input, signal} from '@angular/core';
import {NgForOf} from '@angular/common';
import {
  ApiDashboardActiveremarkByFacilitytypeGetRequestParams,
  DashboardService
} from '../../../../apiModule/api/dashboard.service';
import {toSignal} from '@angular/core/rxjs-interop';
import {map} from 'rxjs/operators';
import {DashboardModal} from '../../../dashboard-modal/dashboard-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {catchError, of} from 'rxjs';

@Component({
  selector: 'ostso-by-subgroups-dashboard-widget',
  standalone: true,
  imports: [
    NgForOf
  ],
  templateUrl: './by-subgroups-dashboard-widget.component.html',
  styleUrls: ['./by-subgroups-dashboard-widget.component.scss', '../../dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BySubgroupsDashboardWidgetComponent {
  dialog = inject(MatDialog);
  dashboardService = inject(DashboardService);
  private _filter?: ApiDashboardActiveremarkByFacilitytypeGetRequestParams;
  data = signal<DashboardActiveremarkItem[]>([]);
  @Input() set filter(v: ApiDashboardActiveremarkByFacilitytypeGetRequestParams) {
    this._filter = v;
    this.dashboardService.apiDashboardActiveremarkBySubgroupGet({...v||{}})
      .pipe(catchError((e: any)=>{
        console.log(e);
        return of({values:[]});
      }))
      .pipe(map(v=>v.values))
      .subscribe(v=>{
      this.data.set(v);
    });
  }

  combinedSubGroups = computed(() => this.data()?.sort((a: DashboardActiveremarkItem, b: DashboardActiveremarkItem) => b.activeRemarksCount - b.activeRemarksCount) ?? []);
  bySubGroup = computed<any[]>(() => Object.values(this.combinedSubGroups()||{}));
  maxBySubGroupCount = computed(() => Math.max(...this.bySubGroup().map((subGroup: any) => subGroup.activeRemarksCount)));

  showMoreInfo(type: string): void {
    this.dialog.open(DashboardModal, {
      width: 'max(320px,50vw)',
      data: {type}
    });
  }
}
