<div class="by-priority-dashboard-widget">
  <h4>Активные замечания по приоритету</h4>
  <div class="bar-chart-legend">
    <div class="bar-column">
      <div class="bar-value">{{this.priority()?.id4}}</div>
      <div class="bar" [style]="{backgroundColor: '#174DEDE5', height:(+this.priority()?.id4 / maxPriorityCount()) * 100 + '%'}"></div>
      <div class="bar-label">Критический</div>
    </div>
    <div class="bar-column">
      <div class="bar-value">{{this.priority()?.id3}}</div>
      <div class="bar" [style]="{backgroundColor: '#E22B36', height:((+this.priority()?.id3 / maxPriorityCount()) * 100)+'%'}"></div>
      <div class="bar-label">Высокий</div>
    </div>
    <div class="bar-column">
      <div class="bar-value">{{this.priority()?.id2}}</div>
      <div class="bar" [style]="{backgroundColor: '#45C440', height: ((+this.priority()?.id2 / maxPriorityCount()) * 100)+'%'}"></div>
      <div class="bar-label">Средний</div>
    </div>
    <div class="bar-column">
      <div class="bar-value">{{this.priority()?.id1}}</div>
      <div class="bar" [style]="{backgroundColor: '#FAFA25', height: ((+this.priority()?.id1 / maxPriorityCount()) * 100) + '%'}"></div>
      <div class="bar-label">Низкий</div>
    </div>
  </div>
</div>
