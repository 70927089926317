import {Component, Inject, OnInit, signal} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms'; // Import ReactiveFormsModule
import {MAT_DIALOG_DATA, MatDialogContent, MatDialogRef,} from '@angular/material/dialog';
import {MatButtonModule, MatIconButton} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {map} from 'rxjs/operators';
import {FacilityRateService} from '../../apiModule/api/facilityRate.service';
import {GroupRating} from '../rating/org-rating.component';
import {catchError} from 'rxjs';


@Component({
  selector: 'ostso-rating-modal',
  templateUrl: 'rating-modal.component.html',
  styleUrls: ['./rating-modal.component.scss', '../rating/rating.component.scss', '../../../shared/components/table/table.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogContent,
    MatIconButton,
    MatIcon,
    CommonModule,
    ReactiveFormsModule
  ],
})
export class RatingModalComponent implements OnInit {
  downloadMessage = signal('Загрузка данных ...');

  public facilities: (FacilityRating & {[key: `group_${number}`]: number})[] = [];
  public facilityFilter = new FormControl('');
  public filteredFacilities: any[] = [];

  get groupFields(): string[] {
    if(!this.facilities.length) return [];
    const res =
      Object.keys(this.facilities[0]).filter(f => f.startsWith('group_'));
    return res;
  }

  getGroupNameByField(field: string): string|undefined {
    if(!this.facilities.length) return;
    const id = field.replace('group_', '');
    const res = this.facilities[0]['groupRating'].find((g: any)=>g.id === +id);
    return res?.['name'];
  }

  constructor(
    public dialogRef: MatDialogRef<RatingModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private facilityService: FacilityRateService,
  ) {
  }

  ngOnInit(): void {

    //--- получаем данные об объектах по id, переданной организации ---
    const id = this.data.id;
    console.log(id);
    this.facilityService.apiFacilityRateGet({firmIds: [id]})
      .pipe(map(v => v.values))
      .pipe(catchError(async (err) => this.downloadMessage.set('Ошибка получения данных')))
      .subscribe((res: FacilityRating[]) => {
        this.facilities = this.parseGroupsData(res);
        this.facilityFilter.setValue('');
      });

    //--- подписываемся на изменение фильтра ---
    this.facilityFilter.valueChanges
      .subscribe(() => this.filterFacilities());

  }

  filterFacilities(): void {
    const filterValue = this.facilityFilter.value ? this.facilityFilter.value.toLowerCase() : '';
    if (filterValue === '') {
      this.filteredFacilities = this.facilities;
      return;
    }
    this.filteredFacilities = this.facilities.filter((facility: any) => (facility.name + '' + facility.address).toLowerCase().includes(filterValue));
  }


  onClose(): void {
    this.dialogRef.close();
  }

  parseGroupsData(data: FacilityRating[]): (FacilityRating & {[key: `group_${number}`]: number})[] {
    if (!data.length) return [];
    return data.map(el => {
      const groupRating: GroupRating = el.groupRating;
      if (!groupRating) return;
      const groups = Object.entries(groupRating).map(([key, value]) => [
        'group_' + value.id, value.rating
      ]);
      return {...el, ...Object.fromEntries(groups)};
    }).filter(Boolean);
  }
}
