import {
  AfterContentInit, afterRender,
  Component,
  ContentChild, ElementRef,
  forwardRef, Input, signal,
  TemplateRef, ViewChild
} from '@angular/core';
import {FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule} from '@angular/forms';
import {QuickFilterService} from '../../../core/services/quick-filter.service';
import {MatFormField, MatFormFieldModule, MatLabel} from '@angular/material/form-field';
import {
  MatAutocomplete,
  MatAutocompleteModule,
  MatAutocompleteTrigger,
  MatOption
} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'ostso-quick-autocomplete',
  standalone: true,
  imports: [
    FormsModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatFormField,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatAutocompleteTrigger,
    MatAutocomplete,
    MatOption,
    MatLabel,
    CommonModule
  ],
  templateUrl: './quick-autocomplete.component.html',
  styleUrl: './quick-autocomplete.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuickAutocompleteComponent),
      multi: true
    }
  ]
})
export class QuickAutocompleteComponent<T> implements AfterContentInit{
  _data: T[] = [];

  @Input() label: string = '';
  @Input() placeholder: string = 'Начните вводить';
  @Input() set data(value: T[]) {
    this._data = value;
  }
  @ContentChild(TemplateRef) contentItem: TemplateRef<any> | null = null;
  @ViewChild('searchElement') searchElement?: ElementRef;

  searchControl = new FormControl('');

  onChange: any = () => {};
  onTouched: any = () => {};

  filteredOptions = signal<T[]>([]);

  get value(): string {
    return this.searchControl.value||'';
  }

  set value(value: string) {
    this.searchControl.setValue(value);
  }

  constructor(private quickFilter: QuickFilterService) {
    // afterRender(() => {
    //   this.searchControl.setValue('');
    // });
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.searchControl.disable() : this.searchControl.enable();
  }

  ngAfterContentInit():void {
    this.searchControl.valueChanges.subscribe((value) => {
      this.quickFilter.getData(this._data, value).then((res: T[])=> {
        setTimeout(() => {
          this.filteredOptions.set(res);
        });
      });
      this.onChange(this.value);
    });

  }
}
