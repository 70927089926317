import {Routes} from '@angular/router';
import {DashboardComponent} from './dashboard/dashboard.component';
import {GradeComponent} from './grade/grade.component';
import {LoginComponent} from './login/login.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {RegisterComponent} from './register/register.component';
import {remarksResolver} from './register/resolvers/remarks.resolver';
import {AuthGuard} from '../../core/interceptors/auth.guard';
import {groupEstimateResolver} from './register/resolvers/group-estimate.resolver';
import {RegisterGroupComponent} from './register/register-group.component';
import {GroupEstimate} from '../apiModule/model/groupEstimate';
import {RegisterSubroupComponent} from './register/register-subgroup.component';
import {subgroupEstimateResolver} from './register/resolvers/subgroup-estimate.resolver';
import {SubgroupEstimate} from '../apiModule/model/subgroupEstimate';
import {RegisterKindComponent} from './register/register-kind.component';
import {kindResolver} from './register/resolvers/kind.resolver';
import {Kind} from '../apiModule/model/kind';
import {remarktypeResolver} from './register/resolvers/remarktype.resolver';
import {RemarkType} from '../apiModule/model/remarktype';
import {RegisterRemarktypeComponent} from './register/register-remarktype.component';
import {institutionsResolver} from './register/resolvers/institutions.resolver';
import {RegisterInstitutionsComponent} from './register/register-institutions.component';
import {facilityResolver} from './register/resolvers/facility.resolver';
import {RegisterFacilityComponent} from './register/register-facility.component';
import {RateCardComponent} from './rate-card/rate-card.component';
import {rateCardResolver} from './rate-card/rate-card..resolver';
import {rateCardIdResolver} from './rate-card/rate-card-id..resolver';
import {newBlockGuard} from '../../core/interceptors/new-block.guard';
import {RatingComponent} from './rating/rating.component';
import {facilityRateResolver} from './rating/resolvers/facility.resolver';
import {OrgRatingComponent} from './rating/org-rating.component';
import {orgRateResolver} from './rating/resolvers/org.resolver';

export const routes: Routes = [
  {path: 'login', title: 'Авторизация', component: LoginComponent},
  {path: 'logout', title: 'Авторизация', component: LoginComponent, resolve: {}},
  {
    path: 'register', title: 'Реестр оценок', component: RegisterComponent, resolve: {
      remarks: remarksResolver
    }, canActivate: [AuthGuard],
  },
  {
    path: 'register/new',
    title: 'Реестр оценок',
    pathMatch: 'full',
    component: GradeComponent,
    canActivate: [AuthGuard,],
  },
  {
    path: 'register/new/new', redirectTo: '/register/new',
  },
  {
    path: 'group',
    title: 'Группы замечаний',
    component: RegisterGroupComponent,
    canActivate: [AuthGuard],
    resolve: {
      remarks: groupEstimateResolver,
      newRemark: () => false,
      type: () => 'group'
    },
  },
  {
    path: 'group/:id',
    title: 'Группы замечаний',
    component: RegisterGroupComponent,
    canActivate: [AuthGuard, newBlockGuard('group')],
    resolve: {
      remarks: groupEstimateResolver,
      newRemark: () => new GroupEstimate(),
      type: () => 'group'
    },
  },
  {
    path: 'subgroup',
    title: 'Подгруппы замечаний',
    component: RegisterSubroupComponent,
    canActivate: [AuthGuard],
    resolve: {
      remarks: subgroupEstimateResolver,
      newRemark: () => false,
      type: () => 'subgroup'
    },
  },
  {
    path: 'subgroup/:id',
    title: 'Подгруппы замечаний',
    component: RegisterSubroupComponent,
    canActivate: [AuthGuard, newBlockGuard('subgroup')],
    resolve: {
      remarks: subgroupEstimateResolver,
      newRemark: () => new SubgroupEstimate(),
      type: () => 'subgroup'
    },
  },
  {
    path: 'kind',
    title: 'Виды замечаний',
    component: RegisterKindComponent,
    canActivate: [AuthGuard],
    resolve: {
      remarks: kindResolver,
      newRemark: () => false,
      type: () => 'kind'
    },
  },
  {
    path: 'kind/:id',
    title: 'Виды замечаний',
    component: RegisterKindComponent,
    canActivate: [AuthGuard, newBlockGuard('kind')],
    resolve: {
      remarks: kindResolver,
      newRemark: () => new Kind(),
      type: () => 'kind'
    },
  },
  {
    path: 'remarktype',
    title: 'Замечания',
    component: RegisterRemarktypeComponent,
    canActivate: [AuthGuard],
    resolve: {
      remarks: remarktypeResolver,
      newRemark: () => false,
      type: () => 'remarktype'
    },
  },
  {
    path: 'remarktype/:id',
    pathMatch: 'full',
    title: 'Замечания',
    component: RegisterRemarktypeComponent,
    canActivate: [AuthGuard, newBlockGuard('remarktype')],
    resolve: {
      remarks: remarktypeResolver,
      newRemark: () => new RemarkType(),
      type: () => 'remarktype'
    },
  },
  {
    path: 'remarktype/new/new',
    redirectTo: 'remarktype/new',
  },
  {
    path: 'institutions',
    title: 'Учреждения Москомспорта',
    component: RegisterInstitutionsComponent,
    canActivate: [AuthGuard],
    resolve: {
      remarks: institutionsResolver,
      newRemark: () => false,
    },
  },
  {
    path: 'facility',
    title: 'Объекты учреждений Москомспорта',
    component: RegisterFacilityComponent,
    canActivate: [AuthGuard],
    resolve: {
      remarks: facilityResolver,
      newRemark: () => false,
    },
  },
  {
    path: 'ratecard',
    title: 'Реестр оценок',
    component: RateCardComponent,
    canActivate: [AuthGuard],
    resolve: {
      data: rateCardResolver,
    },
  },
  {
    path: 'ratecard/new', redirectTo: '/grade',
  },
  {path: 'grade', title: 'Оценка', component: GradeComponent, canActivate: [AuthGuard]},

  {path: 'rating', title: 'Рейтинг', component: RatingComponent, canActivate: [AuthGuard],
    resolve: {
      data: facilityRateResolver,
      newRemark: () => false,
    },

  },
  {path: 'org-rating', title: 'Рейтинг', component: OrgRatingComponent, canActivate: [AuthGuard],
    resolve: {
      data: orgRateResolver,
      newRemark: () => false,
    },

  },
  {path: 'dashboard', title: 'Дашборд', component: DashboardComponent, canActivate: [AuthGuard]},


  {
    path: 'grade/:id',
    title: 'Новая оценка',
    component: GradeComponent,
    canActivate: [AuthGuard],
    resolve: {
      data: rateCardIdResolver,
    },
  },
  {
    path: 'grade/new', redirectTo: '/grade',
  },
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: '404', component: PageNotFoundComponent, title: 'Страница не найдена'},
  {path: '**', component: PageNotFoundComponent, title: 'Страница не найдена'}
];
