<mat-form-field appearance="outline" class="ostro-autocomplete" [attr.data-disabled]="isDisabled ? 'true' : 'false'">
  <mat-label>{{ label }}</mat-label>
  <mat-icon matPrefix>search</mat-icon>
  <input type="text"
         placeholder="Начните печатать или выберите вариант"
         matInput
         class="input"
         [formControl]="control"
         [matAutocomplete]="auto"
         [ngClass]="{'disabled':isDisabled}"
  >
  <mat-icon matSuffix>keyboard_arrow_down</mat-icon>

  <button mat-button *ngIf="control.value" matSuffix mat-icon-button aria-label="Clear"
          (click)="control.reset()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
    <cdk-virtual-scroll-viewport itemSize="48" [ngStyle]="{height: '200px'}">
      <mat-option *cdkVirtualFor="let item of filteredData| async"  [value]="item[field]">
        <ng-container *ngTemplateOutlet="contentTemplate; context: {item}"></ng-container>
      </mat-option>
    </cdk-virtual-scroll-viewport>
  </mat-autocomplete>
</mat-form-field>
