<h4>

{{data.firm}}

  <div class="header-input">
    <mat-icon matPrefix>search</mat-icon>
    <input matInput type="text" [formControl]="facilityFilter">
  </div>


</h4>
<mat-dialog-content>
  <button mat-icon-button class="close-button" (click)="onClose()">
    <mat-icon>close</mat-icon>
  </button>


  @defer (when !!facilities.length){
  <div class="table-container">
    <table [cellPadding]="0" [cellSpacing]="0">
        <thead>
            <tr>
                <th>№</th>
                <th style="text-align: left;">Объект</th>
                <th>Общий&nbsp;рейтинг </th>
              <th *ngFor="let f of groupFields; let i = index">{{getGroupNameByField(f)}}</th>
            </tr>
        </thead>
        <tbody>
          <tr *ngFor="let facility of filteredFacilities; let i = index">
            <td>{{ i + 1 }}</td>
            <td><strong>{{ facility.name }}</strong><br>{{ facility.address }}</td>
            <td><div
              [ngClass]="{
                'green': facility['rating'] > 80,
                'yellow': facility['rating'] >= 66 && facility['rating'] <= 80,
                'red': facility['rating'] < 66
              }"
               class="hollow" style="text-align: center;">{{ facility.rating }}%
            </div></td>
            <td *ngFor="let f of groupFields; let i = index"><div
              [ngClass]="{
                'green': facility[f] > 80,
                'yellow': facility[f] >= 66 && facility[f] <= 80,
                'red': facility[f] < 66
              }"
              class="hollow" style="text-align: center;">{{ facility[f] }}%
            </div></td>
          </tr>
        </tbody>
    </table>
</div>
  } @placeholder {
    <div>
      {{downloadMessage()}}
    </div>
  }
</mat-dialog-content>
