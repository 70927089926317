<main>
  @if (snapshoting()) {
    <h2 class="report-general-placeholder">Готовим данные для экспорта...</h2>
  }

  <mat-sidenav-container [class.opened]="opened">
    <mat-sidenav #sidenav mode="side" [(opened)]="opened">
      <button mat-icon-button (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <ostso-side-bar-double [titleCreate]="''"
                             (toggle)="sidenav.toggle()"
                             [opened]="sidenav.opened"
                             (clickOutside)="onClickOutside()"
      />

    </mat-sidenav>

    <mat-sidenav-content>
      <section [class.pdf-report]="isPdfReport" [pasToPdf]="registerHeader" (snapshoting)="onSnapshot($event)">
        <div class="register-header" #registerHeader>
          <ostso-register-header (open)="mobileOpen=true" *ngIf="!snapshoting()"/>
          <div *ngIf="snapshoting()" class="pdf-report__header">
            <h1>Объекты Москомспорта</h1>
            <div class="period">
              <div>
                <h6>период оценки</h6>
                <a class="btn btn-red period-data">
                  <span>{{ (filters$.value?.createAt | date: 'dd.MM.yyyy') || 'не выбрано' }}</span>
                  <span>-</span>
                  <span>{{ (filters$.value?.finishedDate | date: 'dd.MM.yyyy') || 'не выбрано' }}</span>
                </a>
              </div>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2056_875)">
                  <path
                    d="M3 17.2504V21.0004H6.75L17.815 9.93543L14.065 6.18543L3 17.2504ZM20.705 7.04543C21.095 6.65543 21.095 6.02043 20.705 5.63043L18.37 3.29543C17.98 2.90543 17.345 2.90543 16.955 3.29543L15.125 5.12543L18.875 8.87543L20.705 7.04543Z"
                    fill="#929193"/>
                </g>
                <defs>
                  <clipPath id="clip0_2056_875">
                    <rect width="24" height="24" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div class="header-filter">
            <div class="header-btn red" (click)="onExportPdf()" [attr.data-disabled]="!isLastRowRendering">
              <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.5202 14.5198H5.11369C4.69336 14.5198 4.33354 14.3701 4.03421 14.0708C3.73489 13.7715 3.58523 13.4116 3.58523 12.9913V2.29213C3.58523 1.8718 3.73489 1.51198 4.03421 1.21266C4.33354 0.913333 4.69336 0.763672 5.11369 0.763672H10.4633L15.0486 5.34904V12.9913C15.0486 13.4116 14.899 13.7715 14.5997 14.0708C14.3003 14.3701 13.9405 14.5198 13.5202 14.5198ZM9.69905 6.11327V2.29213H5.11369V12.9913H13.5202V6.11327H9.69905ZM2.05678 17.5767C1.63645 17.5767 1.27663 17.427 0.977304 17.1277C0.677982 16.8284 0.52832 16.4686 0.52832 16.0482V5.34904H2.05678V16.0482H10.4633V17.5767H2.05678Z"
                  fill="#E42130"/>
              </svg>


              PDF
            </div>
            <div class="header-btn" (click)="onExportExcel()" [attr.data-disabled]="!isLastRowRendering">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_0_424" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="19"
                      height="19">
                  <rect width="18.3415" height="18.3415" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask0_0_424)">
                  <path
                    d="M14.5204 14.5202H6.11393C5.69361 14.5202 5.33378 14.3706 5.03446 14.0712C4.73514 13.7719 4.58548 13.4121 4.58548 12.9918V2.29259C4.58548 1.87226 4.73514 1.51244 5.03446 1.21311C5.33378 0.913791 5.69361 0.76413 6.11393 0.76413H11.4635L16.0489 5.3495V12.9918C16.0489 13.4121 15.8992 13.7719 15.5999 14.0712C15.3006 14.3706 14.9408 14.5202 14.5204 14.5202ZM10.6993 6.11372V2.29259H6.11393V12.9918H14.5204V6.11372H10.6993ZM3.05702 17.5771C2.63669 17.5771 2.27687 17.4275 1.97755 17.1282C1.67823 16.8288 1.52856 16.469 1.52856 16.0487V5.3495H3.05702V16.0487H11.4635V17.5771H3.05702Z"
                    fill="#272B37"/>
                </g>
              </svg>

              Excel
            </div>

            <div class="header-btn blue refresh">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_0_158" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="19"
                      height="19">
                  <rect x="0.341431" width="18.3415" height="18.3415" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask0_0_158)">
                  <path
                    d="M4.23909 12.2658C3.95887 11.7817 3.74871 11.285 3.6086 10.7755C3.46849 10.266 3.39844 9.7438 3.39844 9.20885C3.39844 7.50207 3.99071 6.05004 5.17527 4.85275C6.35982 3.65546 7.80548 3.05681 9.51226 3.05681H9.646L8.42323 1.83405L9.49315 0.76413L12.5501 3.82104L9.49315 6.87795L8.42323 5.80803L9.646 4.58527H9.51226C8.23855 4.58527 7.15589 5.03425 6.26429 5.93222C5.37269 6.83019 4.92689 7.9224 4.92689 9.20885C4.92689 9.54001 4.9651 9.86481 5.04153 10.1832C5.11795 10.5017 5.23258 10.8137 5.38543 11.1194L4.23909 12.2658ZM9.53137 17.5771L6.47445 14.5202L9.53137 11.4633L10.6013 12.5332L9.37852 13.756H9.51226C10.786 13.756 11.8686 13.307 12.7602 12.409C13.6518 11.5111 14.0976 10.4189 14.0976 9.13242C14.0976 8.80126 14.0594 8.47646 13.983 8.15803C13.9066 7.8396 13.7919 7.52754 13.6391 7.22185L14.7854 6.07551C15.0656 6.55952 15.2758 7.05627 15.4159 7.56576C15.556 8.07524 15.6261 8.59746 15.6261 9.13242C15.6261 10.8392 15.0338 12.2912 13.8493 13.4885C12.6647 14.6858 11.219 15.2845 9.51226 15.2845H9.37852L10.6013 16.5072L9.53137 17.5771Z"
                    fill="#4674FF"/>
                </g>
              </svg>
              Обновить
            </div>


            <div class="filters-btn" (click)="openFilterDialog()">
              <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_0_435" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="25"
                      height="25">
                  <rect x="0.682861" y="0.5" width="24" height="24" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask0_0_435)">
                  <path
                    d="M11.6828 20.5C11.3995 20.5 11.162 20.4042 10.9703 20.2125C10.7787 20.0208 10.6828 19.7833 10.6828 19.5V13.5L4.88282 6.1C4.63282 5.76667 4.59532 5.41667 4.77032 5.05C4.94532 4.68333 5.24949 4.5 5.68282 4.5H19.6828C20.1162 4.5 20.4203 4.68333 20.5953 5.05C20.7703 5.41667 20.7328 5.76667 20.4828 6.1L14.6828 13.5V19.5C14.6828 19.7833 14.587 20.0208 14.3953 20.2125C14.2037 20.4042 13.9662 20.5 13.6828 20.5H11.6828ZM12.6828 12.8L17.6328 6.5H7.73282L12.6828 12.8Z"
                    fill="#E22B36"/>
                </g>
              </svg>
            </div>

          </div>


          <div class="switch">
            <a class="btn btn-grey" routerLink="/org-rating">Организации МКС</a>
            <a class="btn btn-red" routerLink="/rating">Объекты МКС</a>
          </div>


<!--          <div class="period">-->
<!--            <div>-->
<!--              <h6>период оценки</h6>-->
<!--              <a class="btn btn-red">01.12.2023-01.01.2024</a>-->
<!--            </div>-->
<!--            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <g clip-path="url(#clip0_2056_875)">-->
<!--                <path-->
<!--                  d="M3 17.2504V21.0004H6.75L17.815 9.93543L14.065 6.18543L3 17.2504ZM20.705 7.04543C21.095 6.65543 21.095 6.02043 20.705 5.63043L18.37 3.29543C17.98 2.90543 17.345 2.90543 16.955 3.29543L15.125 5.12543L18.875 8.87543L20.705 7.04543Z"-->
<!--                  fill="#929193"/>-->
<!--              </g>-->
<!--              <defs>-->
<!--                <clipPath id="clip0_2056_875">-->
<!--                  <rect width="24" height="24" fill="white"/>-->
<!--                </clipPath>-->
<!--              </defs>-->
<!--            </svg>-->
<!--          </div>-->

          <div class="filter-indicators">
            <ostso-filter-indicators-bar [filter]="filters$.value"/>
            <div class="filter-reset" (click)="onResetFilter()">Сбросить фильтр</div>
          </div>
        </div>
        <!--        <pre>{{overallRatings()|json}}</pre>-->
        <div class="table-container">

          @defer (when dataTableService.isLoaded() && !!dataTableService.isDataLoaded().values) {
            @if (isRateDataLoaded() && isRateDataEmpty()) {
              <div class="loading-placeholder">Данные отсутствуют</div>
            }
            <ostso-table
              #table
              [updateList]="updateList.asObservable()"
              (sortedColumn)="onSortedColumn($event)"
              [hasControls]="false"
              [class.hide-column]="isPdfReport"
              (changeColumns)="onChangeColumns($event)"
            >

              <ng-template ostsoCellTemplate="number" let-element>
                {{ element['id'] }}
              </ng-template>

              <ng-template ostsoCellTemplate="name" let-element>
                <strong>{{ element['name'] }}</strong>
                <br>
                {{ element['address'] }}
              </ng-template>

              <ng-template ostsoCellTemplate="balanceHolder" let-element>
                {{ element['balanceHolder'] }}
              </ng-template>

              <ng-template ostsoCellTemplate="rating" let-element>
                <div class="hollow"
                     [ngClass]="{
              'green': element['rating'] > 80,
              'yellow': element['rating'] >= 66 && element['rating'] <= 80,
              'red': element['rating'] < 66
              }"
                > {{ element['rating'] }}%
                </div>

              </ng-template>

            </ostso-table>
          } @placeholder {
            <div class="loading-placeholder">Данные загружаются...</div>
          }

        </div>

        <div class="general-legend">
          <div>
            <div>{{zoneRating && zoneRating[0].min}}-100</div>
            <span>Зеленая зона</span> - объект высокоэффективный
          </div>
          <div>
            <div>{{zoneRating && zoneRating[1].min}}-{{zoneRating && zoneRating[1].max}}</div>
            <span>Желтая зона</span> - объект эффективный
          </div>
          <div>
            <div>до {{zoneRating && zoneRating[2].max}}</div>
            <span>Красная зона</span> - объект недостаточно эффективный
          </div>
        </div>

      </section>

      <button (click)="toggleClass()">
        {{ isPdfReport ? 'Normal' : 'PDF' }}
      </button>
    </mat-sidenav-content>
  </mat-sidenav-container>

</main>
