<main class="ostso-grade">


  <mat-sidenav-container [class.opened]="opened">
    <mat-sidenav #sidenav mode="side" [(opened)]="opened" class="main-container">
      <ostso-side-bar-double
        [titleCreate]="titleCreate"
        (toggle)="sidenav.toggle()"
        [opened]="sidenav.opened"
      />
    </mat-sidenav>

    <mat-sidenav-content>
      <section class="grade-container">

        <h1>
          <div class="title-grade">
            <span>{{ title }}</span>
            @if (status() && status()?.id) {
              <span [ngClass]="{'type-grade': true, 'archived': archive()?.code==='Archive'}">{{ typeGrade() }}</span>
            }
            @if (user.isOperator || user.isAdmin) {
              <ostso-select-inspector [formControl]="inspector" [data]="inspectorsArray"/>
            }

          </div>
          <div class="header-icons">
            <div class="wrapper" *ngIf="mode()!=='create'">
              <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg"
                   (click)="onCopyUrl()">
                <path
                  d="M11 9.5V4.5H13V9.5H11ZM6 16.4C5.41667 16.2333 4.9375 15.9083 4.5625 15.425C4.1875 14.9417 4 14.3833 4 13.75V4.5H6V16.4ZM6.75 20.5C5.01667 20.5 3.54167 19.8917 2.325 18.675C1.10833 17.4583 0.5 15.9833 0.5 14.25V5C0.5 3.75 0.9375 2.6875 1.8125 1.8125C2.6875 0.9375 3.75 0.5 5 0.5C6.25 0.5 7.3125 0.9375 8.1875 1.8125C9.0625 2.6875 9.5 3.75 9.5 5V12.5H7.5V5C7.48333 4.3 7.2375 3.70833 6.7625 3.225C6.2875 2.74167 5.7 2.5 5 2.5C4.3 2.5 3.70833 2.74167 3.225 3.225C2.74167 3.70833 2.5 4.3 2.5 5V14.25C2.48333 15.4333 2.89167 16.4375 3.725 17.2625C4.55833 18.0875 5.56667 18.5 6.75 18.5C7.16667 18.5 7.5625 18.4458 7.9375 18.3375C8.3125 18.2292 8.66667 18.0667 9 17.85V20.075C8.65 20.2083 8.2875 20.3125 7.9125 20.3875C7.5375 20.4625 7.15 20.5 6.75 20.5ZM11 19.5V16.5H8V14.5H11V11.5H13V14.5H16V16.5H13V19.5H11Z"
                  fill="#272B37"/>
              </svg>
              @if (archive()?.code === 'Archive') {
                <img src="/assets/icons8-restore-96.png" width="24" alt="" (click)="onRestore()"
                     [ngClass]="{'disabled': !currentRights().archive_restore}">
              } @else {
                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg"
                     (click)="deleteElement($event,null)" [ngClass]="{'disabled': !currentRights().archive_restore}">
                  <mask id="mask0_180_341" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22"
                        height="23">
                    <rect y="0.860779" width="21.2784" height="21.2784" fill="#D9D9D9"/>
                  </mask>
                  <g mask="url(#mask0_180_341)">
                    <path
                      d="M6.20637 19.4794C5.71874 19.4794 5.3013 19.3058 4.95405 18.9586C4.6068 18.6113 4.43317 18.1939 4.43317 17.7062V6.18043H3.54657V4.40723H7.97957V3.52063H13.2992V4.40723H17.7322V6.18043H16.8456V17.7062C16.8456 18.1939 16.6719 18.6113 16.3247 18.9586C15.9774 19.3058 15.56 19.4794 15.0724 19.4794H6.20637ZM15.0724 6.18043H6.20637V17.7062H15.0724V6.18043ZM7.97957 15.933H9.75277V7.95363H7.97957V15.933ZM11.526 15.933H13.2992V7.95363H11.526V15.933Z"
                      fill="#E22B36"/>
                  </g>
                </svg>
              }
            </div>
            <ostso-send-mail
              [enableEmailSend]="enableEmailSend()"
              [(emailSent)]="emailSent"
              [currentId]="currentId"
              [ostsoAccessable]="mode()!=='create' && !user.isInspector"
            />
            <a routerLink="/ratecard">
              <mat-icon>close</mat-icon>
            </a>
          </div>
        </h1>

        <div class="grade-wrap" [class.show]="mode()==='watch'">

          <div class="grade-left">

            <div class="form-row">
              <ostso-autocomplete label="Объект"
                                  [data]="facilitiesData"
                                  [formControl]="facilityControl"
                                  [ostsoAccessable]="currentRights().rate_object && mode()!=='watch'"
                                  [field]="'address'"
              >
                <ng-template let-item="item">
                  @if (window.innerWidth < 1000) {
                    <div class="grade-object">
                      <div>{{ item.balanceHolder.shortName || item.balanceHolder.name }}</div>
                      <div class="bordered">{{ item.name }}</div>
                      <div class="strong">{{ item?.address }}</div>
                    </div>
                  } @else {
                    <div class="grade-object">
                      <!--                      <span>{{ item.iasId ? item.iasId + ' / ' : '' }}</span>-->
                      <!--                      <span>{{ item.district ? item.district.name + ' / ' : '' }}</span>-->
                      <!--                      <span>{{ item.region ? item.region.name + ' / ' : '' }}</span>-->
                      <!--                      <span>{{ item.address ? item.address + ' / ' : '' }}</span>-->
                      <!--                      <span>{{ item.balanceHolder ? item.balanceHolder.shortName + ' / ' : '' }}</span>-->
                      <!--                      <strong>{{ item.name }}</strong>-->
                      <span>{{ item.balanceHolder ? item.balanceHolder.shortName + ' / ' : '' }}</span>
                      <span>{{ item.name + ' / ' }}</span>
                      <strong>{{ item?.address }}</strong>
                    </div>
                  }

                </ng-template>
              </ostso-autocomplete>
            </div>

            <div class="form-row">
              <mat-form-field
                appearance="outline"
                [ostsoAccessable]="currentRights().rate_date && mode()!=='watch'"
              >
                <mat-label>Дата</mat-label>
                <input matInput [matDatepicker]="picker" [formControl]="dateControl">
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker [disabled]="mode()==='watch'"></mat-datepicker>
              </mat-form-field>
            </div>


            <div class="form-row">
              <ostso-autocomplete
                [data]="remarktypesData"
                [formControl]="remarktypeControl"
                label="Замечание"
                [ostsoAccessable]="currentRights().remark && mode()!=='watch'"
              >
                <ng-template let-item="item">
                  <div class="grade-object">
                    <span>{{ item.rateGroup ? (item.rateGroup.shortName ? item.rateGroup.shortName + ' / ' : item.rateGroup.name + ' / ') : '' }}</span>
                    <span>{{ item.rateSubGroup ? (item.rateSubGroup.shortName ? item.rateSubGroup.shortName + ' / ' : item.rateSubGroup.name + ' / ') : '' }}</span>
                    <span>{{ item.rateKind ? (item.rateKind.shortName ? item.rateKind.shortName + ' / ' : item.rateKind.name + ' / ') : '' }}</span>
                    <strong>{{ item.name }}</strong>
                  </div>
                </ng-template>
              </ostso-autocomplete>
            </div>

            <div class="form-row" [ngStyle]="{marginBottom:'1rem'}">
              <mat-checkbox class="example-margin" [formControl]="withoutRemarks" (click)="onWithoutRemarksClick()">
                Без замечаний
              </mat-checkbox>
            </div>

            <!--            <div class="form-row">-->
            <!--              <mat-form-field appearance="outline">-->
            <!--                <mat-label>Группа оценки</mat-label>-->
            <!--                <input matInput [formControl]="groupControl" class="disabled">-->
            <!--              </mat-form-field>-->
            <!--            </div>-->

            <!--            <div class="form-row">-->
            <!--              <mat-form-field appearance="outline">-->
            <!--                <mat-label>Подгруппа оценки</mat-label>-->
            <!--                <input matInput [formControl]="subGroupControl" class="disabled">-->
            <!--              </mat-form-field>-->
            <!--            </div>-->

            <!--            <div class="form-row">-->
            <!--              <mat-form-field appearance="outline">-->
            <!--                <mat-label>Вид оценки</mat-label>-->
            <!--                <input matInput [formControl]="kindControl" class="disabled">-->
            <!--              </mat-form-field>-->
            <!--            </div>-->

            <div class="form-row">
              <mat-form-field appearance="outline" [ostsoAccessable]="currentRights().comment && mode()!=='watch'">
                <mat-label>Комментарий</mat-label>
                <input matInput [formControl]="comment" [disabled]="true">
              </mat-form-field>
            </div>

            @if (canShowDateFixed) {
              <div class="form-row">
                <mat-form-field
                  appearance="outline"
                  [ostsoAccessable]="currentRights().elimination_date && mode()!=='watch'"
                >
                  <mat-label>Дата устранения</mat-label>
                  <input matInput [matDatepicker]="picker1" [formControl]="dateFixedControl">
                  <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1 [disabled]="mode()==='watch'"></mat-datepicker>
                </mat-form-field>
              </div>
            }

            @if (status()?.code !== 'project' && mode() !== 'create') {
              <div class="form-row">
                <mat-form-field appearance="outline" [ostsoAccessable]="currentRights().note && mode()==='edit'">
                  <mat-label>Примечание</mat-label>
                  <input matInput [formControl]="note">
                </mat-form-field>
              </div>
            }
          </div>

          <div class="grade-right">
            <ostso-grade-right-side #filesComponent [currentRights]="currentRights()" [files]="files"/>
          </div>
        </div>

        <div class="grade-buttons-container">
          <button *ngIf="allowEdit() && currentRights().edit && mode()==='watch' && archive()?.code !== 'Archive'"
                  class="grade__button"
                  (click)="onEdit()">
            Редактировать
          </button>
          <button *ngIf="currentRights().save && mode()!=='watch' && currentRights().save" class="grade__button"
                  class="grade__button grade__button--blue" (click)="save()">Сохранить
          </button>
          @if (withoutRemarks.value) {
            <button *ngIf="mode()=== 'watch'" class="grade__button grade__button--blue"
                    (click)="saveWithoutRemarks()">Принять без замечаний
            </button>
          } @else {
            <button *ngIf="currentRights().sent_to_agreement && currentId && mode()==='watch'"
                    class="grade__button grade__button--blue"
                    (click)="sendToAgreement()">
              Отправить на согласование
            </button>
            <button *ngIf="currentRights().sent_to_agreement_and_create && currentId && mode()==='watch'"
                    class="grade__button grade__button--blue"
                    (click)="toAgreementAndNew()">
              Отправить на согласование и создать новую оценку
            </button>
            <button *ngIf="currentRights().agreed" class="grade__button grade__button--blue" (click)="agree()">
              Согласовано
            </button>
            <button *ngIf="currentRights().not_agreed" class="grade__button grade__button--red" (click)="disagree()">
              Не согласовано
            </button>
            <button *ngIf="currentRights().accept" class="grade__button grade__button--blue" (click)="accept()">
              Принять
            </button>
            <button *ngIf="currentRights().reject" class="grade__button grade__button--red"
                    (click)="reject()">
              Вернуть на доработку
            </button>
            <button *ngIf="currentRights().finish_rate && !!withoutRemarks.value"
                    class="grade__button grade__button--blue"
                    (click)="changeStatus('fixed')">Завершить оценку
            </button>
          }
        </div>
      </section>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <ostso-do-agree [titleDoAgree]="titleDoAgree" [(showDoAgree)]="showDoAgree" [(answerDoAgree)]="answerDoAgree"/>
  <ostso-toasts/>
</main>
