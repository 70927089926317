import {ChangeDetectionStrategy, Component, effect, inject, OnInit, signal,} from '@angular/core';
import {FallbackComponent} from '../../../../shared/components/fallback/fallback.component';
import {FormBuilder, FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButton, MatIconButton} from '@angular/material/button';
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from '@angular/material/datepicker';
import {MatFormField, MatLabel, MatPrefix} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatInput} from '@angular/material/input';
import {NgClass, NgStyle} from '@angular/common';
import {toSignal} from '@angular/core/rxjs-interop';
import {map} from 'rxjs/operators';
import {MultiSelectComponent} from '../../../../shared/components/multi-select/multi-select.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FacilityService} from '../../../apiModule/api/facility.service';
import {FirmRateService} from '../../../apiModule/api/firmRate.service';
import {RemarkTypeService} from '../../../apiModule';
import {
  QuickAutocompleteComponent
} from '../../../../shared/components/quick-autocomplete/quick-autocomplete.component';
import {GroupEstimate} from '../../../apiModule/model/groupEstimate';
import {Group1Service} from '../../../apiModule/api/group1.service';
import {SubgroupService} from '../../../apiModule/api/subgroup.service';
import {SubgroupEstimate} from '../../../apiModule/model/subgroupEstimate';
import {FacilityType, FacilityTypeService} from '../../../apiModule/api/facility-type.service';
import {PriorityService} from '../../../apiModule/api/priority.service';
import {MatOption} from '@angular/material/autocomplete';
import {MatSelect} from '@angular/material/select';
import {ApiDashboardActiveremarkByFacilitytypeGetRequestParams} from '../../../apiModule/api/dashboard.service';

@Component({
  selector: 'ostso-facility-filter',
  standalone: true,
  imports: [
    FallbackComponent,
    FormsModule,
    MatButton,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatFormField,
    MatIcon,
    MatIconButton,
    MatInput,
    MatLabel,
    MatPrefix,
    ReactiveFormsModule,
    NgClass,
    NgStyle,
    MultiSelectComponent,
    QuickAutocompleteComponent,
    MatOption,
    MatSelect
  ],
  templateUrl: './dashboard-filter.component.html',
  styleUrls: ['./dashboard-filter.component.scss',
    '../../register/dialogs/dialogForGroup/register-dialog.component.scss',
    '../../rate-card/filter/filter-rate-card.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardFilterComponent implements OnInit {
  fb = inject(FormBuilder);
  firmService = inject(FirmRateService);
  facilityService = inject(FacilityService);
  facilityAddressService = inject(RemarkTypeService);
  groupEstimateService = inject(Group1Service);
  subgroupEstimateService = inject(SubgroupService);
  facilityTypeService = inject(FacilityTypeService);
  priorityService = inject(PriorityService);
  dialogRef = inject(MatDialogRef<DashboardFilterComponent>);
  data = inject(MAT_DIALOG_DATA);


  loaded = signal<boolean>(true);

  firms = toSignal<OrgRatingFirm[]>(this.firmService.apiFirmRateGet({pageSize: 1e4})
    .pipe(
      map(x => x.values.map((v: OrgRating) => v.firm))
    ));
  effectFirms = effect(() => {
    if (!this.firms()?.length) return;
    const fromData = this.firmsGotFromData();
    this.form.get('firmIds')!.setValue(fromData ?? []);
  });

  facilities = toSignal<Facility[]>(this.facilityService.apiRemarktypeGet({pageSize: 1e4})
    .pipe(
      map(x => x.values)
    ));
  effectFacilities = effect(() => {
    if (!this.facilities()?.length) return;
    const fromData = this.facilitiesGotFromData();
    this.form.get('facilityIds')!.setValue(fromData);
  });

  facilityAddresses = toSignal<string[]>(this.facilityAddressService.apiFacilityAdressesGet()
    .pipe(
      map(x => x.values)
    ));

  groups = toSignal<GroupEstimate[]>(this.groupEstimateService.apiRemarktypeGet({pageSize: 1e4})
    .pipe(
      map(x => x.values)
    ));
  effectGroups = effect(() => {
    if (!this.groups()?.length) return;
    const fromData = this.groupsGotFromData();
    this.form.get('remarkGroupIds')!.setValue(fromData ?? []);
  });

  subgroups = toSignal<SubgroupEstimate[]>(this.subgroupEstimateService.apiRemarktypeGet({pageSize: 1e4})
    .pipe(
      map(x => x.values)
    ));
  effectSubGroups = effect(() => {
    if (!this.subgroups()?.length) return;
    const fromData = this.subgroupsGotFromData();
    this.form.get('remarkSubGroupIds')!.setValue(fromData);
  });

  facilityTypes = toSignal<Facility[]>(this.facilityTypeService.apiFacilitytypeGet({pageSize: 1e4})
    .pipe(
      map(x => x.values)
    ));
  effectFacilityTypes = effect(() => {
    if (!this.facilityTypes()?.length) return;
    const fromData = this.facilityTypesGotFromData();
    this.form.get('facilityTypeIds')!.setValue(fromData);
  });

  priorities = toSignal<Priority[]>(this.priorityService.apiRemarktypeGet({pageSize: 1e4})
    .pipe(
      map(x => x.values)
    ));
  effectPriorities = effect(() => {
    if (!this.priorities()?.length) return;
    const fromData = this.priorityTypeGotFromData();
    this.form.get('remarkPriorityIds')!.setValue(fromData);
  });


  dataGotFromData = <T extends { id?: number }>(key: string, dataResource: T[]|undefined): ()=>T[] =>() =>{
    if(!this.data.filter || !this.data.filter[key] || this.data.filter[key].length === 0) return [];
    const res = this.data.filter[key].map((id: number) => {
      const res = dataResource?.find((f: T) => f.id === id);
      return res;
    }).filter(Boolean) ?? [];
    return res;
  };


  firmsGotFromData =(): OrgRatingFirm[] => this.dataGotFromData<OrgRatingFirm>('firmIds', this.firms())();
  facilitiesGotFromData =(): Facility[] => this.dataGotFromData<Facility>('facilityIds', this.facilities())();
  groupsGotFromData =():GroupEstimate[] => this.dataGotFromData<GroupEstimate>('remarkGroupIds', this.groups())();
  subgroupsGotFromData =(): SubgroupEstimate[] => this.dataGotFromData<SubgroupEstimate>('remarkSubGroupIds', this.subgroups())();
  facilityTypesGotFromData =(): FacilityType[] => this.dataGotFromData<FacilityType>('facilityTypeIds', this.facilityTypes())();
  priorityTypeGotFromData = (): Priority[]=>this.dataGotFromData<Priority>('remarkPriorityIds', this.priorities())();


  form = this.fb.group({
    firmIds: [[] as OrgRatingFirm[]],
    facilityIds: [[] as Facility[]],
    facilityAddress: [this.data.filter && this.data.filter['facilityAddress']||''],
    remarkGroupIds: [[] as GroupEstimate[]],
    remarkSubGroupIds: [ [] as SubgroupEstimate[]],
    facilityTypeIds: [[] as FacilityType[]],
    remarkPriorityIds: [[] as Priority[]],
    isLongspanStructureObject: [this.data.filter && this.data.filter['isLongspanStructureObject'] || null],
    isInvestmentObject: [this.data.filter && this.data.filter['isInvestmentObject'] || null],
    IsOverdueRemark: [this.data.filter && this.data.filter['IsOverdueRemark'] || null],
    createAt: [this.data.filter && this.data.filter['createAt'] || null],
    finishedDate: [this.data.filter && this.data.filter['finishedDate'] || null],
  });

  checkboxes = [
    { key: 'groupByDay', label: 'День' },
    { key: 'groupByMonth', label: 'Месяц' },
    { key: 'groupByYear', label: 'Год' }
  ];

  // formGroups = this.fb.group(
  //   this.checkboxes.reduce<Record<string, FormControl>>((group, checkbox) => {
  //     group[checkbox.key] = new FormControl(this.data.grouping[checkbox.key]);
  //     return group;
  //   }, {})
  // );
  formGroups = this.fb.group({
    groupingControl: [this.data.grouping &&  Object.keys(this.data.grouping).length ? Object.keys(this.data.grouping)[0] : 'groupByMonth']
    });


  ngOnInit(): void {
    console.log(this.data);
  }

  onApply(): void {
    const res: ApiDashboardActiveremarkByFacilitytypeGetRequestParams = {
      firmIds: this.form.value.firmIds && this.form.value.firmIds.map((f: OrgRatingFirm) => f.id) || [] as number[],
      facilityIds: this.form.value.facilityIds && this.form.value.facilityIds.map((f: OrgRatingFirm) => f.id) || [] as number[],
      facilityAddresses: this.form.value.facilityAddress || undefined,
      remarkGroupIds: this.form.value.remarkGroupIds && this.form.value.remarkGroupIds.map((f: GroupEstimate) => f.id).filter(Boolean) as number[] || [] as number[],
      remarkSubGroupIds: this.form.value.remarkSubGroupIds && this.form.value.remarkSubGroupIds.map((f: SubgroupEstimate) => f.id).filter(Boolean) as number[] || [] as number[],
      facilityTypeIds: this.form.value.facilityTypeIds && this.form.value.facilityTypeIds.map((f: FacilityType) => f.id).filter(Boolean) as number[]|| [] as number[],
      remarkPriorityIds: this.form.value.remarkPriorityIds && this.form.value.remarkPriorityIds.map((f: Priority) => f.id).filter(Boolean) as number[] || [] as number[],
      isInvestmentObject: this.form.value.isInvestmentObject,
      isLongspanStructureObject: this.form.value.isLongspanStructureObject,
      IsOverdueRemark: this.form.value.IsOverdueRemark,
      createAt: this.form.value.createAt && ((typeof this.form.value.createAt === 'string') ? this.form.value.createAt : this.form.value.createAt.utc().format()),
      finishedDate: this.form.value.finishedDate && ((typeof this.form.value.finishedDate === 'string') ? this.form.value.finishedDate : this.form.value.finishedDate.utc().format()),

    };
    this.dialogRef.close({
      filter: res,
      grouping: {[this.formGroups.value.groupingControl as string]: true}
    });
  }

  onReset(): void {
    this.form.patchValue({
      firmIds: [],
      facilityIds: [],
      facilityAddress: '',
      remarkGroupIds: [],
      remarkSubGroupIds: [],
      facilityTypeIds: [],
      remarkPriorityIds: [],
      isLongspanStructureObject: null,
      isInvestmentObject: null,
      IsOverdueRemark: null,
      createAt: '',
      finishedDate: '',
    });
    this.formGroups.reset();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
