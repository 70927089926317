import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ElementRef,
  forwardRef,
  inject,
  Input, OnDestroy,
  OnInit,
  signal,
  TemplateRef,
  ViewChild, ViewEncapsulation
} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {AccountControllerService} from '../../../apiModule/api/account-controller.service';
import {map} from 'rxjs/operators';
import {MatIcon} from '@angular/material/icon';
import {MatDialog} from '@angular/material/dialog';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'ostso-select-inspector',
  standalone: true,
  imports: [
    MatIcon
  ],
  templateUrl: './select-inspector.component.html',
  styleUrl: './select-inspector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectInspectorComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None
})
export class SelectInspectorComponent implements OnInit, OnDestroy {

  @Input() data: User[] = [];
  @ViewChild('list', {read: TemplateRef}) list!: TemplateRef<any>;
  accountService = inject(AccountControllerService);
  cdr = inject(ChangeDetectorRef);
  dialog = inject(MatDialog);
  doc = inject(DOCUMENT);
  users: User[] = [];
  toggle = signal(false);
  isDisabled = signal(false);

  value: User|null = null;

  get name(): string {
    if (!this.value) return '';
    return `${this.value.lastName} ${this.value?.firstName?.charAt(0)+'.'} ${this.value?.middleName?.charAt(0)+'.'}`;
  }
  onChange: (value: any) => void = (value: any) => {};
  onTouched: ()=>void = () => {};

  writeValue(value: User): void {
    this.value = value || null;
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(value: boolean): void {
    this.isDisabled.set(value);
    value && this.toggle.set(false);
  }

  onDocClick(ev: Event): void {
    if(!(ev.target as HTMLElement).closest('.select-inspector'))  this.toggle.set(false);
  }

  ngOnInit(): void {
    this.accountService.apiAccountGet({roles:['inspector'], pageSize: 1e6})
      .pipe(map(v=>v.values))
      .subscribe((res: User[]) => {
      this.users = res;
    });

    this.doc.addEventListener('click', this.onDocClick.bind(this));
  }

  ngOnDestroy(): void {
    this.doc.removeEventListener('click', this.onDocClick.bind(this));
  }


  getNameFromUser(user: User): string {
    if (!user) return '';
    return `${user.lastName} ${user?.firstName?.charAt(0)+'.'} ${user?.middleName?.charAt(0)+'.'}`;
  }
  select(user: User):void {
    this.value = user;
    this.onChange(user);
    this.cdr.markForCheck();
  }
}
