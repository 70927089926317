import {Injectable} from '@angular/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {MontserratBase64} from './montserrat.base64';

export type ConfigType = {
  orientation: 'p'|'l',
  topMargin: number,
  bottomMargin: number,
  scale: number
}

export const defaultConfigExportToPdf: ConfigType = {
  orientation: 'p',
  topMargin: 4,
  bottomMargin: 20,
  scale: 2.8
};

@Injectable({
  providedIn: 'root'
})
export class ExportPdfService {

  constructor() {
  }

  public async exportToPDF(element: HTMLElement, headerElement?: HTMLElement, name: string='report', config: ConfigType = defaultConfigExportToPdf): Promise<void> {
    if (!element) return;

    const pdf = new jsPDF(config.orientation, 'mm', 'a4');
    pdf.addFileToVFS('Montserrat-Regular.ttf', MontserratBase64);
    pdf.addFont('Montserrat-Regular.ttf', 'Montserrat', 'normal');
    pdf.setFont('Montserrat');

    // Размеры страницы PDF
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight()-10;

    // Устанавливаем белый фон для страницы
    pdf.setFillColor(255, 255, 255); // Белый цвет (RGB)
    pdf.rect(0, 0, pdfWidth, pdfHeight, 'F');


    // Отступы
    const topMargin = config.topMargin;
    const bottomMargin = config.bottomMargin;
    const usableHeight = pdfHeight - topMargin - bottomMargin;

    // Создаем canvas из HTML
    const SCALE = config.scale;
    const FORMAT = 'image/jpeg';
    const COMPRESSION = 0.85;

    const header = headerElement && await html2canvas(headerElement, {scale: SCALE, backgroundColor: '#ffffff'});
    const canvas = await html2canvas(element, {scale: SCALE, backgroundColor: '#ffffff'});
    const imgWidth = pdfWidth ;
    // const imgHeight = (canvas.height * imgWidth) / canvas.width; // Пропорциональная высота

    const headerData = header ? header.toDataURL(FORMAT, COMPRESSION) : null;
    const headerHeight = header ? header.height / 8.5 : 0;

    // Обрезка и добавление изображений на страницы
    let currentHeight = 0;
    let canAddHeader = false;
    let page = 1;

    while (currentHeight < canvas.height) {
      const pageCanvas = document.createElement('canvas');
      pageCanvas.width = canvas.width;
      pageCanvas.height = ((usableHeight) * canvas.width) / pdfWidth;

      const pageCtx = pageCanvas.getContext('2d');
      pageCtx!.fillStyle = '#FFFFFF';
      pageCtx!.fillRect(0, 0, pageCanvas.width, pageCanvas.height); // Заполнить фон белым

      pageCtx!.drawImage(
        canvas,
        0,
        currentHeight,
        canvas.width,
        pageCanvas.height,
        0,
        0,
        pageCanvas.width,
        pageCanvas.height
      );

      const pageData = pageCanvas.toDataURL(FORMAT, COMPRESSION);

      if (headerData && canAddHeader) {
        const headerOffset = 7;
        pdf.addImage(headerData, 'JPEG', headerOffset, 10, imgWidth - headerOffset * 2, headerHeight);
      }

      pdf.addImage(pageData, 'JPEG', 0, (canAddHeader ? headerHeight : 0) + topMargin, imgWidth, usableHeight);
      canAddHeader = true;

      currentHeight += pageCanvas.height;

      if (currentHeight < canvas.height) {
        pdf.setFontSize(10);
        pdf.text(`Страница ${page} из ${Math.ceil(canvas.height / pageCanvas.height)}`, imgWidth - 10, pdfHeight + 5, { align: 'right' });
        pdf.addPage();
        page++;
      }
    }

    pdf.setFontSize(10);
    pdf.text(`Страница ${page} из ${page}`, imgWidth - 10, pdfHeight - 5, { align: 'right' });


    pdf.save(`${name}.pdf`);
  }
}


