import {ChangeDetectionStrategy, Component, computed, inject, Input, signal} from '@angular/core';
import {NgForOf} from '@angular/common';
import {
  ApiDashboardActiveremarkByFacilitytypeGetRequestParams,
  DashboardService
} from '../../../../apiModule/api/dashboard.service';
import {toSignal} from '@angular/core/rxjs-interop';
import {map} from 'rxjs/operators';
import {DashboardModal} from '../../../dashboard-modal/dashboard-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {catchError, of} from 'rxjs';

@Component({
  selector: 'ostso-by-objects-dashboard-widget',
  standalone: true,
    imports: [
        NgForOf
    ],
  templateUrl: './by-objects-dashboard-widget.component.html',
  styleUrls: ['./by-objects-dashboard-widget.component.scss','../../dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ByObjectsDashboardWidgetComponent {
  dialog = inject(MatDialog);
  byFacilitytype = signal<any[]>([]);
  private _filter?: ApiDashboardActiveremarkByFacilitytypeGetRequestParams;
  dashboardService = inject(DashboardService);
  @Input() set filter(v: ApiDashboardActiveremarkByFacilitytypeGetRequestParams){
    this._filter = v;
    this.dashboardService.apiDashboardActiveremarkByFacilitytypeGet({...v || {}})
      .pipe(catchError((e: any)=>{
        console.log(e);
        return of({values:[]});
      }))
      .pipe(map(v => v.values))
      .subscribe(x=>{
      this.byFacilitytype.set(x);
    });
  }
  maxByFacilitytypeCount = computed(()=> Math.max(...this.byFacilitytype().map((facilitytype: any) => facilitytype.remarkCount)));

  showMoreInfo(type: string): void {
    this.dialog.open(DashboardModal, {
      width: 'max(320px,50vw)',
      data: {type}
    });
  }
}
