import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    computed,
    effect,
    ElementRef,
    inject,
    Input,
    signal,
    ViewChild
} from '@angular/core';
import {Chart} from 'chart.js';
import {
    ApiDashboardActiveremarkByFacilitytypeGetRequestParams,
    DashboardService
} from '../../../../apiModule/api/dashboard.service';
import {BehaviorSubject, catchError, combineLatest, map, of, switchMap} from 'rxjs';

export const monthNames: string[] = [
    'янв.', 'фев.', 'мар.', 'апр.', 'май.', 'июн.',
    'июл.', 'авг.', 'сен.', 'окт.', 'ноя.', 'дек.'
] as const;

@Component({
    selector: 'ostso-chart-dashboard-widget',
    standalone: true,
    imports: [],
    templateUrl: './chart-dashboard-widget.component.html',
    styleUrls: ['./chart-dashboard-widget.component.scss', '../../dashboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartDashboardWidgetComponent implements AfterViewInit {
    dashboardService = inject(DashboardService);
    private _filter = new BehaviorSubject<ApiDashboardActiveremarkByFacilitytypeGetRequestParams>({});
    private _grouping = new BehaviorSubject<Partial<DashboardGrouping>>({});
    private _filter$ = this._filter.asObservable();
    private _grouping$ = this._grouping.asObservable();

    byperiod = signal<ByPeriod[]>([]);
    maxValue: number = 0;

    @Input() set filter(v: ApiDashboardActiveremarkByFacilitytypeGetRequestParams) {
        this._filter.next(v);
        // this.fetchData();
    }

    @Input() set grouping(v: Partial<DashboardGrouping> | undefined) {
        this._grouping.next(v || {});
        // this.fetchData();
    }

    // fetchData(): void {
    //     this.dashboardService.apiDashboardActiveremarkByperiodGet({...this._filter || {}, ...this._grouping || {}})
    //         .pipe(catchError((e: any) => {
    //             console.log(e);
    //             return of({values: []});
    //         }))
    //         .pipe(map(v => v.values))
    //         .subscribe((x: ByPeriod[]) => {
    //             this.byperiod.set(x);
    //         });
    // }

    @ViewChild('lineChart', {read: ElementRef, static: true}) lineChart!: ElementRef<HTMLCanvasElement>;

    public chart: Chart<'line', number[] | undefined, string> | null = null;

    labels = computed(() => this.byperiod() && this.byperiod()?.map((item: {
        year: number | null;
        month: number | null,
        day: number | null
    }) => {
        const monthLabel = item.month ? monthNames[item.month - 1] : [];
        const yearSuffix = item.year && item.year.toString().slice(-2);
        return `${monthLabel}${yearSuffix || ''}`;
    }).filter(Boolean));
    identifiedCount = computed(() => this.byperiod() && this.byperiod()?.map((item: ByPeriod) => {
        return item.identifiedCount;
    }).filter(Boolean));
    resolvedCount = computed(() => this.byperiod() && this.byperiod()?.map((item: ByPeriod) => {
        return item.resolvedCount;
    }));

    private _mapLabelBySort: Record<keyof DashboardGrouping, string> = {
        groupByDay: 'day',
        groupByMonth: 'month',
        groupByYear: 'year',
    };

    get activeGrouping(): keyof DashboardGrouping {
        if (!this._grouping) return 'groupByMonth';
        const entries = Object.entries(this._grouping.value)
            .filter(([key, value]) => !!value);
        const res = Object.fromEntries(entries);
        return (Object.keys(res) as (keyof DashboardGrouping)[])[0] || 'groupByMonth';
    }


    private _labelIfMonth({day, month, year}: {
        day: number | null,
        month: number | null,
        year: number | null
    }): string {
        const monthLabel = month ? monthNames[month - 1] : [];
        const yearSuffix = year && year.toString().slice(-2);
        return `${monthLabel}${yearSuffix || ''}`;
    }

    labelsByDay = computed(() => {
        if (!this.byperiod()) return;
        const res = this.byperiod()
            .sort((a, b) => a.day! - b.day!)
            .map((item: ByPeriod) => {
                const currentGrouping = this._mapLabelBySort[this.activeGrouping] as keyof ByPeriod;
                return this.activeGrouping !== 'groupByMonth' ? item[currentGrouping] : this._labelIfMonth(item);
            });
        return res;
    });

    identifiedCountByDay = computed(() => {
        if (!this.byperiod()) return;
        const res = this.byperiod()
            .sort((a, b) => a.day! - b.day!)
            .map(({identifiedCount}: ByPeriod) => {
                return identifiedCount;
            })
            .filter(Boolean);
        return res;
    });

    resolvedCountByDay = computed(() => {
        const res = this.byperiod()
            .sort((a: ByPeriod, b: ByPeriod) => a.day! - b.day!)
            .map(({resolvedCount}: ByPeriod) => {
                return resolvedCount;
            })
            .filter(Boolean);
        return res;
    });


    effectByperiod = effect(() => {
        console.log(this.byperiod());
        if (!this.byperiod()) return;
        // this.byperiod() && this.initializeChart();
        this.maxValue = Math.max(...this.identifiedCountByDay()! as number[], ...this.resolvedCountByDay()! as number[]);

        this.chart && (this.chart.data.labels = this.labelsByDay()?.map(i => i + '') || []);
        this.chart && (this.chart.data.datasets[0].data = this.identifiedCountByDay() as number[]);
        this.chart && (this.chart.data.datasets[1].data = this.resolvedCountByDay() as number[]);
        this.chart && (this.chart.options.scales!['y']!.max = this.maxValue);
        this.chart && this.chart.update();
    });

    ngAfterViewInit(): void {
        setTimeout(() => {
                this.initializeChart();
            }
        );
        setTimeout(() => {
            combineLatest({filter: this._filter$, grouping: this._grouping$})
                .pipe(switchMap(({
                                     filter,
                                     grouping
                                 }) => this.dashboardService.apiDashboardActiveremarkByperiodGet({...filter, ...grouping})
                    .pipe(map(v => v.values))))
                .pipe(catchError((e: any) => {
                    console.log(e);
                    return of({values: []});
                })).subscribe((x: ByPeriod[]) => {
                this.byperiod.set(x);
            });
        }, 1000);

    }

    initializeChart(): void {
        const linechart = this.lineChart.nativeElement;
        console.log(this.labels(), this.identifiedCount(), this.resolvedCount());
        if (!
            linechart || !this.labels() || !this.identifiedCount() || !this.resolvedCount()
        )
            return;
        if (this.chart) this.chart.destroy();
            this.chart = new Chart(linechart, {
                type: 'line',
                data: {
                    labels: this.labels(),
                    datasets: [
                        {
                            label: 'Выявлено',
                            data: this.identifiedCount() as number[],
                            borderColor: 'red',
                            backgroundColor: 'rgba(255, 99, 132, 0.2)',
                            fill: false,
                        },
                        {
                            label: 'Устранено',
                            data: this.resolvedCount() as number[],
                            borderColor: 'gray',
                            backgroundColor: 'rgba(201, 203, 207, 0.2)',
                            fill: false,
                        }
                    ]
                },
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            display: true,
                            position: 'top',
                            labels: {
                                boxWidth: 25,
                                boxHeight: 25,
                                padding: 20,
                            },
                        },
                        title: {
                            text: 'Количество замечаний',
                            display: true,
                            font: {
                                size: 20,
                                weight: 'bold',
                            },
                            align: 'start',

                        }
                    },
                    layout: {
                        padding: {
                            top: 20
                        }
                    },
                    scales: {
                        x: {
                            grid: {
                                color: 'white',
                            }
                        },
                        y: {
                            beginAtZero: true,
                            max: this.maxValue,
                            ticks: {
                                stepSize: 10
                            },
                            grid: {
                                color: 'white',
                            }
                        }
                    }
                }
            });
    }
}
